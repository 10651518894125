import React, { useState, useEffect, useRef } from 'react';
import {useLocation, useHistory} from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import {postData, putData} from '../../../helper/request-response';
import {onSuccess} from '../../../helper/toaster';
import coaPath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();
    
    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [coa, setCoa] = useState({
        id: 0,
        accountNo: "",
        description: "",
        accountType: "",
        isActive: 1
    });

    const onReset = e => {

        e.preventDefault();

        setCoa(coa => ({ 
            ...coa,
            accountNo: "",
            description: "",
            accountType: "",
            isActive: 1
        }));
    }

    const onCoaChange = e => {

        const { name, value } = e.target;

        setCoa(coa => ({ 
            ...coa,
            [name]: value
        }));
    };

    const onActiveChange = e => {

        setCoa(coa => ({ 
            ...coa,
            isActive: coa.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postCoa = async () => {

            const result = await postData(coaPath.baseApi, coa);

            if (Object.keys(result).length) {

                onSuccess(`COA ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putCoa = async () => {

            const result = await putData(`${coaPath.baseApi}/${coa.id}`, coa);

            if (Object.keys(result).length) {
                
                onSuccess(`COA ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        };

        if (coa.id === 0 && coa.accountNo && coa.description && coa.accountType) postCoa();
        else putCoa();
        
    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state) {
    
                setCoa(state);
                
                setTitleInfo(`Detail: ${state.accountNo} - ${state.description}`);

                setIsResetable(false);
    
            } else {
    
                setTitleInfo("Tambah COA Baru");
                
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (coa.accountNo && coa.description && coa.accountType) setIsDisabledSave(false);

    }, [coa.accountNo, coa.description, coa.accountType]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ coaPath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="account-no" isCol={ true } text="COA" isMandatory={ true } value={ coa.accountNo } />
                                <div className="col-md-9">
                                    <input 
                                        id="account-no"
                                        type="text" 
                                        className="form-control" 
                                        name="accountNo"
                                        value={coa.accountNo}
                                        onChange={ e => onCoaChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="description" isCol={ true } text="Deskripsi" isMandatory={ true } value={ coa.description } />
                                <div className="col-md-9">
                                    <input 
                                        id="description" 
                                        type="text"
                                        className="form-control"
                                        name="description"
                                        value={coa.description}
                                        onChange={ e => onCoaChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="account-type" isCol={ true } text="Jenis" isMandatory={ true } value={ coa.accountType } />
                                <div className="col-md-9">
                                    <select id="account-type" className="form-control" name="accountType" onChange={ e => onCoaChange(e) } value={ coa.accountType }>
                                        <option value="">Pilih</option>
                                        <option value="AR">Kas Masuk</option>
                                        <option value="AP">Kas Keluar</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="is-active"
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            name="isActive"
                                            value={ coa.isActive }
                                            checked={ coa.isActive }
                                            onChange={ e => onActiveChange(e) } />   
                                        <label htmlFor="is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
