import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PurchaseSalesList from './List';
import purchaseSalesPath from './config';

const PurchaseSalesRoutes = () => {
    return (
        <Switch>
            <Route path={ purchaseSalesPath.basePath } exact component={ PurchaseSalesList } />
        </Switch>
    );
};

export default PurchaseSalesRoutes;
