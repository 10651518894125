import React, { useState } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';

import Label from '../../../components/label';
import Select from '../../../components/select';
import TextNumber from '../../../components/text-number';
import DayPicker from '../../../components/day-picker';
import ReportButton from '../../../components/report-button';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import TaxPath from './config';

const List = () => {

    const [startDate, setStartDate] = useState(DateTime.local());

    const [Tax, setTax] = useState([]);

    const [TaxFilter, setFilterTax] = useState({
        startDate:"",
        masterCustomerId: "",
        custName:""
    });

    const [selectedCustName, setSelectedCustName] = useState(constant.DEFAULT_SELECTED);

    const onStartDayChange = day => setStartDate(formatDate(day, constant.DEFAULT_DATE));

    const getExcel = async(param, url) => await getFile(url, param);

    const onExport = e => {

        e.preventDefault();

        const params = {
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                masterCustomerId: TaxFilter.masterCustomerId,
                custName: TaxFilter.custName
            }
        };

        getExcel(params, TaxPath.taxExcel).then(response => {

            downloadFile(response);

        });

    }

    const onReset = e => {

        e.preventDefault();

        setFilterTax(trx => ({ 
            ...TaxFilter,
            startDate:"",
            masterCustomerId: "",
            custName:""
        }));

        setStartDate(DateTime.local());

        getTaxs();

    }

    const getTaxs = async(params) => {

        const result = await getData(TaxPath.baseApi, params);

        setTax(result[0]);

    }

    /**
     * Customer change event.
     * @param {Object} opt - Option object.
     */
    const onChangeCustomer = opt => {

        if (opt.id) {

            setFilterTax(trx => ({
                ...trx,
                masterCustomerId: opt.id,
                custName: opt.name
            }));

            setSelectedCustName(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                masterCustomerId: TaxFilter.masterCustomerId
            }
        };

        getTaxs(params);

    };

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ TaxPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="startDate" text="Tanggal" />
                                    <DayPicker id="startDate" name="startDate" onDayChange={onStartDayChange} valueDate={ startDate } />
                                </div>
                            </div>
                            
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="Tax-name" text="Nama" />
                                    <Select
                                        id="customer-name"
                                        paramFilter="name"
                                        api={ TaxPath.custFind }
                                        entityId="3,4"
                                        onChange={ onChangeCustomer }
                                        value={ selectedCustName }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

                <br />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Deskripsi</th>
                                        <th>1</th><th>2</th><th>3</th><th>4</th><th>5</th><th>6</th>
                                        <th>7</th><th>8</th><th>9</th><th>10</th><th>11</th><th>12</th>
                                        <th>13</th><th>14</th><th>15</th><th>16</th><th>17</th><th>18</th>
                                        <th>19</th><th>20</th><th>21</th><th>22</th><th>23</th><th>24</th>
                                        <th>25</th><th>26</th><th>27</th><th>28</th><th>29</th><th>30</th>
                                        <th>31</th><th>Jumlah</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Tax.map((ct, index) => {
                                            return(
                                                <tr key={ index }>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ ct.deskripsi }</td>
                                                    <td><TextNumber value={ ct.a1 ? ct.a1 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a2 ? ct.a2 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a3 ? ct.a3 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a4 ? ct.a4 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a5 ? ct.a5 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a6 ? ct.a6 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a7 ? ct.a7 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a8 ? ct.a8 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a9 ? ct.a9 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a10 ? ct.a11 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a11 ? ct.a12 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a12 ? ct.a13 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a13 ? ct.a14 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a14 ? ct.a15 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a15 ? ct.a16 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a16 ? ct.a17 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a17 ? ct.a18 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a18 ? ct.a19 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a19 ? ct.a20 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a20 ? ct.a20 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a21 ? ct.a21 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a22 ? ct.a22 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a23 ? ct.a23 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a24 ? ct.a24 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a25 ? ct.a25 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a26 ? ct.a26 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a27 ? ct.a27 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a28 ? ct.a28 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a29 ? ct.a29 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a30 ? ct.a30 : 0 } /></td>
                                                    <td><TextNumber value={ ct.a31 ? ct.a31 : 0 } /></td>
                                                    <td><TextNumber value={ ct.jumlah ? ct.jumlah : 0 } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
