import apiPath from '../../../config/api';

const SalesPath = {
    title: "Toko Pupuk dan Pertanian - Modul Penjualan",
    basePath: "/pupuk-dan-pertanian/penjualan",
    newPath: "/pupuk-dan-pertanian/penjualan/baru",
    detailPath: "/pupuk-dan-pertanian/penjualan/opsi/:id",
    base: apiPath.retailSales,
    salesExcel: apiPath.retailSalesExcel,
    custFind: apiPath.masterCustFind,
    gnsFind: apiPath.masterGnsFind
}

export default SalesPath;
