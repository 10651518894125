import apiPath from '../../../config/api';

const PoCashoutPath = {
    title: "TBS - Laporan Penjualan Di Terima",
    base: "/sawit/laporan/penerimaan",
    api: apiPath.palmOilCashin,
    summary: apiPath.palmOilCashinSum,
    excel: apiPath.palmOilCashinExcel
}

export default PoCashoutPath;
