import apiPath from '../../../config/api';

const TaxPath = {
    title: "Laporan PPH",
    basePath: "/akunting/laporan/laporan-pph",
    baseApi: apiPath.accountTax,
    taxExcel : apiPath.accountTaxExcel,
    custFind: apiPath.masterCustFind
}

export default TaxPath;
