import React from 'react';

import Header from './Header';
import Sidebar from './Sidebar';
import Content from './Content';

const ProtectedContent = () => {
    return(
        <>
            <Header className="header" />
            <div className="d-flex align-items-stretch">
                <Sidebar />
                <Content />        
            </div>
        </>
    );
};

export default ProtectedContent;