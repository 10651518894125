import React, { useState, useRef, useEffect } from 'react';
import constant from '../config/constant';

/**
 * Note component
 * @param {Object} props - { isAction: Boolean, isFilter: Boolean, label: string, entity: string }
 */
const Note = ({ hideHeader, isAction, isFilter, label, entity }) => {

    const [header, setHeader] = useState(true);

    const refHeader = useRef(hideHeader);

    useEffect(() => {

        if (refHeader.current) setHeader(false);

    }, []);

    return(
        <>
            {
                header &&
                <>
                    <h2 className="h5 mb-0 border-bottom text-info">Keterangan</h2>
                    <br />
                </>
            }

            {
                isAction &&
                <p>Kolom <span className="text-danger"><strong>*</strong></span> harus di isi.</p>
            }

            {
                isFilter &&
                <>
                    <p>
                        Klik tombol <span><strong>[{ constant.SEARCH }]</strong></span> untuk melakukan pencarian.
                    </p>
                    <p>
                        Klik tombol <span><strong>[{ label }]</strong></span> untuk manambahkan <strong>{ entity }</strong> baru.
                    </p>
                    <p>
                        Klik tombol <span><strong>[{ constant.RESET }]</strong></span> untuk mereset parameter dan hasil pencarian ke awal.
                    </p>
                </>
            }
        </>
    );
}

export default Note;
