import React from "react";
import constant from '../../../config/constant';

const ComponentToPrint = React.forwardRef((props, ref) => {
  
    return (
      <div className="ticket" ref={ref}>
          <p className="centered title-header">{constant.BILL.COMP_NAME}</p>
          <p className="centered" >{constant.BILL.COMP_DESC}<br/>{constant.BILL.COMP_ADDR_LINE1}<br/>{constant.BILL.COMP_ADDR_LINE2}</p>
          
          <p className="centered border-dot-top border-dot-btm" >FAKTUR/NOTA PENJUALAN SAWIT/PBDO</p>

          <table className="cust-info">
            <tbody>
              <tr>
                <td>NO</td>
                <td>&nbsp;:&nbsp;</td>
                <td>{props.invoiceNo}</td>
              </tr>
              <tr>
                <td>TANGGAL</td>
                <td>&nbsp;:&nbsp;</td>
                <td>{props.trxDate}</td>
              </tr>
              <tr>
                <td>P K S</td>
                <td>&nbsp;:&nbsp;</td>
                <td>{props.custName}</td>
              </tr>
              <tr>
                <td>KENDARAAN</td>
                <td>&nbsp;:&nbsp;</td>
                <td>{props.vehicleNAme}</td>
              </tr>
            </tbody>
          </table>
          <br/>
          <table>
              <thead>
                  <tr className="border-dash-top border-dash-btm">
                      <td className="description">NAMA BARANG</td>
                      <td className="quantity">QTY</td>
                      <td className="price">@ HARGA</td>
                      <td className="total">JUMLAH</td>
                  </tr>
              </thead>
              <tbody>
                { props.items &&
                    props.items.filter(i => i.id !== 453).map((item, index) => {
                      return(
                        <tr key={ index }>
                            <td className="description">{ item.name }</td>
                            <td className="quantity">{ item.qty +" "+ item.unit }</td>
                            <td className="price"><p className="alignright">{item.sellingPrice.toLocaleString()}</p></td>
                            <td className="total"><p className="alignright">{(item.qty * item.sellingPrice).toLocaleString()}</p></td>
                        </tr>
                      );
                    })
                }
              </tbody>
          </table>
          
          <table>
            <tbody>
              <tr className="border-solid-top">
                <td className="left">
                  <p className="alignleft">Sub Total</p>
                </td>
                <td className="right">
                  <p className="alignleft">Rp</p>
                  {props.totalSales && <p className="alignright">{props.totalSales.toLocaleString()}</p> }
                </td>
              </tr>

              <tr>
                <td className="left">
                  <p className="alignleft">Pot Lain-lain</p>
                  <p className="alignright">- &nbsp;</p>
                </td>
                <td className="right">
                  <p className="alignleft">Rp</p>
                  {props.deduction && <p className="alignright">{props.deduction.toLocaleString()}</p>}
                </td>
              </tr>

              <tr>
                <td className="left">
                  <p className="alignleft">Pot PPH-22</p>
                  <p className="alignright">- &nbsp;</p>
                </td>
                <td className="right border-dash-btm">
                  <p className="alignleft">Rp</p>
                  {props.totalPph22 && <p className="alignright">{props.totalPph22.toLocaleString()}</p> }
                </td>
              </tr>

              <tr>
                <td className="left">
                  <p className="alignleft">Total</p>
                </td>
                <td className="right">
                  <p className="alignleft">Rp</p>
                  {props.payment && <p className="alignright">{props.payment.toLocaleString()}</p> }
                </td>
              </tr>
            </tbody>
          </table>
          
          
          <br/>
          <div>
            <p className="centered">{constant.BILL.FOOTER}</p>
          </div>
            
        </div>
    );
})

export default ComponentToPrint;