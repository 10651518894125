const Constant = {
    ACTIVE: "Aktif",
    INACTIVE: "Non Aktif",
    COMMIT: "Commit",
    VOID: "Void",
    NEW: "Tambah Data Baru",
    DETAIL: "Lihat Detail",
    SAVE: "Simpan",
    CANCEL_TRANS: "Batalkan Transaksi",
    BACK: "Kembali",
    SEARCH: "Cari",
    RESET: "Refresh",
    PRINT: "Cetak",
    MENU: "menu",
    LOCALE: "id",
    DEFAULT_DATE: "YYYY-MM-DD",
    DISPLAY_DATE: "dd-LLLL-yyyy",
    BTN_CLASS_DETAIL: "btn-info",
    MODAL_STYLE: {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }
    },
    PARAM_DATE_FORMAT: 'yyyyMMdd',
    INVOICE_FORMAT: 'yyLLddhhmmSSS',
    SELLING_MARGIN: 30,
    NO_RESULT_TEXT_ADD: 'Item baru',
    DATA_NOT_FOUND: 'Data Tidak Ditemukan',
    SUB_TOTAL: 'Sub Total',
    PAYMENT: 'Pembayaran',
    OUTSTANDING: 'Sisa Pembayaran',
    BALANCE: 'Kembali',
    DEFAULT_SELECTED: { value: '', label: 'Pilih Item' },
    BILL: { 
        COMP_NAME: "*UD. A U L I A T A N I*",
        COMP_DESC: "SEDIA PUPUK & OBAT PERTANIAN",
        COMP_ADDR_LINE1: "RANTAU SAKTI - TAM UTARA",
        COMP_ADDR_LINE2: "R I A U",
        FOOTER:"TERIMA KASIH ATAS KUNJUNGAN ANDA"
    }
}

export default Constant;
