import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {formatDate} from 'react-day-picker/moment';

import DayPicker from '../../../components/day-picker';
import TextNumber from '../../../components/text-number';
import Filter from '../../../components/filter';
import {getData} from '../../../helper/request-response';
import constant from '../../../config/constant';
import renttrxPath from './config';

const List = () => {

    const [renttrxs, setRentTrxs] = useState([]);

    const [renttrxFilter, setFilterRentTrx] = useState({
        invoiceNo: "",
        trxDate: "",
        customerName: ""
    });

    const getDetailPath = (id) => renttrxPath.detailPath.replace(":id", id);

    const onInputChange = e => {

        setFilterRentTrx({ 
            ...renttrxFilter,
            [e.target.name]: e.target.value
        });
    };

    const onDayChange = day => {

        setFilterRentTrx({ 
            ...renttrxFilter,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    }

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                invoiceNo: renttrxFilter.invoiceNo,
                trxDate: renttrxFilter.trxDate,
                customerName: renttrxFilter.customerName,
            }
        };

        getRentTrxs(params);

    };

    const getRentTrxs = async(params) => {

        const result = await getData(renttrxPath.baseApi, params);
            
        setRentTrxs(result);

    }

    const onButtonClick = e => {

        e.preventDefault();

        setFilterRentTrx({ 
            ...renttrxFilter,
            invoiceNo: "",
            trxDate: "",
            customerName: ""
        });

        getRentTrxs();

    }

    useEffect(() => { 

        getRentTrxs();

     }, []);
    

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{renttrxPath.title}</h3>
            </div>
            <div className="card-body">
                <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                <div className="form-group row">
                        <label htmlFor="renttrx-invoiceNo" className="col-md-2 form-control-label">No Transaksi</label>
                        <div className="col-md-4">
                            <input 
                                id="renttrx-invoiceNo" 
                                type="text" 
                                className="form-control"
                                name="invoiceNo"
                                value={renttrxFilter.invoiceNo}
                                onChange={ e => onInputChange(e) }
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="trx-date" className="col-md-2 form-control-label">Tanggal Pembelian</label>
                        <div className="col-md-4">
                            <DayPicker id="trx-date" name="trxDate" onDayChange={onDayChange} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="renttrxtype-customerName" className="col-md-2 form-control-label">Nama Pelanggan</label>
                        <div className="col-md-4">
                            <input 
                                id="renttrxtype-customerName" 
                                type="text" 
                                className="form-control"
                                name="customerName"
                                value={renttrxFilter.customerName}
                                onChange={ e => onInputChange(e) }
                            />
                        </div>
                    </div>

                    <Filter onButtonClick={onButtonClick} path={renttrxPath.newPath} label="Tambah Transaksi Baru" />
                </form>

                <div className="col-lg-12 mb-4">
                    <div className="row">
                        <table className="table table-striped table-hover card-text">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>No Faktur</th>
                                    <th>Tanggal</th>
                                    <th>Nama Pelanggan</th>
                                    <th>Supir</th>
                                    <th>Kode Kendaraan</th>
                                    <th>Fee</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    renttrxs.map((renttrx, index) => {
                                        return(
                                            <tr key={index}>
                                                <th scope="row">{ index + 1 }</th>
                                                <td>{ renttrx.invoiceNo }</td>
                                                <td>{ `${formatDate(renttrx.trxDate, 'LL', constant.LOCALE)}` }</td>
                                                <td>{ renttrx.customerName }</td>
                                                <td>{ renttrx.employeeName }</td>
                                                <td>{ renttrx.vehicleNo }</td>
                                                <td><TextNumber value={ renttrx.fee } /></td>
                                                <td><Link to={{ pathname: getDetailPath(renttrx.id), state: renttrx }} className="btn btn-success">{constant.DETAIL}</Link></td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
