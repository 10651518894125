import apiPath from '../../../config/api';

const harvestPath = {
    title: "TBS - Laporan Biaya Panen",
    basePath: "/sawit/laporan/biaya-panen",
    base: apiPath.palmOilHarvest,
    palmOilHarvestSum: apiPath.palmOilHarvestSum,
    palmOilHarvestExcel: apiPath.palmOilHarvestExcel,
    palmOilHarvestFind: apiPath.palmOilHarvestFind
}

export default harvestPath;
