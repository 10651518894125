import React from 'react';
import {Switch, Route} from 'react-router-dom';
import UnitList from './List';
import UnitAction from './Action';
import unitPath from './config';

const UnitRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={unitPath.basePath} exact component={UnitList} />
                <Route path={unitPath.newPath} exact component={UnitAction} />
                <Route path={unitPath.detailPath} exact component={UnitAction} />
            </Switch>
        </React.Fragment>
    );
};

export default UnitRoutes;
