import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import LinkButton from '../../../components/link-button';
import { getData, postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import customerPath from './config';
import AddressList from '../address/List';
import addressPath from '../address/config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isAddWithPage, setIsAddWithPage] = useState(false);

    const [isResetable, setIsResetable] = useState(true);

    const [titleInfo, setTitleInfo] = useState("");

    const [customertype, setCustomerType] = useState([]);

    const [customer, setCustomer] = useState({
        id: 0,
        code: "",
        name: "",
        icNumber: "",
        phoneNo: "",
        isActive: 1,
        masterCustomerTypeId: 0,
        addresses: []
    });

    const newAddressPath = {

        'pathname': addressPath.empNewAddrPath.replace(":parentId", customer.id),
        'state': {
            'address': {},
            'customerId': customer.id,
            'name': customer.name
        }

    };

    const onReset = e => {

        e.preventDefault();
        
        setCustomer(cust => ({
            ...cust,
            code: "",
            name: "",
            icNumber: "",
            phoneNo: "",
            isActive: 1,
            masterCustomerTypeId: 0,
        }));
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setCustomer(cust => ({ 
            ...cust,
            [name]: value
        }));
    };

    const onCheckboxChange = e => {

        setCustomer(cust => ({ 
            ...cust,
            isActive: customer.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postCustomer = async () => {

            const result = await postData(customerPath.baseApi, customer);

            if (Object.keys(result).length) {

                onSuccess(`Pelanggan ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putCustomer = async () => {

            const result = await putData(`${customerPath.baseApi}/${customer.id}`, customer);

            if (Object.keys(result).length) {
                
                onSuccess(`Pelanggan ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        }

        if (customer.id === 0) postCustomer();
        else putCustomer();

    }

    const setCustomerTypeState = async() => {

        const result = await getData(customerPath.baseApiCustomerType);
        
        setCustomerType(result);
        
    }

    useEffect(() => {

        setCustomerTypeState();

        const setCustomerState = () => {

            const state = refData.current.state;

            if (state) {
                
                setCustomer(state)

                setTitleInfo(`Detail: ${state.name}`);

                setIsAddWithPage(true);

                setIsResetable(false);

            } else {

                setTitleInfo("Tambah Pelanggan Baru");
                
            }

        }

        setCustomerState();

    }, []);

    /**
     * useEffect to enable or disabled save button
     */
    useEffect(() => {

        if (customer.code && customer.name && customer.masterCustomerTypeId) setIsDisabledSave(false);
        else setIsDisabledSave(true);

    }, [customer.code, customer.name, customer.masterCustomerTypeId]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{customerPath.title} | {titleInfo}</h3>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="customer-code" isCol={ true } text="Kode" />
                                <div className="col-md-9">
                                    <input 
                                        id="customer-code"
                                        type="text" 
                                        className="form-control" 
                                        name="code"
                                        value={customer.code}
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="customer-name" isCol={ true } text="Nama" />
                                <div className="col-md-9">
                                    <input 
                                        id="customer-name"
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        value={customer.name}
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="customer-type" isCol={ true } text="Tipe" isMandatory={ true } value={ customer.masterCustomerTypeId } />
                                <div className="col-md-9">
                                    <select id="customer-type" className="form-control" name="masterCustomerTypeId" onChange={ e => onInputChange(e) } value={customer.masterCustomerTypeId}>
                                        <option value="">Pilih</option>
                                        {customertype.map(({id, name}) => {return(<option key={id} value={id}>{name}</option>)})}
                                    </select>
                                    <span><LinkButton to={ customerPath.newCustType } className="stretched-link" label="Tambah Tipe Pelanggan Baru" /></span>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="customer-icNumber" isCol={ true } text="KTP" />
                                <div className="col-md-9">
                                    <input 
                                        id="customer-icNumber"
                                        type="text" 
                                        className="form-control" 
                                        name="icNumber"
                                        value={ customer.icNumber }
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="customer-phoneNo" isCol={ true } text="No Telepon" />
                                <div className="col-md-9">
                                    <input 
                                        id="customer-phoneNo"
                                        type="text" 
                                        className="form-control" 
                                        name="phoneNo"
                                        value={customer.phoneNo}
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="customer-is-active"
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            name="isActive"
                                            value={ customer.isActive }
                                            checked={ customer.isActive }
                                            onChange={ e => onCheckboxChange(e) } 
                                        />   
                                        <label htmlFor="customer-is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave }
                                        isAddWithPage={ isAddWithPage }
                                        isReset={ isResetable }
                                        onReset={ onReset }
                                        newPagePath ={ newAddressPath }
                                        addLabel="Tambah Alamat Baru" />
                                </div>
                            </div>
                            
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isAction={ true } />
                        <p>
                            <span><strong>Kode</strong></span> pelanggan tidak boleh duplikat.
                        </p>
                        {
                            !isResetable &&
                            <p>
                                Klik tombol <span><strong>[Tambah Alamat Baru]</strong></span> untuk manambahkan <strong>Alamat</strong> baru.
                            </p>
                        }
                    </div>
                </div>
                <br />
                {
                    data.state &&
                    <AddressList customerId={ data.state.id } name={ data.state.name } />
                }

            </div>
        </React.Fragment>
    );
};

export default Action;
