import React from 'react';
import { Route } from 'react-router-dom';
import SaveDebList from './List';
import SaveDebAction from './Action';
import saveDebPath from './config';

const SaveDebRoutes = () => {
    return (
        <React.Fragment>
            <Route path={saveDebPath.basePath} exact component={SaveDebList} />
            <Route path={saveDebPath.newPath} exact component={SaveDebAction} />
            <Route path={saveDebPath.detailPath} exact component={SaveDebAction} />
        </React.Fragment>
    );
};

export default SaveDebRoutes;