import apiPath from '../../../config/api';

const BankAccPath = {
    'title': 'Rekening Bank', 
    'base': '/master/rekening',
    'new': '/master/rekening/baru',
    'detail': '/master/rekening/opsi/:id',
    'api': apiPath.masterBankAccount,
    'apiBank': apiPath.masterBankFind
}

export default BankAccPath;
