import React from "react";
import constant from '../../../config/constant';

const ComponentToPrint = React.forwardRef((props, ref) => {
    
    return (
      <div className="ticket" ref={ref}>
          <p className="centered title-header">{constant.BILL.COMP_NAME}</p>
          <p className="centered" >{constant.BILL.COMP_DESC}<br/>{constant.BILL.COMP_ADDR_LINE1}<br/>{constant.BILL.COMP_ADDR_LINE2}</p>

          <p className="centered border-dot-top border-dot-btm" >FAKTUR/NOTA PEMBELIAN SAWIT/PBDO</p>

          <table className="cust-info">
            <tbody>
            <tr>
              <td>NO</td>
              <td>&nbsp;:&nbsp;</td>
              <td>{props.invoiceNo}</td>
            </tr>
            <tr>
              <td>TANGGAL</td>
              <td>&nbsp;:&nbsp;</td>
              <td>{props.trxDate}</td>
            </tr>
            <tr>
              <td>N A M A</td>
              <td>&nbsp;:&nbsp;</td>
              <td>{props.custName}</td>
            </tr>
            <tr>
              <td>TYPE</td>
              <td>&nbsp;:&nbsp;</td>
              <td>{props.custTypeName}</td>
            </tr>
            <tr>
              <td>KENDARAAN</td>
              <td>&nbsp;:&nbsp;</td>
              <td>{props.vehicleCode}</td>
            </tr>
            <tr>
              <td>JALUR</td>
              <td>&nbsp;:&nbsp;</td>
              <td>{props.retribution.replace("BIAYA RETRIBUSI - ","")}</td>
            </tr>
            </tbody>
          </table>
          <br/>
          <table>
              <thead>
                  <tr className="border-dash-top border-dash-btm">
                      <td className="description">NAMA BARANG</td>
                      <td className="quantity">QTY</td>
                      <td className="price">@ HRG</td>
                      <td className="total">JUMLAH</td>
                  </tr>
              </thead>
              <tbody>
                  { 
                    props.items
                      .filter(gns => gns.id !== 453)
                      .map((item, index) => {
                        return(
                          <tr key={ index }>
                              <td className="description">{ item.name }</td>
                              <td className="quantity">{ item.qty +" "+ item.unit }</td>
                              <td className="price"><p className="alignright">{item.purchasePrice.toLocaleString()}</p></td>
                              <td className="total"><p className="alignright">{(item.isSubtrahend === 1 ? (parseFloat(item.qty) * parseFloat(item.purchasePrice)) * -1 : parseFloat(item.qty) * parseFloat(item.purchasePrice) ).toLocaleString()}</p></td>
                          </tr>
                        )
                      })
                  }
              </tbody>
          </table>
          
          <table>
            <tbody>
            <tr className="border-solid-top">
              <td className="left">
                <p className="alignleft">SUB TOTAL</p>
              </td>
              <td className="right">
                <p className="alignleft">Rp</p>
                <p className="alignright">{props.totalPurchase.toLocaleString()}</p>
              </td>
            </tr>

            <tr>
              <td className="left">
                <p className="alignleft">PEMBULATAN</p>
              </td>
              <td className="right">
                <p className="alignleft">Rp</p>
                <p className="alignright">{props.rounding.toLocaleString()}</p>
              </td>
            </tr>

            <tr>
              <td className="left">
                <p className="alignleft">CICILAN PINJAMAN</p>
                <p className="alignright">- &nbsp;</p>
              </td>
              <td className="right border-dash-btm">
                <p className="alignleft">Rp</p>
                <p className="alignright">{props.loanPayment.toLocaleString()}</p>
              </td>
            </tr>

            <tr>
              <td className="left">
                <p className="alignleft">TOTAL UANG DITERIMA</p>
              </td>
              <td className="right">
                <p className="alignleft">Rp</p>
                <p className="alignright">{props.payment.toLocaleString()}</p>
              </td>
            </tr>
            <tr>
              <td colSpan={2}><hr /></td>
            </tr>
            <tr>
              <td className="left">
                <p className="alignleft">SISA PINJAMAN YG LALU</p>
              </td>
              <td className="right">
                <p className="alignleft">Rp</p>
                <p className="alignright">{props.loanBalance.toLocaleString()}</p>
              </td>
            </tr>

            <tr>
              <td className="left">
                <p className="alignleft">CICILAN PINJAMAN</p>
                <p className="alignright">- &nbsp;</p>
              </td>
              <td className="right border-dash-btm">
                <p className="alignleft">Rp</p>
                <p className="alignright">{props.loanPayment.toLocaleString()}</p>
              </td>
            </tr>

            <tr>
              <td className="left">
                <p className="alignleft">SISA PINJAMAN SAAT INI</p>
              </td>
              <td className="right">
                <p className="alignleft">Rp</p>
                <p className="alignright">{(props.loanBalance - props.loanPayment).toLocaleString()}</p>
              </td>
            </tr>
            <tr>
              <td colSpan={2}><hr /></td>
            </tr>
            <tr>
              <td className="left">
                <p className="alignleft">DITABUNG</p>
              </td>
              <td className="right">
                <p className="alignleft">Rp</p>
                <p className="alignright">{props.paymentToSave.toLocaleString()}</p>
              </td>
            </tr>

            <tr>
              <td className="left">
                <p className="alignleft">TOTAL TABUNGAN YG LALU</p>
              </td>
              <td className="right border-dash-btm">
                <p className="alignleft">Rp</p>
                <p className="alignright">{(props.savingBalance - props.paymentToSave).toLocaleString()}</p>
              </td>
            </tr>

            <tr>
              <td className="left">
                <p className="alignleft">TOTAL TABUNGAN SAAT INI</p>
              </td>
              <td className="right">
                <p className="alignleft">Rp</p>
                <p className="alignright">{props.savingBalance.toLocaleString()}</p>
              </td>
            </tr>
            </tbody>
          </table>
          
          
          <br/>
          <div>
            <p className="centered">{constant.BILL.FOOTER}</p>
          </div>
            
        </div>
    );
})

export default ComponentToPrint;