import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CustTrxList from './List';
import custTrxPath from './config';

const CustTrxRoutes = () => {
    return (
        <Switch>
            <Route path={custTrxPath.basePath} exact component={CustTrxList} />
        </Switch>
    );
};

export default CustTrxRoutes;