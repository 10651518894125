import React from 'react';
import { Switch, Route } from 'react-router-dom';
import VehiclesList from './List';
import VehiclesAction from './Action';
import vehiclesPath from './config';

const VehiclesRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={vehiclesPath.basePath} exact component={VehiclesList} />
                <Route path={vehiclesPath.newPath} exact component={VehiclesAction} />
                <Route path={vehiclesPath.detailPath} exact component={VehiclesAction} />
            </Switch>
        </React.Fragment>
    );
};

export default VehiclesRoutes;
