import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';
import { useReactToPrint } from 'react-to-print';
import { Modal } from 'react-responsive-modal';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import Note from '../../../components/note';
import Label from '../../../components/label';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import LinkButton from '../../../components/link-button';
import ActionButton from '../../../components/action-button';
import ClickButton from '../../../components/click-button';
import InputNumeral from '../../../components/input-numeral';
import TextNumber from '../../../components/text-number';
import { deleteData, getData, postData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import { getPbdoBalance, getPoBalance } from '../../../helper/cash-balance';
import constant from '../../../config/constant';
import poPurchasePath from './config';
import ComponentToPrint from './print.js';

const Action = () => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const data = useLocation();
    const refData = useRef(data);
    const history = useHistory();

    const [poBalance, , setPoBalance] = useGlobalState('poBalance');

    const [pbdoBalance, , setPbdoBalance] = useGlobalState('pbdoBalance');

    /**
     * [modalIsOpen] state
     */
    const [modalIsOpen, setIsOpen] = useState(false);

    /**
     * [titleInfo] state
     */
    const [titleInfo, setTitleInfo] = useState("");

    /**
     * [isDisabledSave] state
     */
    const [isDisabledSave, setIsDisabledSave] = useState(true);

    /**
     * [isDisabledAdd]
     */
    const [isDisabledAdd, setIsDisabledAdd] = useState(true);

    /**
     * [isView] state
     */
    const [isNew, setIsNew] = useState(false);

    const [isAbnormalPrice, setIsAbnormalPrice] = useState(false);

    /**
     * [idxItem] state
     */
    const [idxItem, setIdxItem] = useState(-1);

    const [selectedSales, setSelectedSales] = useState(constant.DEFAULT_SELECTED);

    const [selectedCust, setSelectedCust] = useState(constant.DEFAULT_SELECTED);

    const [selectedGnsName, setSelectedGnsName] = useState(constant.DEFAULT_SELECTED);

    const [savingBalance, setSavingBalance] = useState(0);

    /**
     * [sales] state
     */
    const [poPurchase, setPoPurchase] = useState({
        id: 0,
        isEdit: false,
        invoiceNo: `PP${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
        trxDate: DateTime.local(),
        trip: 0,
        salesId: 0,
        salesInvoiceNo: "",
        customerId: 0,
        customerName: "",
        customerType: 0,
        customerTypeName: '',
        lineNo: 0,
        isCommit: 1,
        totalPurchase: 0,
        totalRounding: 0,
        rounding: 0,
        loanBalance: 0,
        loanPayment: 0,
        savingBalance: 0,
        isSaving: 0,
        payment: 0,
        paymentToSave: 0,
        apArId: 0,
        purchasePoCoa: '',
        purchasePoCoaDesc: '',
        retribution: '',
        retributionPrice: 0,
        items:[],
        vehicleCode:'',
        driverName: ''
    });

    const gnsEntity = {
        id: 0,
        code: '',
        name: '',
        purchasePrice: 0,
        sellingPrice: 0,
        qty: 0,
        stock: 0,
        isSubtrahend: false,
        unitId: 0,
        unit: '',
        businessUnitId: 2,
        coaId: 0,
        coaAccount: '',
        coaDescription: ''
    }

    /**
     * [goodsAndService] state
     */
    const [gns, setGns] = useState(gnsEntity);

    const [entityId, setEntityId] = useState(3);

    /**
     * Reset state of [goodsAndServices] and [updateItem]
     */
    const resetGns = () => {

        setGns(gnsEntity);

        setSelectedGnsName(constant.DEFAULT_SELECTED);

    }

    /**
     * Autocomplete when name of [GoodsAndService] field are changed
     * @param {object} opt 
     */
    const onGnsNameChange = opt => {

        const getCoa = () => {

            if (poPurchase.customerTypeName.toLowerCase() === 'petani') {
    
                return {
                    coaId: 23,
                    coaAccount: '5001001',
                    coaDescription: 'PEMBELIAN TBS PETANI'
                }
            }
    
            return {
                coaId: 24,
                coaAccount: '5002101',
                coaDescription: 'PEMBELIAN TBS AGEN'
            }
        }

        if (opt.id) {

            const coa = getCoa();

            setGns(gns => ({
                ...gns,
                id: opt.id,
                code: opt.code,
                name: opt.name,
                purchasePrice: opt.purchasePrice,
                sellingPrice: opt.sellingPrice,
                qty: opt.qty,
                stock: opt.stock,
                isSubtrahend: opt.isSubtrahend,
                unitId: opt.unitId,
                unit: opt.unit,
                businessUnitId: opt.businessUnitId,
                coaId: opt.coaId,
                coaAccount: opt.coaAccount,
                coaDescription: opt.coaDescription
            }));

            if (opt.id === 349) {

                setGns(gns => ({
                    ...gns,
                    coaId: coa.coaId,
                    coaAccount: coa.coaAccount,
                    coaDescription: coa.coaDescription
                }));

            } 

            setSelectedGnsName(gns => ({
                ...gns,
                value: opt.id,
                label: opt.name
            }));

        } 

    }

    /**
     * Calculate total of purchased items from supplier
     * @param {Array} items 
     */
    const calculateTotal = items => {

        let total = 0;

        for(let i = 0; i < items.length; i++) {

            const qty = parseFloat(items[i].qty);
            const purchasePrice = parseFloat(items[i].purchasePrice);

            if (parseFloat(items[i].isSubtrahend) === 1) total -= (qty * purchasePrice);
            else total += (qty * purchasePrice);

        }

        return total;

    };

    const onTripChange = values => {

        const { value } = values;

        setPoPurchase(purchase => ({
            ...purchase,
            trip: parseFloat(value)
        }));

    }

    const onPurchasePriceChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            purchasePrice: parseFloat(value)
        }));
    }

    const onQtyChange = values => {

        const { value } = values;

        setGns(gns => ({
            ...gns,
            qty: parseFloat(value)
        }));
    }

    const onLoanPaymentChange = values => {

        const { value } = values;

        setPoPurchase(purchase => ({
            ...purchase,
            loanPayment: parseFloat(value) > purchase.loanBalance ? purchase.loanBalance : parseFloat(value),
            payment: parseFloat(purchase.totalRounding) - parseFloat(value) > 0 ? parseFloat(purchase.totalRounding) - parseFloat(value) : 0
        }));
    }

    const onPaymentSaveChange = values => {

        const { value } = values;

        setPoPurchase(purchase => ({
            ...purchase,
            paymentToSave: (parseFloat(value) <= (parseFloat(poPurchase.totalRounding) - parseFloat(poPurchase.loanPayment)) && parseFloat(value) > 0) ? parseFloat(value) : (parseFloat(poPurchase.totalRounding) - parseFloat(poPurchase.loanPayment)),
            payment: (parseFloat(value) <= (parseFloat(poPurchase.totalRounding) - parseFloat(poPurchase.loanPayment)) && parseFloat(value) > 0) ? parseFloat(poPurchase.totalRounding) - parseFloat(poPurchase.loanPayment) - parseFloat(value) : parseFloat(poPurchase.totalRounding) - parseFloat(poPurchase.loanPayment),
            savingBalance: parseFloat(value) > 0 ? parseFloat(savingBalance) + parseFloat(value) : parseFloat(savingBalance)
        }));
    }

    const onConfirmItem = async e => {

        e.preventDefault();

        /**
         * Add [GoodsAndServices] to purchased items
         */
        const addPurchasedItem = () => new Promise((resolve, reject) => {

            let gsItems = poPurchase.items;
            gsItems.push(gns);

            if (gns.id === 349) {
                gsItems.push({
                    id: 352,
                    code: 'RETR',
                    name: 'BIAYA RETRIBUSI',
                    purchasePrice: parseFloat(poPurchase.retributionPrice),
                    sellingPrice: 0,
                    qty: gns.qty,
                    stock: 0,
                    isSubtrahend: 1,
                    unitId: 7,
                    unit: 'KG',
                    businessUnitId: 3,
                    coaId: 12,
                    coaAccount: '2002000',
                    coaDescription: 'BIAYA RETRIBUSI JALAN'
                });
            }

            resolve(gsItems);
        });

        /**
         * Update [GoodsAndServices] in purchased items based on [idxItem] state
         */
        const updatePurchasedItem = () => {

            let gsItems = poPurchase.items;

            for (let i = 0; i < gsItems.length; i++) {

                if (i === idxItem) {

                    gsItems[i].id = gns.id;
                    gsItems[i].code = gns.code;
                    gsItems[i].name = gns.name;
                    gsItems[i].purchasePrice = gns.purchasePrice;
                    gsItems[i].qty = gns.qty;

                    break;
                } 
            }

            return gsItems;

        }

        let gsItems = [];

        if (idxItem < 0) gsItems = await addPurchasedItem();
        else gsItems = updatePurchasedItem();

        const total = calculateTotal(gsItems);
        const totalRounding = Math.round(total / 100) * 100;
        const rounding = total - totalRounding;

        setPoPurchase(data => ({
            ...data,
            totalPurchase: total,
            totalRounding: totalRounding,
            rounding: rounding,
            payment: parseFloat(totalRounding) - parseFloat(data.loanPayment) - parseFloat(data.paymentToSave),
            items: gsItems
        }));

        resetGns();

        setIdxItem(-1);

        setIsOpen(false);

        setIsAbnormalPrice(false);
    }

    /**
     * Event triggered when [GoodsAndServices] added to purchased item
     * @param {object} e 
     */
    const onAddItem = e => {

        e.preventDefault();

        if (gns.purchasePrice > gns.qty) setIsAbnormalPrice(true);

        if (gns.id === 349) setIsOpen(true);
        else onConfirmItem(e);
        
    }

    /**
     * Close modal
     * @param {Object} e 
     */
    const closeModal = () => {

        setIsOpen(false);

        setIsAbnormalPrice(false);
        
    }

    /**
     * Autocomplete when [Farmer] field is changed
     * @param {object} opt 
     */
    const onCustomerChange = opt => {

        if (opt.id) {

            setPoPurchase(data => ({ 
                ...data,
                apArId: opt.apArId,
                customerId: opt.id,
                customerName: opt.name,
                customerType: opt.masterCustomerTypeId,
                customerTypeName: opt.customerType,
                loanBalance: opt.arAmount,
                savingBalance: opt.apAmount,
                retribution: opt.retribution,
                retributionPrice: opt.retributionPrice
            }));

            setSavingBalance(opt.apAmount);

            setSelectedCust(cust => ({
                ...cust,
                value: opt.id,
                label: opt.name
            }));

        } 

    }

    const onSalesInvChange = opt => {

        if (opt.id) {

            setPoPurchase(purch => ({
                ...purch,
                salesId: _.get(opt, 'id'),
                salesInvoiceNo: _.get(opt, 'invoiceNo'),
                vehicleCode: _.get(opt, 'vehicleCode'),
                driverName: _.get(opt, 'driver')
            }));

            if (!_.isEmpty(opt)) {

                if (_.get(opt, 'vehicleCode') === 'PBDO') setEntityId('4');
                else setEntityId('3');

            }

            setSelectedSales(sales => ({
                ...sales,
                value: _.get(opt, 'id'),
                label: _.get(opt, 'invoiceNo'),
            }));

        }
    }

    /**
     * Event triggerd when transaction date is selected
     * @param {string} day 
     */
    const onDayChange = day => {

        setPoPurchase(data => ({ 
            ...data,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));

    }

    const onCheckboxChange = e => {

        setPoPurchase(data => ({ 
            ...data,
            isSaving: data.isSaving === 1 ? 0 : 1,
        }));

    }

    /**
     * Event trigered when item in purchased items is clicked
     * @param {int} index 
     */
    const onViewDetail = index => {

        const item = poPurchase.items[index];

        setGns(data => ({
            ...data,
            id: item.id,
            code: item.code,
            name: item.name,
            unit: item.unit,
            unitId: item.unitId,
            stock: item.stock,
            qty: item.qty,
            purchasePrice: item.purchasePrice,
        }));

        setSelectedGnsName(gns => ({
            ...gns,
            value: item.id,
            label: item.name
        }));

        setIdxItem(index);
    }

    /**
     * Event trigered when main form of purchase is submited
     * @param {object} e 
     */
    const onSubmit = (e) => {

        e.preventDefault();

        setPoBalance(0);
        setPbdoBalance(0);

        /**
         * Create new purchase data
         */
        const post = async () => {

            const result = await postData(poPurchasePath.base, poPurchase);

            if (!_.isEmpty(result)) {

                onSuccess(`Pembelian ${poPurchase.invoiceNo} berhasil di tambahkan`);

                history.goBack();

                getPoBalance();

                getPbdoBalance();

            }
        };

        const update = async() => {

            const historyResult = await postData(poPurchasePath.baseHistory, poPurchase);

            if (historyResult) {

                const params = {
                    params: { 
                        'invoice-no': poPurchase.invoiceNo,
                        'sales-invoice-no': poPurchase.salesInvoiceNo
                    }
                };
                
                const removeResult = await deleteData(poPurchasePath.base, params)

                if (removeResult) post();
            }
        }

        if (poPurchase.isEdit) {

            update();

        } else {
            
            if (poPurchase.id === 0 && poPurchase.totalPurchase) post()

        };
        
    }

    useEffect(() => {

        const getPurchaseDetail = async (id) => {

            const result = await getData(`${ poPurchasePath.base }/${id}`);

            return result;
        }

        const init = () => {

            const state = refData.current.state;

            if (state) {
    
                setPoPurchase(state);

                setSelectedSales(sales => ({
                    ...sales,
                    value: state.salesInvoiceId,
                    label: state.salesInvoiceNo
                }));

                setSelectedCust(cust => ({
                    ...cust,
                    value: state.customerId,
                    label: state.customerName
                }));
                
                setTitleInfo(`Detail: ${state.invoiceNo}`);

                getPurchaseDetail(state.id)
                    .then(result => {

                        const total = calculateTotal(result);

                        setPoPurchase(data => ({
                            ...data,
                            salesId: data.salesInvoiceId,
                            isEdit: refData.current.isEdit,
                            loanBalance: refData.current.isEdit ? data.loanBalance + data.loanPayment : data.loanBalance,
                            totalPurchase: total,
                            totalRounding: Math.round(total / 100) * 100,
                            recordType: 'EDIT',
                            isCommit: true,
                            items: result
                        }));

                    });
    
            } else {
    
                setTitleInfo("Tambah Pembelian Baru");

                setIsNew(true);
                
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (poPurchase.vehicleCode === "PBDO") {
            
            if (poPurchase.invoiceNo && poPurchase.salesInvoiceNo && poPurchase.customerId && poPurchase.trxDate && poPurchase.totalPurchase && pbdoBalance > poPurchase.totalPurchase) setIsDisabledSave(false);
            else setIsDisabledSave(true);

        } else {

            if (poPurchase.invoiceNo && poPurchase.salesInvoiceNo && poPurchase.customerId && poPurchase.trxDate && poPurchase.totalPurchase && poBalance > poPurchase.totalPurchase) setIsDisabledSave(false);
            else setIsDisabledSave(true);

        }

        if (gns.id === 349) {

            if (gns.id && gns.purchasePrice && gns.qty) setIsDisabledAdd(false);
            else setIsDisabledAdd(true);

        } else {

            if (gns.id && gns.purchasePrice > -1 && gns.qty) setIsDisabledAdd(false);
            else setIsDisabledAdd(true);

        }

    }, [poPurchase.invoiceNo, poPurchase.salesInvoiceNo, poPurchase.customerId, poPurchase.trxDate, poPurchase.totalPurchase, gns.id, gns.purchasePrice, gns.qty, entityId, poPurchase.vehicleCode, poBalance, pbdoBalance])

    return(
        <React.Fragment>
            { isNew &&
                <div style={{display:"none"}}>
                    <ComponentToPrint 
                        invoiceNo = {poPurchase.invoiceNo}
                        trxDate = {`${formatDate(poPurchase.trxDate, 'LL', constant.LOCALE)}`}
                        custName = {poPurchase.customerName}
                        custTypeName = {poPurchase.customerTypeName}
                        totalPurchase = {poPurchase.totalPurchase}
                        rounding = {poPurchase.rounding}
                        loanBalance = {poPurchase.loanBalance}
                        loanPayment = {poPurchase.loanPayment}
                        savingBalance = {poPurchase.savingBalance}
                        payment = {poPurchase.payment}
                        paymentToSave = {poPurchase.paymentToSave}
                        retribution = {poPurchase.retribution}
                        items = {poPurchase.items}
                        itemsTbs = {poPurchase.items.find(gns => gns.id === 349) ? poPurchase.items.find(gns => gns.id === 349) : ""}
                        itemsRetr = {poPurchase.items.find(gns => gns.id === 352) ? poPurchase.items.find(gns => gns.id === 352) : ""}
                        itemsHarvest = {poPurchase.items.find(gns => gns.id === 350) ? poPurchase.items.find(gns => gns.id === 350) : ""}
                        vehicleCode = {poPurchase.vehicleCode}
                        
                        ref={componentRef} />

                        <button onClick={handlePrint}>Print this out!</button>
                </div>
            }

            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ poPurchasePath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">

                <div className="row">
                    <Modal open={ modalIsOpen } onClose={ closeModal } center>
                        <div className="modal-header">
                            <h5 className="modal-title">KONFIRMASI PEMBELIAN { gns.name }</h5>
                        </div>

                        <div className="modal-body">
                            <table>
                                <tbody>
                                    <tr className="text-success">
                                        <td><h6>Harga Beli</h6></td>
                                        <td>:</td>
                                        <td><h6><TextNumber value={ gns.purchasePrice } /></h6></td>
                                    </tr>
                                    <tr className="text-primary">
                                        <td><h6>Quantity({gns.unit})</h6></td>
                                        <td>:</td>
                                        <td><h6><TextNumber value={ gns.qty } /></h6></td>
                                    </tr>
                                    {
                                        isAbnormalPrice &&
                                        <tr className="text-danger">
                                            <td colSpan={3}><h6>Harga (Rp) Pembelian Lebih Besar Dari Tonase (Kg) Pembelian. Harap Di Cek!</h6></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer">
                            <ClickButton onClick={ onConfirmItem } label="Simpan" className="btn btn-primary" />
                        </div>
                    </Modal>
                </div>

                <div className="row">

                    <div className="col-md-6 border-right">
                        <form>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="inv-no" text="No Faktur" />
                                    <input 
                                        id="inv-no" 
                                        type="text" 
                                        className="form-control"
                                        value={ poPurchase.invoiceNo }
                                        disabled
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="sales-inv-no" text="Faktur Penjualan" isMandatory={ true } value={ poPurchase.salesInvoiceNo } />
                                    <Select
                                        id="sales-inv-no"
                                        api={ poPurchasePath.palmOilSalesFind }
                                        onChange={ onSalesInvChange }
                                        value={ selectedSales }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Label text="Supir"/>
                                            <input type="text" className="form-control" name="driverName" disabled value={ poPurchase.driverName } />
                                        </div>
                                        <div className="col-md-6">
                                            <Label text="Kendaraan"/>
                                            <input type="text" className="form-control" name="vehicleCode" disabled value={ poPurchase.vehicleCode } />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal" isMandatory={ true } value={ poPurchase.trxDate } />
                                    <div>
                                        <DayPicker id="trx-date" name="trx-date" onDayChange={onDayChange} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="trip" text="Trip" />
                                    <InputNumeral
                                        id="trip"
                                        value={ poPurchase.trip }
                                        onValueChange={ onTripChange }
                                    />
                                </div>
                            </div>

                            {
                                entityId &&
                                <>
                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <Label htmlFor="cust" text="Pelanggan" isMandatory={ true } value={ poPurchase.customerId } />
                                            <Select
                                                id="cust"
                                                paramFilter="name"
                                                api={ poPurchasePath.custFind }
                                                entityId={ entityId }
                                                onChange={ onCustomerChange }
                                                value={ selectedCust }
                                                key={ entityId }
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <Label text="Tipe Pelanggan"/>
                                            <input type="text" className="form-control" name="customerTypeName" disabled value={ poPurchase.customerTypeName } />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-8">
                                            <Label text="Retribusi" />
                                            <input type="text" className="form-control" disabled value={ poPurchase.retribution } />
                                        </div>

                                        <div className="col-md-4">
                                            <Label text="Retribusi (Rp)" />
                                            <InputNumeral
                                                id="retribution-price"
                                                value={ poPurchase.retributionPrice }
                                                display={ true }
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </form>
                    </div>

                    <div className="col-md-6">
                        <form onSubmit={ e => onSubmit(e) }>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label text="Total Pembelian (Rp)" isMandatory={ true } value={ poPurchase.totalPurchase } />
                                    <InputNumeral
                                        id="total"
                                        value={ poPurchase.totalPurchase }
                                        display={ true }
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label text="Pembulatan (Rp)" />
                                    <InputNumeral
                                        id="rounding"
                                        value={ parseFloat(poPurchase.totalRounding) }
                                        display={ true }
                                    />
                                </div>
                            </div>

                            {
                                (isNew || poPurchase.isEdit) && parseFloat(poPurchase.loanBalance) > 0 &&
                                <>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <Label text="Saldo Pinjaman (Rp)" />
                                            <InputNumeral
                                                id="loan-balance"
                                                value={ poPurchase.loanBalance }
                                                display={ true }
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <Label htmlFor="loan-payment" text="Pinjaman Di Bayar (Rp)" />
                                            <InputNumeral
                                                id="loan-payment"
                                                isDisabled={ !poPurchase.totalPurchase }
                                                value={ poPurchase.loanPayment }
                                                onValueChange={ onLoanPaymentChange }
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                !isNew && !poPurchase.isEdit &&
                                <>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <Label text="Pembayaran Di Tabungkan (Rp)" />
                                            <InputNumeral
                                                value={ poPurchase.paymentToSave }
                                                display={ true }
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <Label text="Pinjaman Di Bayar (Rp)" />
                                            <InputNumeral
                                                value={ poPurchase.loanPayment }
                                                display={ true }
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label text="Jumlah Pembayaran (Rp)" />
                                    <InputNumeral
                                        id="payment"
                                        value={ poPurchase.payment }
                                        display={ true }
                                    />
                                </div>
                            </div>

                            {
                                (isNew || poPurchase.isEdit) &&
                                poPurchase.loanBalance === 0 &&
                                <>
                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <div className="custom-control custom-checkbox">
                                                <input 
                                                    id="is-saving"
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    name="isSaving"
                                                    disabled={ !poPurchase.totalPurchase }
                                                    value={ poPurchase.isSaving }
                                                    checked={ poPurchase.isSaving }
                                                    onChange={ e => onCheckboxChange(e) }
                                                />   
                                                <label htmlFor="is-saving" className="custom-control-label">Pembayaran Di Tabungkan</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <Label id="balance-to-save" text="Jumlah Di Tabungkan (Rp)" />
                                            <InputNumeral
                                                id="balance-to-save"
                                                value={ poPurchase.paymentToSave }
                                                isDisabled={ poPurchase.isSaving ? false : true }
                                                onValueChange={ onPaymentSaveChange }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Label text="Saldo Tabungan (Rp)" />
                                            <InputNumeral
                                                id="saving-balance"
                                                value={ poPurchase.savingBalance }
                                                display={ true }
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label />
                                    <ActionButton 
                                        isPrint={ isNew || poPurchase.isEdit ? true: false }
                                        onPrint={handlePrint}
                                        isDisabledSave={ isDisabledSave }
                                        addLabel="Tambah Detail Pembelian" />
                                </div>
                                {
                                    (poPurchase.totalPurchase > poBalance) && poPurchase.vehicleCode !== "PBDO" &&
                                    <div className="col-md-12"><Label text="Kas TBS Tidak Cukup Untuk Pembayaran" isMandatory={ true } value={ poBalance } /></div>
                                }
                                {
                                    (poPurchase.totalPurchase > pbdoBalance) && poPurchase.vehicleCode === "PBDO" && 
                                    <div className="col-md-12"><Label text="Kas PBDO Tidak Cukup Untuk Pembayaran" isMandatory={ true } value={ pbdoBalance } /></div>
                                }
                            </div>

                            <br />
                            <br />
                            <div className="form-group row">
                                <div className="col-md-12"><Note isAction={ true } /></div>
                            </div>
                        </form>
                    </div>
                </div>

                {
                    (isNew || poPurchase.isEdit) && 
                    selectedCust.value !== '' &&
                    selectedSales.value !== '' &&
                    <>
                        <div className="line"></div>
                
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <Label htmlFor="gns-name" text="Nama Barang" isMandatory={ true } value={ gns.id } />
                                        <Select 
                                            id="gns-name"
                                            api={ poPurchasePath.gnsFind }
                                            entityId="3"
                                            value={ selectedGnsName }
                                            onChange={ onGnsNameChange }
                                        />
                                        <span><LinkButton to={ poPurchasePath.newGns } className="stretched-link" label="Tambah Barang dan Jasa Baru" /></span>
                                    </div>

                                    <div className="col-md-2">
                                        <Label htmlFor="satuan" text="Satuan" />
                                        <input id="satuan" type="text" className="form-control" value={ gns.unit } disabled />
                                    </div>

                                    <div className="col-md-2">
                                        <Label htmlFor="qty" text={ gns.unit ? `Qty (${gns.unit})` : 'Qty'} isMandatory={ true } value={ gns.qty } />
                                        <InputNumeral
                                            id="qty"
                                            value={ gns.qty }
                                            onValueChange={ onQtyChange }
                                        />
                                    </div>

                                    <div className="col-md-2">
                                        <Label htmlFor="purchase-price" text="Harga Beli (Rp)" isMandatory={ gns.id === 349 ? true : false } value={ gns.purchasePrice } />
                                        <InputNumeral
                                            id="purchase-price"
                                            value={ gns.purchasePrice }
                                            onValueChange={ onPurchasePriceChange }
                                        />
                                    </div>

                                    <div className="col-md-2">
                                        <Label />
                                        <div className="btn-group d-flex">
                                            <ClickButton isDisabled={ isDisabledAdd } className="p-2 flex-fill btn btn-success" label={ idxItem === -1 ? `Tambah` : `Perbaharui` } onClick={ onAddItem } />
                                            {
                                                gns.name &&
                                                <ClickButton onClick={ resetGns } className="p-2 flex-fill btn btn-info" />
                                            }
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </>
                }

                <br />

                {
                    poPurchase.items && 
                    selectedCust.value !== '' &&

                    <div className="col-lg-12 mb-4">
                        <div className="row">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nama Barang</th>
                                            <th>Satuan</th>
                                            <th>Harga</th>
                                            <th>Qty</th>
                                            <th>Total</th>
                                            {
                                                (isNew || poPurchase.isEdit) &&
                                                <th>Detail</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            poPurchase.items.map((item, index) => {
                                                return(
                                                    <tr key={ index }>
                                                        <th scope="row">{ index + 1 }</th>
                                                        <td>{ item.name }</td>
                                                        <td>{ item.unit }</td>
                                                        <td><TextNumber value={ item.purchasePrice } /></td>
                                                        <td><TextNumber value={ item.qty } /></td>
                                                        <td><TextNumber value={ item.isSubtrahend === 1 ? (parseFloat(item.qty) * parseFloat(item.purchasePrice)) * -1 : parseFloat(item.qty) * parseFloat(item.purchasePrice) } /></td>
                                                        {
                                                            (isNew || poPurchase.isEdit) &&
                                                            <td>
                                                                <button className={`btn ${constant.BTN_CLASS_DETAIL}`} onClick={ () => onViewDetail(index) }>Detail</button>
                                                            </td>
                                                        }
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </React.Fragment>
    );
};

export default Action;
