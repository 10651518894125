import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TrxList from './List';
import trxPath from './config';

const TrxRoutes = () => {
    return (
        <Switch>
            <Route path={trxPath.basePath} exact component={TrxList} />
        </Switch>
    );
};

export default TrxRoutes;