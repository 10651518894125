import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import InputNumeral from '../../../components/input-numeral';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import retributionPath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();

    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const retributionEntity = {
        id: 0,
        code: "",
        name: "",
        amount: 0
    }

    const [retribution, setRetribution] = useState(retributionEntity);

    const onReset = e => {

        e.preventDefault();

        setRetribution(retributionEntity);
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setRetribution(role => ({ 
            ...role,
            [name]: value
        }));
    };

    const onAmountChange = values => {

        const { value } = values;

        setRetribution(retr => ({
            ...retr,
            amount: parseFloat(value)
        }));
    }

    const onSubmit = (e) => {

        e.preventDefault();

        const postRetribution = async () => {

            const result = await postData(retributionPath.baseApi, retribution);
            
            if (Object.keys(result).length) {

                onSuccess(`Retribusi ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putRetribution = async () => {

            const result = await putData(`${retributionPath.baseApi}/${retribution.id}`, retribution);

            if (Object.keys(result).length) {
                
                onSuccess(`Role ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        }

        if (retribution.id === 0 && retribution.name) postRetribution();
        else putRetribution();

    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state) {
                
                setRetribution(state);
                
                setTitleInfo(`Detail: ${state.name}`);

                setIsResetable(false);
    
            } else {
    
                setTitleInfo("Tambah Role Baru");
    
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (retribution.name) setIsDisabledSave(false);

    }, [retribution.name]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{retributionPath.title} | {titleInfo}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="code" isCol={ true } text="Kode" isMandatory={ true } value={ retribution.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="code"
                                        type="text" 
                                        className="form-control" 
                                        name="code"
                                        value={retribution.code}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="name" isCol={ true } text="Nama" isMandatory={ true } value={ retribution.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="name"
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        value={retribution.name}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="amount" isCol={ true } text="Retribusi (Rp)" isMandatory={ true } value={ retribution.amount } />
                                <div className="col-md-9">
                                    <InputNumeral
                                        id="amount"
                                        value={ retribution.amount }
                                        onValueChange={ onAmountChange }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset }/>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Action;
