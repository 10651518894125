import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import Label from '../../../components/label';
import InputNumeral from '../../../components/input-numeral';
import TextNumber from '../../../components/text-number';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import balanceSheetDetailPath from './config';
import LoaderType from '../../../components/loader-type';
import constant from '../../../config/constant';

const List = () => {

    const [isLoad, setIsLoad] = useState(true);

    const [totalAsset, setTotalAsset] = useState(0);

    const [totalDebt, setTotalDebt] = useState(0);

    const [balance, setBalance] = useState(0);

    const [accountBalanceDetail, setAccountBalanceDetail] = useState([]);

    const [accountBalanceDebtDetail, setAccountBalanceDebtDetail] = useState([]);

    const getParams = () => {

        const params = {
            params: { 
                'trx-date': DateTime.local().toFormat(constant.PARAM_DATE_FORMAT),
            }
        };

        return params;
    }

    const getAccountBalanceDetail = () => {

        setIsLoad(true);

        const params = getParams();

        getData(balanceSheetDetailPath.balanceDetailAsset, params).then(result => {
            setAccountBalanceDetail(result[0]);
            let assets = 0;
            if (result[0]) {
                result[0].map(r => {
                    assets += r.Amount;
                })

                setTotalAsset(assets);
            }

            getData(balanceSheetDetailPath.balanceDetailDebt, params).then(result => {
                setAccountBalanceDebtDetail(result[0]);
    
                if (result[0]) {
                    let debt = 0;
                    result[0].map(r => {
                        debt += r.Amount;
                    })
    
                    setTotalDebt(debt);
                    setBalance(assets - debt)
                }

                setIsLoad(false);
    
            });
        });
    }
    
    const onExport = e => {

        e.preventDefault();
        setIsLoad(true);
        const params = getParams();

        getFile(balanceSheetDetailPath.balanceDetailExcel, params).then(response => {
            downloadFile(response);
            setIsLoad(false);
        });

    }

    useEffect(getAccountBalanceDetail, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ balanceSheetDetailPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <form className="form-horizontal">
                            <div className="form-group row">
                                <div className="col-md-12">
                                <h3>Rekapitulasi</h3>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-4">
                                    <Label text="Asset (Rp)" />
                                    <InputNumeral
                                        value={ totalAsset }
                                        display={ true }
                                    />
                                </div>

                                <div className="col-md-4">
                                    <Label text="Kewajiban (Rp)" />
                                    <InputNumeral
                                        value={ totalDebt }
                                        display={ true }
                                    />
                                </div>

                                <div className="col-md-4">
                                    <Label text="Saldo (Rp)" />
                                    <InputNumeral
                                        value={ balance }
                                        display={ true }
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <button className='flex-fill btn btn-primary' onClick= { onExport } >Export Excel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {
                    isLoad &&
                    <LoaderType />
                }
                
                {
                    !isLoad && accountBalanceDetail.length > 0 &&
                    <>
                        <br />
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Group</th>
                                                <th>Desc</th>
                                                <th>Nilai (Rp)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                accountBalanceDetail.map((ss, index) => {
                                                    return(
                                                        <tr key={ index }>
                                                            <td>{ ss.Order }</td>
                                                            <td>{ ss.Category }</td>
                                                            <td>{ ss.Description }</td>
                                                            <td><TextNumber value={ ss.Amount } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Group</th>
                                                <th>Desc</th>
                                                <th>Nilai (Rp)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                accountBalanceDebtDetail.map((ss, index) => {
                                                    return(
                                                        <tr key={ index }>
                                                            <td>{ ss.Order }</td>
                                                            <td>{ ss.Category }</td>
                                                            <td>{ ss.Description }</td>
                                                            <td><TextNumber value={ ss.Amount } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
