import React from 'react';
import { Route } from 'react-router-dom';
import CashTrfList from './List';
import CashTrfAction from './Action';
import cashTrfPath from './config';

const CashTrfRoutes = () => {
    return (
        <React.Fragment>
            <Route path={cashTrfPath.basePath} exact component={CashTrfList} />
            <Route path={cashTrfPath.newPath} exact component={CashTrfAction} />
            <Route path={cashTrfPath.detailPath} exact component={CashTrfAction} />
        </React.Fragment>
    );
};

export default CashTrfRoutes;