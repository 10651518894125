import React from 'react';
import { Route } from 'react-router-dom';
import DesignationList from './List';
import DesignationAction from './Action';
import designationPath from './config';

const DesignationRoutes = () => {
    return (
        <React.Fragment>
            <Route path={designationPath.basePath} exact component={DesignationList} />
            <Route path={designationPath.newPath} exact component={DesignationAction} />
            <Route path={designationPath.detailPath} exact component={DesignationAction} />
        </React.Fragment>
    );
};

export default DesignationRoutes;
