import React from 'react';
import { Route } from 'react-router-dom';
import BalanceSheetDetailList from './List';
import balanceSheetDetailPath from './config';

const BalanceSheetDetailRoutes = () => {
    return (
        <React.Fragment>
            <Route path={ balanceSheetDetailPath.basePath } exact component={ BalanceSheetDetailList } />
        </React.Fragment>
    );
};

export default BalanceSheetDetailRoutes;