import React from 'react';
import {Switch, Route} from 'react-router-dom';
import SalesList from './List';
import SalesAction from './Action';
import salesPath from './config';

const SalesRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={salesPath.basePath} exact component={SalesList} />
                <Route path={salesPath.newPath} exact component={SalesAction} />
                <Route path={salesPath.detailPath} exact component={SalesAction} />
            </Switch>
        </React.Fragment>
    );
};

export default SalesRoutes;
