import apiPath from '../../../config/api';

const trxPath = {
    title: "Laporan Transaksi Akunting",
    basePath: "/akunting/laporan/rpt-transaksi",
    baseApi: apiPath.accountTransaction,
    custSaldoDetailExcel : apiPath.accountTransactionExcel,
    custFind: apiPath.masterCustFind
}

export default trxPath;
