import apiPath from '../../../config/api';

const PurchasePath = {
    title: "Toko Pupuk dan Pertanian - Modul Pembelian",
    basePath: "/pupuk-dan-pertanian/pembelian",
    newPath: "/pupuk-dan-pertanian/pembelian/baru",
    detailPath: "/pupuk-dan-pertanian/pembelian/opsi/:id",
    newUnit: "/master/satuan/baru",
    base: apiPath.retailPurchase,
    custFind: apiPath.masterCustFind,
    unitFilter: apiPath.masterUnitFilter,
    coaGns: apiPath.masterCoaGns,

    
    apArFilterCustId: apiPath.accountApArCustIdFilter,
    gnsNmFilter: apiPath.masterGnsNmFilter,
    gnsCodeFilter: apiPath.masterGnsCodeFilter,
    gns: apiPath.masterGns,
    gnsFind: apiPath.masterGnsFind,
    
    unit: apiPath.masterUnit
}

export default PurchasePath;
