import React, {useState, useEffect} from 'react';
import { DateTime } from 'luxon';
import { formatDate } from 'react-day-picker/moment';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import Label from '../../../components/label';
import Note from '../../../components/note';
import DayPicker from '../../../components/day-picker';
import ReportButton from '../../../components/report-button';
import TextNumber from '../../../components/text-number';
import { getData, getFile } from '../../../helper/request-response';
import { getDateApiParam, downloadFile } from '../../../helper/util';
import LoaderType from '../../../components/loader-type';
import constant from '../../../config/constant';
import cashPoPath from './config';

const List = () => {

    const [isLoad, setIsLoad] = useState(true);

    const [rows, ,] = useGlobalState('rows');

    const [poCash, setPoCash] = useState([]);

    const [currDate, setCurrDate] = useState(DateTime.local());

    const onCurrentDateChange = day => {

        setCurrDate(formatDate(day, constant.DEFAULT_DATE));

    };

    const onSubmit = e => {

        e.preventDefault();
    }

    const onReset = e => {

        e.preventDefault();

        setCurrDate(DateTime.local());
    }

    const onExport = e => {

        e.preventDefault();

        getFile(cashPoPath.apiExcel, { params: { 'current-date': getDateApiParam(currDate) }}).then(response => downloadFile(response));

    }

    useEffect(() => {

        setIsLoad(true);

        const params = {
            params: {
                'current-date': getDateApiParam(currDate),
                'rows': rows
            }
        }

        getData(cashPoPath.api, params).then(result => {

            if (!_.isEmpty(result)) setPoCash(result[0]);

            setIsLoad(false);
            
        });

     }, [currDate, rows]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{cashPoPath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } > 
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="startDate" text="Tanggal Dari" />
                                    <DayPicker id="startDate" name="startDate" onDayChange={ onCurrentDateChange } valueDate={ currDate } />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Data Transfer" entity="Transfer" />
                    </div>
                </div>
                {
                    isLoad && <LoaderType />
                }
                {
                    !isLoad && poCash.length > 0 &&
                    <>
                        <br />
                        <div className="col-lg-12 mb-4">
                            <div className="row">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Tanggal</th>
                                            <th>Transaksi</th>
                                            <th>No Faktur</th>
                                            <th>Tipe</th>
                                            <th>Pelanggan</th>
                                            <th>Deskripsi</th>
                                            <th>Kas Masuk</th>
                                            <th>Kas Keluar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            poCash.map((po, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <th scope="row">{ index + 1 }</th>
                                                        <td>{ DateTime.fromISO(po.trxDate).toFormat('dd-LLLL-yyyy') }</td>
                                                        <td>{ po.trxType }</td>
                                                        <td>{ po.invNo }</td>
                                                        <td>{ po.custType }</td>
                                                        <td>{ po.custName }</td>
                                                        <td>{ po.desc }</td>
                                                        <td><TextNumber value={ po.cashIn } /></td>
                                                        <td><TextNumber value={ po.cashOut } /></td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
