import apiPath from '../../../config/api';

const CashLoanPath = {
    title: "Pinjaman Tunai",
    basePath: "/akunting/pinjaman-tunai",
    newPath: "/akunting/pinjaman-tunai/baru",
    detailPath: "/akunting/pinjaman-tunai/opsi/:id",
    newCustomer: "/master/pelanggan/baru",
    baseApi: apiPath.accountCashLoan,
    custFind: apiPath.masterCustFind
}

export default CashLoanPath;
