import React from 'react';

import Bars from './bars';
import TextNumber from './text-number';

const CashBalance = ({ balance, label }) => {

    return (
        <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-2 flex-column flex-sm-row">
            <div className="left d-flex align-items-center">
                <div className="icon icon-lg shadow mr-3 text-gray"><i className="fas fa-wallet"></i></div>
                <div className="text">
                    <h6 className="mb-0 d-flex align-items-center"> <span>{ label }</span></h6>
                    <div className="mb-0 d-flex align-items-center text-blue">
                        { balance === 0 && <Bars /> }
                        { balance !== 0 && <strong><TextNumber value={ balance } /></strong> }
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default CashBalance;
