import apiPath from '../../../config/api';

const PayablePath = {
    title: "Biaya Lain-Lain",
    basePath: "/akunting/biaya-lain-lain",
    newPath: "/akunting/biaya-lain-lain/baru",
    detailPath: "/akunting/biaya-lain-lain/opsi/:id",
    baseApi: apiPath.accountMiscExp,
    coaMiscExpFilterApi: apiPath.masterCoaMiscExp
}

export default PayablePath;
