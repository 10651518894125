import React, {useState, useEffect, useCallback} from 'react';
import { DateTime } from 'luxon';
import { formatDate } from 'react-day-picker/moment';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import Label from '../../../components/label';
import Select from '../../../components/select';
import Note from '../../../components/note';
import DayPicker from '../../../components/day-picker';
import FilterButton from '../../../components/filter-button';
import TextNumber from '../../../components/text-number';
import { getData } from '../../../helper/request-response';
import { getDateApiParam } from '../../../helper/util';
import LoaderType from '../../../components/loader-type';
import constant from '../../../config/constant';
import cashTrfPath from './config';

const List = () => {

    const [isLoad, setIsLoad] = useState(true);

    const [rows, ,] = useGlobalState('rows');

    const [selectedCoa, setSelectedCoa] = useState(constant.DEFAULT_SELECTED);

    const [cashTrfs, setCashTrfs] = useState([]);

    const [cashTrfFilter, setCashTrfFilter] = useState({
        'invoiceNo': '',
        'startDate': DateTime.local(),
        'endDate': DateTime.local(),
        'coa': ''
    });

    const onStartDateChange = day => {

        setCashTrfFilter(filter => ({
            ...filter,
            startDate: formatDate(day, constant.DEFAULT_DATE),
            endDate: formatDate(day, constant.DEFAULT_DATE)
        }));
    
    };

    const onEndDateChange = day => setCashTrfFilter(filter => ({ ...filter, endDate: formatDate(day, constant.DEFAULT_DATE) }));

    const onSubmit = e => {

        e.preventDefault();

        const params = getFilterParams(cashTrfFilter);
        getCashTransfer(params);

    };

    const onReset = e => {
        e.preventDefault();

        setCashTrfFilter(filter => ({
            ...filter,
            invoiceNo: '',
            startDate: DateTime.local(),
            endDate: DateTime.local(),
            coa: ''
        }));

        setSelectedCoa(constant.DEFAULT_SELECTED);

        const params = getFilterParams({ 'startDate': DateTime.local(), 'endDate': DateTime.local() });
        getCashTransfer(params);
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setCashTrfFilter(filter => ({
            ...filter,
            [name]: value
        }));
        
    };

    const onSelectedCoa = opt => {

        setCashTrfFilter(filter => ({ 
            ...filter,
            coa: opt.accountNo,
            coaOutDesc: opt.description
        }));

        setSelectedCoa(code => ({
            ...code,
            label: opt.label
        }));
    }

    const getFilterParams = useCallback((filter) => {

        const params = { 
            params: { 
                'invoice-no': _.get(filter, 'invoiceNo'), 
                'date-from': _.get(filter, 'startDate'),
                'date-to': _.get(filter, 'endDate'), 
                'coa': _.get(filter, 'coa'),
                'rows': rows
            } 
        };

        return params;

    }, [rows]);

    const getCashTransfer = (params) => {

        setIsLoad(true);

        getData(cashTrfPath.baseApi, params).then(result => {

            setCashTrfs(result);
            setIsLoad(false);
            
        });

    }

    useEffect(() => {

        const params = getFilterParams({ 'startDate': getDateApiParam(DateTime.local()), 'endDate': getDateApiParam(DateTime.local()) });
        getCashTransfer(params);

     }, [getFilterParams]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{cashTrfPath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } > 
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="inv-no" text="No Faktur" />
                                    <input 
                                        id="inv-no" 
                                        type="text" 
                                        className="form-control"
                                        name="invoiceNo"
                                        value={ cashTrfFilter.invoiceNo }
                                        onChange={ e => onInputChange(e) }
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="coa" text="COA" />
                                    <Select
                                        id="coa"
                                        paramFilter="name"
                                        entityId='101,102'
                                        api={ cashTrfPath.cashCoa }
                                        onChange={ onSelectedCoa }
                                        value={ selectedCoa } 
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="startDate" text="Tanggal Dari" />
                                    <DayPicker id="startDate" name="startDate" onDayChange={ onStartDateChange } valueDate={ cashTrfFilter.startDate } />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="endDate" text="Tanggal Ke" />
                                    <DayPicker id="endDate" name="endDate" onDayChange={ onEndDateChange } valueDate={ cashTrfFilter.endDate } />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <FilterButton to={ cashTrfPath.newPath } newLabel="Tambah Data Transfer" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Data Transfer" entity="Transfer" />
                    </div>
                </div>
                <br />
                {
                    isLoad && <LoaderType />
                }

                {
                    !isLoad && cashTrfs.length > 0 &&
                    <div className="col-lg-12 mb-4">
                        <div className="row">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>No Faktur</th>
                                        <th>Tanggal</th>
                                        <th>COA</th>
                                        <th>Deskripsi</th>
                                        <th>Kas Masuk</th>
                                        <th>Kas Keluar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cashTrfs.map((cashTrf, index) => {
                                            return(
                                                <tr key={index}>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ cashTrf.invoiceNo }</td>
                                                    <td>{ DateTime.fromISO(cashTrf.trxDate).toFormat('dd-LLLL-yyyy') }</td>
                                                    <td>{ cashTrf.coa }</td>
                                                    <td>{ cashTrf.desc }</td>
                                                    <td><TextNumber value={ cashTrf.cashIn } /></td>
                                                    <td><TextNumber value={ cashTrf.cashOut } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
