import apiPath from '../../../config/api';

const RetrPath = {
    title: "TBS - Laporan Retribusi",
    basePath: "/sawit/laporan/retribusi",
    base: apiPath.palmOilRetribution,
    palmOilRetrSum: apiPath.palmOilRetributionSum,
    palmOilRetrExcel: apiPath.palmOilRetributionExcel,
    palmOilRetrFind: apiPath.palmOilRetributionFind
}

export default RetrPath;
