import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import _ from 'lodash';

import { postData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import cashoutPath from './config';

const DisburseCheckbox = ({ cashout }) => {

    const [disburse, setDisburse] = useState({});

    const [val, setVal] = useState("false");

    useEffect(() => {

        setDisburse(cashout);

        setVal(cashout.status);

    }, [cashout]);

    const onStatusChanged = e => {

        const postCashoutDisburse = async () => {

            const result = await postData(cashoutPath.base, {
                invoiceNo: disburse.invoiceNo,
                cashoutDate: DateTime.local(),
                customerId: disburse.customerId,
                apArDetailId: disburse.apardetailId
            });
            
            if (!_.isEmpty(result)) {

                onSuccess(`Status pembayaran untuk ${disburse.customerName} berhasil di tambahkan`);
                
                setDisburse(disb => ({
                    ...disb,
                    id: result.id
                }));

            }
        };

        const checked = val === 1 ? 0 : 1;
        
        postCashoutDisburse();

        setVal(checked);
    }

    return(
        <span className="custom-control custom-checkbox text-center">
            <input 
                id={`cashout-${cashout.invoiceNo}`}
                type="checkbox"
                className="custom-control-input"
                checked={ val }
                value={ val }
                onChange={ e => onStatusChanged(e) } 
                disabled={ disburse.id ? true : false }/>   
                <label htmlFor={`cashout-${cashout.invoiceNo}`} className="custom-control-label">&nbsp;</label>
        </span>
    );
}

export default DisburseCheckbox;