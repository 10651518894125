import React from 'react';
import { Switch, Route } from 'react-router-dom';
import MonitoringList from './List';
import monitoringPath from './config';

const MonitoringRoutes = () => {
    return (
        <Switch>
            <Route path={monitoringPath.basePath} exact component={MonitoringList} />
        </Switch>
    );
};

export default MonitoringRoutes;