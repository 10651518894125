import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TaxList from './List';
import TaxPath from './config';

const TaxRoutes = () => {
    return (
        <Switch>
            <Route path={TaxPath.basePath} exact component={TaxList} />
        </Switch>
    );
};

export default TaxRoutes;