import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import SelectCreate from '../../../components/select-create';
import ActionButton from '../../../components/action-button';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import unitPath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();
    
    const [selectedUnit, setSelectedUnit] = useState({ value: '', label: ''});

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [titleInfo, setTitleInfo] = useState("");

    const [unit, setUnit] = useState({
        id: 0,
        code: "",
        name: ""
    });

    const onUnitChange = opt => {

        if (opt.id) {

            setUnit(unit => ({
                ...unit,
                id: opt.id,
                code: opt.code,
                name: opt.name
            }));

            setSelectedUnit(unit => ({
                ...unit,
                value: opt.id,
                label: opt.code
            }));

        } else {

            setUnit(unit => ({
                ...unit,
                code: opt.label
            }));

            setSelectedUnit(unit => ({
                ...unit,
                value: 0,
                label: opt.label
            }));

        }
    }

    /**
     * Reset unit.
     */
    const onReset = e => {

        e.preventDefault();

        setUnit(unit => ({ 
            ...unit,
            code: "",
            name: ""
        }));

    }

    /**
     * Event triggered when input text changed.
     * @param {Object} e - Input text object
     */
    const onInputChange = e => {

        const { name, value } = e.target;

        setUnit(unit => ({ 
            ...unit,
            [name]: value
        }));

    };

    /**
     * Event triggered when the form submitted.
     * @param {Object} e - Form object.
     */
    const onSubmit = (e) => {

        e.preventDefault();

        const postUnit = async () => {

            const result = await postData(unitPath.baseApi, unit);

            if (Object.keys(result).length) {

                onSuccess(`Unit ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putUnit = async () => {

            const result = await putData(`${unitPath.baseApi}/${unit.id}`, unit);

            if (Object.keys(result).length) {
                
                onSuccess(`Unit ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        }

        if (unit.id === 0 && unit.code && unit.name) postUnit();
        else putUnit();

    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state) {

                setUnit(state);

                setSelectedUnit(unit => ({
                    ...unit,
                    value: state.id,
                    label: state.code
                }));

                setSelectedUnit(unit => ({
                    ...unit,
                    value: state.id,
                    label: state.code
                }));
                
                setTitleInfo(`Detail: ${state.code} - ${state.name}`);

                setIsResetable(false);

            } else {

                setTitleInfo("Tambah Unit Baru");
                
            }
        }

        init();
        

    }, []);

    useEffect(() => {

        if (unit.code && unit.name) setIsDisabledSave(false);

    }, [unit.code, unit.name]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ unitPath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            
                            <div className="form-group row">
                                <Label htmlFor="unit-code" isCol={ true } text="Kode Satuan" isMandatory={ true } value={ unit.code } />
                                <div className="col-md-9">
                                    <SelectCreate 
                                        id="name"
                                        api={ unitPath.unitFilter }
                                        value={ selectedUnit }
                                        onChange={ onUnitChange }
                                    />
                                    {/* <AutoComplete 
                                        filterApi={ unitPath.unitFilter }
                                        onSelectChange={ onUnitCodeChange } 
                                        filter={ {label: unit.code} }
                                        isCreateable={ true } 
                                        noResultText={ constant.NO_RESULT_TEXT_ADD } /> */}
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="unit-code" isCol={ true } text="Nama Satuan" isMandatory={ true } value={ unit.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="unit-name"
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        value={unit.name}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset }/>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                
            </div>
        </React.Fragment>
    );
};

export default Action;
