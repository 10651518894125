import React, { useState, useEffect, useRef } from 'react';
import {useLocation, useHistory} from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import {postData, putData} from '../../../helper/request-response';
import {onSuccess} from '../../../helper/toaster';
import vehiclePath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [titleInfo, setTitleInfo] = useState("");

    const [vehicle, setVehicle] = useState({
        id: 0,
        code: "",
        vehicleNo: "",
        isActive: 1
    });

    const onReset = e => {

        setVehicle(vhc => ({ 
            ...vhc,
            code: "",
            vehicleNo: "",
            isActive: 1
        }));
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setVehicle(vhc => ({ 
            ...vhc,
            [name]: value
        }));
    };

    const onActiveChange = e => {
        
        setVehicle(vhc => ({ 
            ...vhc,
            isActive: vhc.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = (e) => {
        
        e.preventDefault();

        const postVehicle = async () => {

            const result = await postData(vehiclePath.baseApi, vehicle);

            if (Object.keys(result).length) {

                onSuccess(`Kendaraan ${result.code} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putVehicle = async () => {

            const result = await putData(`${vehiclePath.baseApi}/${vehicle.id}`, vehicle);

            if (Object.keys(result).length) {
                
                onSuccess(`Kendaraan ${result.code} berhasil di perbaharui`);

                history.goBack();

            }
        };

        if (vehicle.id === 0 && vehicle.code && vehicle.vehicleNo) postVehicle();
        else putVehicle();

    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;
            
            if (state) {
                
                setVehicle(state);

                setTitleInfo(`Detail: ${state.name}`);

                setIsResetable(false);

            } else {
                
                setTitleInfo("Tambah Kendaraan Baru");

            }
        };

        init();

    }, []);

    useEffect(() => {

        if (vehicle.code && vehicle.vehicleNo) setIsDisabledSave(false);

    }, [vehicle.code, vehicle.vehicleNo]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{vehiclePath.title} | {titleInfo}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">

                        <form className="form-horizontal" onSubmit={e => onSubmit(e)}>
                            <div className="form-group row">
                                <Label htmlFor="vehicle-code" isCol={ true } text="Kode Kendaraan" isMandatory={ true } value={ vehicle.code } />
                                <div className="col-md-9">
                                    <input 
                                        id="vehicle-code"
                                        type="text" 
                                        className="form-control" 
                                        name="code"
                                        value={vehicle.code}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="vehicle-no" isCol={ true } text="Nomor Kendaraan" isMandatory={ true } value={ vehicle.vehicleNo } />
                                <div className="col-md-9">
                                    <input 
                                        id="vehicle-no" 
                                        type="text"
                                        className="form-control"
                                        name="vehicleNo"
                                        value={vehicle.vehicleNo}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="vehicle-is-active"
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            name="isActive"
                                            value={ vehicle.isActive }
                                            checked={ vehicle.isActive }
                                            onChange={ e => onActiveChange(e) } />   
                                        <label htmlFor="vehicle-is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset }/>
                                </div>
                            </div>
                        </form>

                    </div>

                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
