import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useHistory} from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import LinkButton from '../../../components/link-button';
import { getData, postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import employeePath from './config';
import AddressList from '../address/List';
import addressPath from '../address/config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isAddWithPage, setIsAddWithPage] = useState(false);

    const [isResetable, setIsResetable] = useState(true);

    const [titleInfo, setTitleInfo] = useState("");

    const [designations, setDesignation] = useState([]);

    const [employee, setEmployee] = useState({
        id: 0,
        name: "",
        isActive: 1,
        phoneNo: "",
        icNumber: "",
        masterDesignationId: 0,
        addresses: []
    });

    const newAddressPath = {
        
        'pathname': addressPath.empNewAddrPath.replace(":parentId", employee.id),
        'state': {
            "address": {},
            "customerId": null,
            "employeeId": employee.id,
            "name": employee.name
        }
    };

    const onReset = e => {

        e.preventDefault();

        setEmployee(empl => ({ 
            ...empl,
            name: "",
            isActive: 1,
            phoneNo: "",
            icNumber: "",
            masterDesignationId: 0
        }));
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setEmployee(empl => ({ 
            ...empl,
            [name]: value
        }));
    };

    const onActiveChange = e => {
        
        setEmployee(empl => ({ 
            ...empl,
            isActive: employee.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postEmployee = async () => {

            const result = await postData(employeePath.baseApi, employee);

            if (Object.keys(result).length) {

                onSuccess(`Karyawan ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putEmployee = async () => {

            const result = await putData(`${employeePath.baseApi}/${employee.id}`, employee);

            if (Object.keys(result).length) {
                
                onSuccess(`Karyawan ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        }

        if (employee.id === 0 && employee.name) postEmployee();
        else putEmployee();

    }

    const getDesignations = async() => {

        const result = await getData(employeePath.baseApiDesignation);
        
        setDesignation(result);
        
    }

    useEffect(() => {

        getDesignations();

        const setEmployeeState = () => {

            const state = refData.current.state;

            if (state) {
                
                setEmployee(state);

                setTitleInfo(`Detail: ${state.name}`);

                setIsAddWithPage(true);

                setIsResetable(false);

            } else {

                setTitleInfo("Tambah Karyawan Baru");

            }

        }

        setEmployeeState();

    }, []);

    useEffect(() => {

        if (employee.name) setIsDisabledSave(false);

    }, [employee.name, employee.masterDesignationId]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{employeePath.title} | {titleInfo}</h3>
            </div>
            <div className="card-body">

                <div className="row">

                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="employee-name" isCol={ true } text ="Nama" isMandatory={ true } value={ employee.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="employee-name"
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        value={employee.name}
                                        onChange={ e => onInputChange(e) }  />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="designation" isCol={ true } text="Jabatan" isMandatory={ true } value={ employee.masterDesignationId } />
                                <div className="col-md-9">
                                    <select id="designation" className="form-control" name="masterDesignationId" onChange={ e => onInputChange(e) } value={ employee.masterDesignationId }>
                                        <option value="">Pilih</option>
                                        {designations.map(({id, name}) => {return(<option key={id} value={id}>{name}</option>)})}
                                    </select>
                                    <span><LinkButton to={ employeePath.newDesignation } className="stretched-link" label="Tambah Jabatan Baru" /></span>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="employee-phoneNo" isCol={ true } text="No Telepon" />
                                <div className="col-md-9">
                                    <input 
                                        id="employee-phoneNo"
                                        type="text" 
                                        className="form-control" 
                                        name="phoneNo"
                                        value={employee.phoneNo}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="employee-icNumber" isCol={ true } text="KTP" />
                                <div className="col-md-9">
                                    <input 
                                        id="employee-icNumber"
                                        type="text" 
                                        className="form-control" 
                                        name="icNumber"
                                        value={employee.icNumber}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="employee-is-active"
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            name="isActive"
                                            value={ employee.isActive }
                                            checked={ employee.isActive }
                                            onChange={ e => onActiveChange(e) } />   
                                        <label htmlFor="employee-is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave }
                                        isAddWithPage={ isAddWithPage }
                                        isReset={ isResetable }
                                        onReset={ onReset }
                                        newPagePath ={ newAddressPath }
                                        addLabel="Tambah Alamat Baru" />
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isAction={ true } />
                        {
                            !isResetable &&
                            <p>
                                Klik tombol <span><strong>[Tambah Alamat Baru]</strong></span> untuk manambahkan <strong>Alamat</strong> baru.
                            </p>
                        }
                    </div>
                </div>

                <br />

                {
                    data.state &&
                    <AddressList employeeId={ data.state.id } name={ data.state.name } />
                }

            </div>
        </React.Fragment>
    );
};

export default Action;
