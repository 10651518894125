import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import Select from '../../../components/select';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import bankAccPath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();
    
    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [selectedBank, setSelectedBank] = useState({ value: '', label: ''});

    const bankAccountEntity = {
        'id': 0,
        'bankId': 0,
        'name': '',
        'accountNo': ''
    }

    const [bankAccount, setBankAccount] = useState(bankAccountEntity);

    const onReset = e => {

        e.preventDefault();

        setBankAccount(bankAccountEntity);
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setBankAccount(coa => ({ 
            ...coa,
            [name]: value
        }));
    };

    const onSelectedBank = opt => {
        
        setSelectedBank(code => ({
            ...code,
            value: opt.id,
            label: opt.label
        }));

        setBankAccount(acc => ({
            ...acc,
            bankId: opt.id
        }))

    }

    const onSubmit = (e) => {

        e.preventDefault();

        const postBankAccount = async () => {

            const result = await postData(bankAccPath.api, bankAccount);

            if (!_.isEmpty(result)) {

                onSuccess(`COA ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putBankAccount = async () => {

            const result = await putData(`${bankAccPath.api}/${bankAccount.id}`, bankAccount);

            if (!_.isEmpty(result)) {
                
                onSuccess(`Akun Bank ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        };

        if (bankAccount.id === 0 && bankAccount.name && bankAccount.accountNo) postBankAccount();
        else putBankAccount();
        
    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state) {
    
                setBankAccount(state);

                setSelectedBank(code => ({
                    ...code,
                    value: state.bankId,
                    label: state.bankName
                }));
                
                setTitleInfo(`Detail: ${state.name}`);

                setIsResetable(false);
    
            } else {
    
                setTitleInfo("Tambah Bank");
                
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (bankAccount.name) setIsDisabledSave(false);

    }, [bankAccount.name, bankAccount.bankId, bankAccount.accountNo]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ bankAccPath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>

                            <div className="form-group row">
                                <Label htmlFor="bank-name" text="Nama Bank" isCol={ true } isMandatory={ true } value={ bankAccount.bankId } />
                                <div className="col-md-9">
                                    <Select
                                        id="bank-name"
                                        api={ bankAccPath.apiBank }
                                        value={ selectedBank }
                                        onChange={ onSelectedBank }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="bank-name" isCol={ true } text="Nama" isMandatory={ true } value={ bankAccount.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="bank-name"
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        value={bankAccount.name}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="account-no" isCol={ true } text="No Rekening" isMandatory={ true } value={ bankAccount.accountNo } />
                                <div className="col-md-9">
                                    <input 
                                        id="account-no"
                                        type="text" 
                                        className="form-control" 
                                        name="accountNo"
                                        value={bankAccount.accountNo}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
