import apiPath from '../../../config/api';

const ApArHeaderPath = {
    title: "AP AR Header",
    basePath: "/akunting/ap-ar-header",
    newPath: "/akunting/ap-ar-header/baru",
    detailPath: "/akunting/ap-ar-header/opsi/:id",
    baseApi: apiPath.accountApAr,
    baseApiCustomer: apiPath.masterCustomer,
    customerNameFilterApi: apiPath.masterCustomerAllNameFilter
}

export default ApArHeaderPath;
