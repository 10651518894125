import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import bankPath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();
    
    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [bank, setBank] = useState({
        id: 0,
        name: ''
    });

    const onReset = e => {

        e.preventDefault();

        setBank(bank => ({ 
            ...bank,
            id: 0,
            name: ''
        }));
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setBank(coa => ({ 
            ...coa,
            [name]: value
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postBank = async () => {

            const result = await postData(bankPath.api, bank);

            if (!_.isEmpty(result)) {

                onSuccess(`Bank ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putBank = async () => {

            const result = await putData(`${bankPath.api}/${bank.id}`, bank);

            if (!_.isEmpty(result)) {
                
                onSuccess(`Bank ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        };

        if (bank.id === 0 && bank.name) postBank();
        else putBank();
        
    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state) {
    
                setBank(state);
                
                setTitleInfo(`Detail: ${state.name}`);

                setIsResetable(false);
    
            } else {
    
                setTitleInfo("Tambah Bank");
                
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (bank.name) setIsDisabledSave(false);

    }, [bank.name]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ bankPath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="bank-name" isCol={ true } text="Nama" isMandatory={ true } value={ bank.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="bank-name"
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        value={bank.name}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
