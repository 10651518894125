import React, { useState } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';

import Label from '../../../components/label';
import TextNumber from '../../../components/text-number';
import DayPicker from '../../../components/day-picker';
import ReportButton from '../../../components/report-button';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import monitoringPath from './config';

const List = () => {

    const [startDate, setStartDate] = useState(DateTime.local());

    const [endDate, setEndDate] = useState(DateTime.local());

    const [monitoring, setMonitoring] = useState([]);

    const [monitoringFilter, setFilterMonitoring] = useState({
        startDate:"",
        endDate:""
    });

    const onStartDayChange = day => setStartDate(formatDate(day, constant.DEFAULT_DATE));

    const onEndDayChange = day => setEndDate(formatDate(day, constant.DEFAULT_DATE));

    const getExcel = async(param, url) => await getFile(url, param);

    const onExport = e => {

        e.preventDefault();

        const params = {
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                endDate: DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT)
            }
        };

        getExcel(params, monitoringPath.monitoringExcel).then(response => {

            downloadFile(response);

        });

    }

    const onReset = e => {

        e.preventDefault();

        setFilterMonitoring(trx => ({ 
            ...monitoringFilter,
            startDate:"",
            endDate:""
        }));

        setStartDate(DateTime.local());
        setEndDate(DateTime.local());

        getMonitoring();

    }

    const getMonitoring = async(params) => {

        const result = await getData(monitoringPath.baseApi, params);

        setMonitoring(result[0]);

    }

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                endDate: DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT)
            }
        };

        getMonitoring(params);

    };

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ monitoringPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="startDate" text="Tanggal Dari" />
                                    <DayPicker id="startDate" name="startDate" onDayChange={onStartDayChange} valueDate={ startDate } />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="endDate" text="Tanggal Ke" />
                                    <DayPicker id="endDate" name="endDate" onDayChange={onEndDayChange} valueDate={ endDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                

                <br />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nama Pelanggan</th>
                                        <th>Total Cicilan</th>
                                        <th>Total Pinjaman</th>
                                        <th>Total Tarik Tabungan</th>
                                        <th>Total Setor Tabungan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        monitoring.map((mon, index) => {
                                            return(
                                                <tr key={ index }>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ mon.name }</td>
                                                    <td><TextNumber value={ mon.receivables } /></td>
                                                    <td><TextNumber value={ mon.loan } /></td>
                                                    <td><TextNumber value={ mon.debit } /></td>
                                                    <td><TextNumber value={ mon.credit } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
