import apiPath from '../../../config/api';

const MonitoringPath = {
    title: "Monitoring Hutang dan Tabungan",
    basePath: "/akunting/laporan/monitoring-hutang-tabungan",
    baseApi: apiPath.accountMonitoring,
    monitoringExcel : apiPath.accountMonitoringExcel
}

export default MonitoringPath;
