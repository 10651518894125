import React, { useState, useEffect, useRef } from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import { useReactToPrint } from 'react-to-print';

import Label from '../../../components/label';
import Note from '../../../components/note';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import InputNumeral from '../../../components/input-numeral';
import ActionButton from '../../../components/action-button';
import LinkButton from '../../../components/link-button';
import { postData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import { getBankBalance } from '../../../helper/cash-balance';
import constant from '../../../config/constant';

import ComponentToPrint from './print';

import bankTrxPath from './config';

const Action = () => {

    const [, , setBankBalance] = useGlobalState('bankBalance');

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    
    const data = useLocation();

    const refData = useRef(data);
    
    const history = useHistory();
    
    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [isPrint, setIsPrint] = useState(true);

    const [selectedCustCode, setSelectedCustCode] = useState(constant.DEFAULT_SELECTED);

    const [selectedCustName, setSelectedCustName] = useState(constant.DEFAULT_SELECTED);

    const [selectedTrxType, setSelectedTrxType] = useState(constant.DEFAULT_SELECTED);

    const trxBankEntity = {
        id: 0,
        invoiceNo: `BJ${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
        trxDate: DateTime.local(),
        trxTypeId: 0,
        trxTypeName: '',
        apArId: 0,
        coa:'',
        coaName:'',
        custId: 0,
        custCode: '',
        custName: '',
        description: '',
        amount: 0,
        extFee: 0,
        extFeeDesc: 'ADMIN BRILINK NON TUNAI',
        extFeeCoa: '1021010',
        intFee: 0,
        intFeeDesc: 'LAYANAN BRILINK TUNAI',
        intFeeCoa: '1021020',
        isCashlessService: 0,
        subTotal: 0,
        payment: 0,
        outstanding: 0,
        balance: 0,
        isCommit: true
    }

    const [trxBank, setTrxBank] = useState(trxBankEntity);

    const onReset = e => {

        e.preventDefault();
        setTrxBank(trxBankEntity);
    }

    /**
     * Calculate Sub total of transaction.
     * @param {Number} amount - Transaction amount.
     * @param {Number} extFee - Admin Fee.
     * @param {Number} intFee - Aulia Tani Fee.
     */
    const calcSubTotal = (amount, extFee, intFee) => parseFloat(amount) + parseFloat(extFee) + parseFloat(intFee);

    /**
     * Calculate outstanding. Return 0 if the result less than 0.
     * @param {Number} subTotal - Sub total of transaction.
     * @param {Number} payment - Payment.
     */
    const calcOutstanding = (subTotal, payment) =>  {
        
        const outstanding = parseFloat(subTotal) - parseFloat(payment);

        return outstanding < 0 ? 0 : outstanding;
    };

    /**
     * Calculate balance. Return 0 if the result less than 0.
     * @param {Number} subTotal - Sub total of transaction.
     * @param {Number} payment - Payment.
     */
    const calcBalance = (subTotal, payment) => {

        const bal = parseFloat(payment) - parseFloat(subTotal);

        return bal < 0 ? 0 : bal;
    }

    /**
     * Input change event.
     * @param {Object} e - Text field object.
     */
    const onInputChange = e => {

        const { name, value } = e.target;

        setTrxBank(trx => ({
            ...trx,
            [name]: value,
            subTotal: calcSubTotal(trx.amount, trx.extFee, trx.intFee),
            outstanding: calcOutstanding(calcSubTotal(trx.amount, trx.extFee, trx.intFee), trx.payment),
            balance: calcBalance(calcSubTotal(trx.amount, trx.extFee, trx.intFee), trx.payment)
        }));
        
    };

    /**
     * Amount change event.
     * @param {Object} values - NumberFormat object.
     */
    const onAmountChange = values => {

        const { value } = values;

        const val = parseFloat(value);

        setTrxBank(trx => ({
            ...trx,
            amount: val,
            subTotal: calcSubTotal(val, trx.extFee, trx.intFee),
            payment: calcSubTotal(val, trx.extFee, trx.intFee),
            outstanding: calcOutstanding(calcSubTotal(val, trx.extFee, trx.intFee), trx.payment),
            balance: calcBalance(calcSubTotal(val, trx.extFee, trx.intFee), trx.payment)
        }));

    }

    /**
     * Internal fee change event.
     * @param {Object} values - NumberFormat object;
     */
    const onIntFeeChange = values => {

        const { value } = values;

        const val = parseFloat(value);

        setTrxBank(trx => ({
            ...trx,
            intFee: val,
            subTotal: calcSubTotal(trx.amount, trx.extFee, val),
            outstanding: calcOutstanding(calcSubTotal(trx.amount, trx.extFee, val), trx.payment),
            balance: calcBalance(calcSubTotal(trx.amount, trx.extFee, val), trx.payment)
        }));
    }

    /**
     * External fee change event.
     * @param {Object} values - NumberFormat object;
     */
    const onExtFeeChange = values => {

        const { value } = values;

        const val = parseFloat(value);

        setTrxBank(trx => ({
            ...trx,
            extFee: val,
            subTotal: calcSubTotal(trx.amount, val, trx.intFee),
            payment: calcSubTotal(trx.amount, val, trx.intFee),
            outstanding: calcOutstanding(calcSubTotal(trx.amount, val, trx.intFee), trx.payment),
            balance: calcBalance(calcSubTotal(trx.amount, val, trx.intFee), trx.payment)
        }));
    }

    const onCashlessService = e => {

        const { value } = e.target;

        setTrxBank(trx => ({ 
            ...trx,
            isCashlessService: parseInt(value) === 0 ? 1 : 0,
            intFeeCoa: parseInt(value) === 0 ? '1021021': '1021020',
            intFeeDesc: parseInt(value) === 0 ? 'LAYANAN BRILINK NON TUNAI' : 'LAYANAN BRILINK TUNAI'
        }));

    }

    /**
     * Payment change event.
     * @param {Object} values - NumberFormat object.
     */
    const onPaymentChange = values => {

        const { value } = values;

        const val = parseFloat(value);

        setTrxBank(trx => ({
            ...trx,
            payment: val,
            subTotal: calcSubTotal(trx.amount, trx.extFee, trx.intFee),
            outstanding: val > 0 ? calcOutstanding(calcSubTotal(trx.amount, trx.extFee, trx.intFee), val) : calcOutstanding(calcSubTotal(trx.amount, trx.extFee, trx.intFee), 0),
            balance: calcBalance(calcSubTotal(trx.amount, trx.extFee, trx.intFee), val)
        }));
    }

    /**
     * Transaction type change event.
     * @param {Object} opt - Option object.
     */
    const onChangeTrxType = opt => {

        if (opt.id) {

            setTrxBank(trx => ({ 
                ...trx,
                trxTypeId: opt.id,
                trxTypeName: opt.label,
                extFee: opt.extFee === null ? 0 : opt.extFee,
                intFee: opt.intFee === null ? 0 : opt.intFee,
                coa: opt.account,
                coaName: opt.description
            }));

            setSelectedTrxType(trx => ({
                ...trx,
                value: opt.id,
                label: opt.label
            }));
        }
    }

    /**
     * Customer change event.
     * @param {Object} opt - Option object.
     */
    const onChangeCustomer = opt => {

        if (opt.id) {

            setTrxBank(trx => ({
                ...trx,
                apArId: opt.apArId,
                custId: opt.id,
                custCode: opt.code,
                custName: opt.name
            }));

            setSelectedCustCode(code => ({
                ...code,
                value: opt.id,
                label: opt.code
            }));

            setSelectedCustName(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    /**
     * Transaction date change event.
     * @param {Object} day - Date object.
     */
    const onDayChange = day => {

        setTrxBank({ 
            ...trxBank,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    }

    /**
     * Form submit event.
     * @param {Object} e - The form.
     */
    const onSubmit = (e) => {

        e.preventDefault();

        /**
         * Post new BRI Link transaction
         */
        const postBankTrx = async () => {

            setIsDisabledSave(true);

            setBankBalance(0);

            const result = await postData(bankTrxPath.baseApi, trxBank);

            if (!_.isEmpty(result)) {

                onSuccess(`Transaksi ${result.invoice_no} berhasil di tambahkan`);

                history.goBack();

                getBankBalance();

            }
        };

        if (trxBank.id === 0 && trxBank.invoiceNo && trxBank.trxDate && trxBank.trxTypeId && trxBank.custId && trxBank.amount && trxBank.payment) postBankTrx();

    }

    /**
     * Run only once.
     */
    useEffect(() => {

        const setBankTrxState = () => {

            const state = refData.current.state;

            if (state) {

                setTrxBank(state)

                setSelectedTrxType(type => ({
                    ...type,
                    value: state.trxTypeId,
                    label: state.trxTypeName
                }));

                setSelectedCustCode(code => ({
                    ...code,
                    value: state.custId,
                    label: state.custCode
                }));

                setSelectedCustName(code => ({
                    ...code,
                    value: state.custId,
                    label: state.custName
                }));

                setTitleInfo(`Detail: ${state.invoiceNo}`);

                setIsResetable(false);

                setIsPrint(false);

            } else {

                setTitleInfo("Tambah Data Baru");

            }
        }

        setBankTrxState();

    }, []);

    /**
     * Run every mandatory property changed.
     */
    useEffect(() => {

        if (trxBank.invoiceNo && trxBank.trxDate && trxBank.trxTypeId && trxBank.custId && trxBank.amount && trxBank.payment) setIsDisabledSave(false);

    }, [trxBank.invoiceNo, trxBank.trxDate, trxBank.trxTypeId, trxBank.custId, trxBank.amount, trxBank.payment]);

    return (
        

        <React.Fragment>
            { isPrint &&
            <div style={{display:"none"}}>
                <ComponentToPrint 
                    invoiceNo={trxBank.invoiceNo}
                    trxDate={`${formatDate(trxBank.trxDate, 'LL', constant.LOCALE)}`}
                    custCode={trxBank.custCode}
                    custName={trxBank.custName}
                    trxDesc={trxBank.trxTypeName + trxBank.description}
                    qty={1}
                    amount={trxBank.amount}
                    subTotal={trxBank.subTotal}
                    payAmount={trxBank.payment}
                    outstanding={trxBank.outstanding}
                    balance={trxBank.balance}
                    intFee={trxBank.intFee}
                    extFee={trxBank.extFee}
                    
                    ref={componentRef} />

                    <button onClick={handlePrint}>Print this out!</button>
            </div>
}

            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{bankTrxPath.title} | {titleInfo}</h3>
            </div>

            <div className="card-body">
                <div className="row">
                    
                    <div className="col-md-6 border-right">

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="invoiceNo" text="No Faktur" isMandatory={ true } value={ trxBank.invoiceNo } />
                                <input 
                                    id="invoiceNo"
                                    type="text" 
                                    className="form-control"
                                    value={ trxBank.invoiceNo }
                                    readOnly 
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="trx-date" text="Tanggal" isMandatory={ true } value={ trxBank.trxDate } />
                                <DayPicker id="trx-date" name="trxDate" valueDate={ trxBank.trxDate } onDayChange={ onDayChange } />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="trx-type" text="Jenis Transaksi" isMandatory={ true } value={ trxBank.trxTypeId } />
                                <Select
                                    id="trx-type"
                                    api={ bankTrxPath.brilinkTypeNameFilterApi }
                                    onChange={ onChangeTrxType }
                                    value={ selectedTrxType } 
                                />
                                <span><LinkButton to={ bankTrxPath.newType } className="stretched-link" label="Tambah Jenis Transaksi Baru" /></span>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="customer-code" text="Kode Pelanggan" isMandatory={ true } value={ trxBank.custCode } />
                                <Select
                                    id="customer-code"
                                    paramFilter="code"
                                    api={ bankTrxPath.custFind }
                                    entityId="1,2,3,4"
                                    onChange={ onChangeCustomer }
                                    value={ selectedCustCode } 
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="customer-name" text="Nama Pelanggan" isMandatory={ true } value={ trxBank.custName } />
                                <Select
                                    id="customer-name"
                                    paramFilter="name"
                                    api={ bankTrxPath.custFind }
                                    entityId="1,2,3,4"
                                    onChange={ onChangeCustomer }
                                    value={ selectedCustName }
                                />
                                <span><LinkButton to={ bankTrxPath.newCustomer } className="stretched-link" label="Tambah Pelanggan Baru" /></span>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="desc" text="Deskripsi" />
                                <input 
                                    id="bankTrx-description"
                                    type="text" 
                                    className="form-control" 
                                    name="description"
                                    value={ trxBank.description }
                                    onChange={ e => onInputChange(e) } />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">

                        <form onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="amount" text="Jumlah" isMandatory={ true } value={ trxBank.amount } />
                                    <InputNumeral 
                                        id="amount"
                                        value={ trxBank.amount }
                                        onValueChange={ onAmountChange }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="ext-fee" text="Biaya Admin" />
                                    <InputNumeral 
                                        id="extFee"
                                        value={ trxBank.extFee }
                                        isDisabled={ true }
                                        onValueChange={ onExtFeeChange }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <Label htmlFor="int-fee" text="Biaya Layanan" />
                                        <InputNumeral 
                                            id="intFee"
                                            value={ trxBank.intFee }
                                            onValueChange={ onIntFeeChange }
                                        />
                                    </div>
                                    <div>
                                        <div className="custom-control custom-checkbox">
                                            <input 
                                                id="is-cashless-service"
                                                type="checkbox" 
                                                className="custom-control-input" 
                                                name="isCashlessService"
                                                value={ trxBank.isCashlessService }
                                                checked={ trxBank.isCashlessService }
                                                onChange={ e => onCashlessService(e) } />   
                                            <label htmlFor="is-cashless-service" className="custom-control-label">Biaya Layanan Non Tunai</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="subtotal" text={ constant.SUB_TOTAL } />
                                    <InputNumeral 
                                        id="subtotal"
                                        value={ trxBank.subTotal }
                                        display={ true }
                                    />
                                </div>
                            </div>
                            
                            {
                                isPrint && 
                                <>
                                    <div className="form-group-row">
                                        <div className="line"/>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <Label htmlFor="payment" text={ constant.PAYMENT } isMandatory={ true } value={ trxBank.payment } />
                                            <InputNumeral 
                                                id="payment"
                                                value={ trxBank.payment }
                                                onValueChange={ onPaymentChange }
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <Label htmlFor="outstanding" text={ constant.OUTSTANDING } />
                                            <InputNumeral 
                                                id="outstanding"
                                                value={ trxBank.outstanding }
                                                display={ true }
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <Label htmlFor="balance" text={ constant.BALANCE } />
                                            <InputNumeral 
                                                id="balance"
                                                value={ trxBank.balance }
                                                display={ true }
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label />
                                    <ActionButton 
                                        isSave={ false }
                                        isPrint={ isPrint }
                                        onPrint={handlePrint}
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                        { isPrint && <Note isAction={ true } /> }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Action;
