import apiPath from '../../../config/api';

const CustomerTypePath = {
    title: "Modul Tipe Pelanggan", 
    basePath: "/master/tipe-pelanggan",
    newPath: "/master/tipe-pelanggan/baru",
    detailPath: "/master/tipe-pelanggan/opsi/:id",
    baseApi: apiPath.masterCustomerType
}

export default CustomerTypePath;
