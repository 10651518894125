import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import constant from '../../../config/constant';
import menuPath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);
    
    const history = useHistory();

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [titleInfo, setTitleInfo] = useState("");

    const [menu, setMenu] = useState({
        id: 0,
        name: "",
        label: "",
        icon: "",
        path: "",
        parentId: null,
        isActive: 1
    });

    const onReset = e => {

        e.preventDefault();

        setMenu(mn => ({
            ...mn,
            name: "",
            label: "",
            icon: "",
            path: "",
            parentId: null,
            isActive: 1
        }));
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setMenu(mn => ({ 
            ...mn,
            [name]: value
        }));
    };

    const onActiveChange = e => {

        setMenu(mn => ({ 
            ...mn,
            isActive: menu.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postMenu = async () => {

            const result = await postData(menuPath.baseApi, menu);
            
            if (Object.keys(result).length) {

                onSuccess(`Sub menu ${result.label} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putMenu = async () => {

            const result = await putData(`${menuPath.baseApi}/${menu.id}`, menu);

            if (Object.keys(result).length) {
                
                onSuccess(`Sub menu ${result.label} berhasil di perbaharui`);

                history.goBack();

            }  
        }

        if (menu.id === 0) postMenu();
        else putMenu();
        
    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state.sub) {

                setMenu(state.sub);

                setTitleInfo(`Detail: Group ${state.parentLabel} | Submenu: ${state.sub.label}`);

                setIsResetable(false);
    
            } else {
    
                setTitleInfo(`${constant.NEW} | Detail: Group ${state.parentLabel}`);

                setMenu(mn => ({ 
                    ...mn,
                    parentId: state.parentId
                }));
            }

        }
    
        init();

    }, []);

    useEffect(() => {

        if (menu.name && menu.label) setIsDisabledSave(false);

    }, [menu.name, menu.label]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{menuPath.title} | {titleInfo}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="menu-sub-name" isCol={ true } text="Nama Sub Menu" isMandatory={ true } value={ menu.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="menu-sub-name"
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        value={ menu.name }
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="menu-sub-name" isCol={ true } text="Nama Sub Menu" isMandatory={ true } value={ menu.label } />
                                <div className="col-md-9">
                                    <input 
                                        id="menu-sub-label"
                                        type="text" 
                                        className="form-control" 
                                        name="label"
                                        value={ menu.label }
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="menu-sub-name" isCol={ true } text="Path Sub Menu" isMandatory={ true } value={ menu.path } />
                                <div className="col-md-9">
                                    <input 
                                        id="menu-sub-path"
                                        type="text" 
                                        className="form-control" 
                                        name="path"
                                        value={ menu.path }
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="menu-sub-icon" isCol={ true } text="Icon" />
                                <div className="col-md-9">
                                    <input 
                                        id="menu-sub-icon"
                                        type="text" 
                                        className="form-control" 
                                        name="icon"
                                        value={ menu.icon ? menu.icon : '' }
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="menu-sub-icon" isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="menu-sub-active"
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            name="isActive"
                                            value={ menu.isActive }
                                            checked={ menu.isActive }
                                            onChange={ e => onActiveChange(e) } 
                                        />   
                                        <label htmlFor="menu-sub-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset }/>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
                
            </div>
        </React.Fragment>
    );
};

export default Action;
