import apiPath from '../../../config/api';

const UserPath = {
    title: "Modul User",
    basePath: "/admin/user",
    newPath: "/admin/user/baru",
    detailPath: "/admin/user/opsi/:id",
    newEmployeePath: "/master/employee/baru",
    newRolePath: "/admin/role/baru",
    baseApi: apiPath.adminUser,
    baseApiRole: apiPath.adminRole,
    baseApiEmployee: apiPath.masterEmployee,
    emplUser: apiPath.masterEmplUser,
    usernameFilterApi: apiPath.adminUserUnameFilter
}

export default UserPath;
