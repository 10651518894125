import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RetributionList from './List';
import RetributionAction from './Action';
import retributionPath from './config';

const RetributionRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={ retributionPath.basePath } exact component={ RetributionList } />
                <Route path={ retributionPath.newPath } exact component={ RetributionAction } />
                <Route path={ retributionPath.detailPath } exact component={ RetributionAction } />
            </Switch>
        </React.Fragment>
    );
};

export default RetributionRoutes;
