import React, {useState, useRef, useEffect, useCallback} from 'react';
import { DateTime } from 'luxon';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import Label from '../../../components/label';
import Select from '../../../components/select';
import Note from '../../../components/note';
import FilterButton from '../../../components/filter-button';
import TextNumber from '../../../components/text-number';
import { getData } from '../../../helper/request-response';
import LoaderType from '../../../components/loader-type';
import constant from '../../../config/constant';
import bankBalancePath from './config';

const List = () => {

    const [isLoad, setIsLoad] = useState(true);

    const [rows, ,] = useGlobalState('rows');

    const rowsRef = useRef(rows);

    const [selectedBank, setSelectedBalance] = useState(constant.DEFAULT_SELECTED);

    const [bankBalance, setBankBalance] = useState([]);

    const [bankBalanceFilter, setBankBalanceFilter] = useState({ 
        'bankId': 0,
        'rows': rows
    });

    const getFilterParams = useCallback((filter) => {

        const params = { 
            'params': { 
                'bankId': _.get(filter, 'bankId'),
                'rows': _.get(filter, 'rows')
            } 
        };

        return params;

    }, []);

    const onSelectedBank = opt => {

        setBankBalanceFilter(filter => ({ 
            ...filter,
            bankId: opt.id
        }));

        setSelectedBalance(code => ({
            ...code,
            value: opt.id,
            label: opt.label
        }));
    }

    const getBankBalance = (params) => {

        setIsLoad(true);

        getData(bankBalancePath.api, params).then(result => {

            setBankBalance(result);
            setIsLoad(false);
            
        });

    }

    const onSubmit = e => {

        e.preventDefault();

        const params = getFilterParams(bankBalanceFilter);
        getBankBalance(params);

    };

    const onReset = e => {
        e.preventDefault();

        setBankBalanceFilter(filter => ({
            ...filter,
            bankId: 0,
            rows: rows
        }));

        setSelectedBalance(constant.DEFAULT_SELECTED);

        const params = getFilterParams({ 'rows': bankBalanceFilter.rows });
        getBankBalance(params);
    }

    useEffect(() => {

        const params = getFilterParams({ 'rows': rowsRef.current });
        getBankBalance(params);

     }, [getFilterParams]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{bankBalancePath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } > 
                            <div className="form-group row">
                                <Label htmlFor="bank-name" text="Bank" isCol={ true } />
                                <div className="col-md-9">
                                    <Select
                                        id="bank-name"
                                        api={ bankBalancePath.bankApi }
                                        value={ selectedBank } 
                                        onChange={ onSelectedBank }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <FilterButton to={ bankBalancePath.new } newLabel="Tambah Data Saldo Bank" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Data Saldo Bank" entity="Saldo Bank" />
                    </div>
                </div>
                <br />

                { isLoad && <LoaderType /> }

                {
                    !isLoad && bankBalance.length > 0 &&
                    <div className="col-lg-12 mb-4">
                        <div className="row">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tanggal Saldo</th>
                                        <th>Bank</th>
                                        <th>Nama</th>
                                        <th>No Rekening</th>
                                        <th>Saldo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        bankBalance.map((bank, index) => {
                                            return(
                                                <tr key={index}>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ DateTime.fromISO(bank.balanceDate).toFormat('dd-LLLL-yyyy') }</td>
                                                    <td>{ bank.bankName }</td>
                                                    <td>{ bank.accountName }</td>
                                                    <td>{ bank.accountNo }</td>
                                                    <td><TextNumber value={ bank.balance } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
