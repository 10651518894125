import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoadList from './List';
import loadPath from './config';

const HarvestRoute = () => {
    return (
        <Switch>
            <Route path={ loadPath.basePath } exact component={ LoadList } />
        </Switch>
    );
};

export default HarvestRoute;
