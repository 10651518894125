import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useGlobalState } from 'state-pool';

import { getData } from '../../helper/request-response';
import TrxDate from '../../components/trx-date';

const TransactionUpdate = () => {

    const [syncDate, setSyncDate] = useState({
        'bankTrx': undefined,
        'retailTrx': undefined,
        'popTrx': undefined,
        'posTrx': undefined
    });

    const [isShowRetailCash, ,] = useGlobalState('isShowRetailCash');
    const [retailTrxLabel, ,] = useGlobalState('retailTrxLabel');
    const [isShowPBDOCash, ,] = useGlobalState('isShowPBDOCash');
    const [isShowTBSCash, ,] = useGlobalState('isShowTBSCash');
    const [poSalesLabel, ,] = useGlobalState('poSalesLabel');
    const [poPurchaseLabel, ,] = useGlobalState('poPurchaseLabel');
    const [isShowBankCash, ,] = useGlobalState('isShowBankCash');
    const [bankTrxLabel, ,] = useGlobalState('bankTrxLabel');

    useEffect(() => { 

        getData('/dashboard/sync').then(result => {
            
            setSyncDate(sync => ({
                ...sync,
                bankTrx: _.get(_.find(result, {label: 'bankTrx'}), 'trxDate'),
                retailTrx: _.get(_.find(result, {label: 'retailTrx'}), 'trxDate'),
                popTrx: _.get(_.find(result, {label: 'popTrx'}), 'trxDate'),
                posTrx: _.get(_.find(result, {label: 'posTrx'}), 'trxDate'),
            }));
            
        });

    }, []);

    return(
        <div className="card">
            <div className="card-header">
                <h2 className="h6 text-uppercase mb-0">Tanggal Update Transaksi</h2>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="d-flex flex-wrap justify-content-between align-items-start align-items-sm-center mb-2 flex-column flex-sm-row">
                            { isShowBankCash === "1" && <TrxDate date={ syncDate.bankTrx } label={ bankTrxLabel } /> }
                        </div>
                        <div className="d-flex flex-wrap justify-content-between align-items-start align-items-sm-center mb-2 flex-column flex-sm-row">
                            { isShowRetailCash === "1" && <TrxDate date={ syncDate.retailTrx } label={ retailTrxLabel} /> }
                        </div>
                        <div className="d-flex flex-wrap justify-content-between align-items-start align-items-sm-center mb-2 flex-column flex-sm-row">
                            { (isShowPBDOCash === "1" || isShowTBSCash === "1") && <TrxDate date={ syncDate.popTrx } label={ poPurchaseLabel } /> }
                        </div>
                        <div className="d-flex flex-wrap justify-content-between align-items-start align-items-sm-center mb-2 flex-column flex-sm-row">
                            { (isShowPBDOCash === "1" || isShowTBSCash === "1") && <TrxDate date={ syncDate.posTrx } label={ poSalesLabel } /> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionUpdate;