import React, { useState } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';

import Label from '../../../components/label';
import Select from '../../../components/select';
import TextNumber from '../../../components/text-number';
import DayPicker from '../../../components/day-picker';
import ReportButton from '../../../components/report-button';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import cSDetailPath from './config';

const List = () => {

    const [startDate, setStartDate] = useState(DateTime.local());

    const [endDate, setEndDate] = useState(DateTime.local());

    const [cSDetail, setCSDetail] = useState([]);

    const [cSDetailFilter, setFilterCSDetail] = useState({
        startDate:"",
        endDate:"",
        masterCustomerId: "",
        reportType:"",
        custName:""
    });

    const [selectedCustName, setSelectedCustName] = useState(constant.DEFAULT_SELECTED);

    const onStartDayChange = day => setStartDate(formatDate(day, constant.DEFAULT_DATE));

    const onEndDayChange = day => setEndDate(formatDate(day, constant.DEFAULT_DATE));

    const getExcel = async(param, url) => await getFile(url, param);

    const onExport = e => {

        e.preventDefault();

        const params = {
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                endDate: DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT),
                masterCustomerId: cSDetailFilter.masterCustomerId,
                reportType: cSDetailFilter.reportType,
                custName: cSDetailFilter.custName
            }
        };

        getExcel(params, cSDetailPath.custSaldoDetailExcel).then(response => {

            downloadFile(response);

        });

    }

    const onReset = e => {

        e.preventDefault();

        setFilterCSDetail(cSDetailFilter => ({ 
            ...cSDetailFilter,
            startDate:"",
            endDate:"",
            masterCustomerId: "",
            reportType:"",
            custName:""
        }));

        setStartDate(DateTime.local());
        setEndDate(DateTime.local());

        getCSDetails();

    }

    const onInputChange = e => {

        setFilterCSDetail({ 
            ...cSDetailFilter,
            [e.target.name]: e.target.value
        });
    };


    const getCSDetails = async(params) => {

        const result = await getData(cSDetailPath.baseApi, params);

        setCSDetail(result[0]);

    }

    /**
     * Customer change event.
     * @param {Object} opt - Option object.
     */
    const onChangeCustomer = opt => {

        if (opt.id) {

            setFilterCSDetail(trx => ({
                ...trx,
                masterCustomerId: opt.id,
                custName: opt.name
            }));

            setSelectedCustName(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                endDate: DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT),
                masterCustomerId: cSDetailFilter.masterCustomerId,
                reportType: cSDetailFilter.reportType
            }
        };

        getCSDetails(params);

    };

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ cSDetailPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="startDate" text="Tanggal Dari" />
                                    <DayPicker id="startDate" name="startDate" onDayChange={onStartDayChange} valueDate={ startDate } />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="endDate" text="Tanggal Ke" />
                                    <DayPicker id="endDate" name="endDate" onDayChange={onEndDayChange} valueDate={ endDate } />
                                </div>
                            </div>
                            
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="cSDetail-name" text="Nama" />
                                    <Select
                                        id="customer-name"
                                        paramFilter="name"
                                        api={ cSDetailPath.custFind }
                                        entityId="3,4,6"
                                        onChange={ onChangeCustomer }
                                        value={ selectedCustName }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">

                                    <Label htmlFor="reportType" text="Jenis Laporan" />
                                    <select id="reportType" className="form-control" name="reportType" onChange={ e => onInputChange(e) } value={cSDetailFilter.reportType}>
                                        <option value="">Pilih</option>
                                        <option value="1">Tabungan</option>
                                        <option value="2">Hutang</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tanggal</th>
                                        <th>No Faktur</th>
                                        <th>Nama</th>
                                        <th>Tipe</th>
                                        <th>Deskripsi</th>
                                        <th>Kredit</th>
                                        <th>Debit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cSDetail.map((cs, index) => {
                                            return(
                                                <tr key={ index }>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ `${formatDate(cs.trx_date, 'LL', constant.LOCALE)}` }</td>
                                                    <td>{ cs.invoice_no }</td>
                                                    <td>{ cs.customer_name }</td>
                                                    <td>{ cs.customer_type }</td>
                                                    <td>{ cs.description }</td>
                                                    <td><TextNumber value={ cs.credit } /></td>
                                                    <td><TextNumber value={ cs.debit } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
