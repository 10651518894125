import React, { useState } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';

import Label from '../../../components/label';
import InputNumeral from '../../../components/input-numeral';
import TextNumber from '../../../components/text-number';
import DayPicker from '../../../components/day-picker';
import ReportButton from '../../../components/report-button';
import { getData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import chartPath from './config';
import {Line} from 'react-chartjs-2';

const List = () => {

    const [chart, setChart] = useState([]);

    const [startDate, setStartDate] = useState(DateTime.local());

    const [endDate, setEndDate] = useState(DateTime.local());

    const [chartFilter, setFilterChart] = useState({
        startDate:"",
        endDate:""
    });

    const [chartSummary, setChartSummary] = useState({
        pay:0,
        outstanding:0
    });

    const onStartDayChange = day => setStartDate(formatDate(day, constant.DEFAULT_DATE));

    const onEndDayChange = day => setEndDate(formatDate(day, constant.DEFAULT_DATE));

    const stateLoan = {
        labels: chart.map(item => item.date),
        datasets: [
          {
            label: 'Hutang',
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(83,51,237,1)',
            borderWidth: 4,
            data: chart.map(item => item.loan)
          }
        ]
      }

      const statePay = {
        labels: chart.map(item => item.date),
        datasets: [   
          {
            label: 'Cicilan',
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(207,0,15,1)',
            borderWidth: 4,
            data: chart.map(item => item.pay)
          }
        ]
      }

      const getChart = async(params) => {

        const result = await getData(chartPath.baseApi, params);

        setChart(result[0]);

        getData(chartPath.chartSum, params).then(result => {

            if (Object.keys(result[0]).length) {

                setChartSummary(sum => ({
                    ...sum,
                    pay: result[0][0].pay,
                    outstanding: result[0][0].outstanding
                }));
            }

        });
    }

    const onReset = e => {

        e.preventDefault();

        setFilterChart(trx => ({ 
            ...chartFilter,
            startDate:"",
            endDate:""
        }));

        setStartDate(DateTime.local());
        setEndDate(DateTime.local());

        getChart();

    }

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                endDate: DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT)
            }
        };

        getChart(params);

    };

    return(
        <React.Fragment>

            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ chartPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="startDate" text="Tanggal Dari" />
                                    <DayPicker id="startDate" name="startDate" onDayChange={onStartDayChange} valueDate={ startDate } />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="endDate" text="Tanggal Ke" />
                                    <DayPicker id="endDate" name="endDate" onDayChange={onEndDayChange} valueDate={ endDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset }/>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <div className="col-md-12">
                                <h3>Rekapitulasi</h3>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6">
                                <Label text="Jumlah Hutang" />
                                <InputNumeral
                                    value={chartSummary.outstanding}
                                    display={ true }
                                />
                            </div>

                            <div className="col-md-6">
                                <Label text="Jumlah Cicilan" />
                                <InputNumeral
                                    value={chartSummary.pay}
                                    display={ true }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                

                <br />

                <div>
                    <Line
                        data={stateLoan}
                        options={{
                        title:{
                            display:true,
                            text:'Grafik Hutang',
                            fontSize:20
                        },
                        legend:{
                            display:true,
                            position:'right'
                        }
                        }}
                    />
                </div>

                <div>
                    <Line
                        data={statePay}
                        options={{
                        title:{
                            display:true,
                            text:'Grafik Cicilan',
                            fontSize:20
                        },
                        legend:{
                            display:true,
                            position:'right'
                        }
                        }}
                    />
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>Tanggal</th>
                                        <th>Hutang</th>
                                        <th>Cicilan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        chart.map((chart, index) => {
                                            return(
                                                <tr key={ index }>
                                                    <td>{ chart.date }</td>
                                                    <td><TextNumber value={ chart.loan } /></td>
                                                    <td><TextNumber value={ chart.pay } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
