import apiPath from '../../../config/api';

const loadPath = {
    title: "TBS - Laporan Biaya Timbang Muat",
    basePath: "/sawit/laporan/timbang-muat",
    base: apiPath.palmOilLoad,
    palmOilLoadSum: apiPath.palmOilLoadSum,
    palmOilLoadExcel: apiPath.palmOilLoadExcel
}

export default loadPath;
