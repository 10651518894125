import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import _ from 'lodash';

import Select from '../../../components/select';
import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import InputNumber from '../../../components/input-number';
import LinkButton from '../../../components/link-button';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import typePath from './config';

const Action = () => {
    
    const data = useLocation();

    const refData = useRef(data);
    
    const history = useHistory();

    const [selectedCoa, setSelectedCoa] = useState({ value: '', label: ''});
    
    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [titleInfo, setTitleInfo] = useState('');

    const bankTrxTypeEntity = {
        id: 0,
        masterCoaId: 0,
        name: '',
        extFee: 0,
        intFee: 0,
        isActive: 1
    }

    const [bankTrxType, setBankTrxType] = useState(bankTrxTypeEntity);

    const onReset = e => {

        e.preventDefault();

        setBankTrxType(bankTrxTypeEntity);

    }

    const onFeeChange = e => {

        const { name, value } = e.target;

        setBankTrxType(data => ({
            ...data,
            [name]: value.replace(',', '')
        }));
    }

    const onClickFee = e => {

        const { name, value } = e.target;

        const val = parseInt(value);

        if (val === 0) {

            setBankTrxType(type => ({ 
                ...type,
                [name]: ''
            }));
        }
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setBankTrxType(type => ({ 
            ...type,
            [name]: value
        }));
    };

    const onSelectChangeCoa = async (opt) => {

        setBankTrxType(data => ({ 
            ...data,
            masterCoaId: opt.id,
        }));

        setSelectedCoa(code => ({
            ...code,
            label: opt.label
        }));

    };

    const onActiveChange = e => {

        setBankTrxType(type => ({ 
            ...type,
            isActive: bankTrxType.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postBankTrxType = async () => {

            const result = await postData(typePath.baseApi, bankTrxType);

            if (!_.isEmpty(result)) {

                onSuccess(`Jenis Transaksi ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };

        const putBankTrxType = async () => {

            const result = await putData(`${typePath.baseApi}/${bankTrxType.id}`, bankTrxType);

            if (!_.isEmpty(result)) {

                onSuccess(`Jenis Transaksi ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        }

        if (bankTrxType.id === 0) postBankTrxType();
        else putBankTrxType();

    }

    useEffect(() => {

        const setBankTrxTypeState = () => {

            const state = refData.current.state;

            if (state) {

                setBankTrxType(state)

                setTitleInfo(`Detail: ${state.name}`);

                setSelectedCoa(coa => ({
                    ...coa,
                    value: state.accountNo,
                    label: state.description
                }))

                setIsResetable(false);

            } else {

                setTitleInfo("Tambah Data Baru");

            }
        }

        setBankTrxTypeState();

    }, []);

    useEffect(() => {

        if (bankTrxType.masterCoaId && bankTrxType.name && bankTrxType.intFee && bankTrxType.extFee) setIsDisabledSave(false);

    }, [bankTrxType.masterCoaId, bankTrxType.name, bankTrxType.intFee, bankTrxType.extFee]);

    return (

        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{typePath.title} | {titleInfo}</h3>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="coa-id" isCol={ true } text="COA" isMandatory={ true } value={ bankTrxType.masterCoaId } />
                                <div className="col-md-9">
                                    <Select
                                        id="coa-name"
                                        paramFilter="name"
                                        entityId='102'
                                        api={ typePath.cashCoa }
                                        onChange={ onSelectChangeCoa }
                                        value={ selectedCoa } 
                                    />
                                    <span><LinkButton to={ typePath.newCoa } className="stretched-link" label="Tambah COA Baru" /></span>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="type-name" isCol={ true } text="Jenis Transaksi" isMandatory={ true } value={ bankTrxType.name } />
                                <div className="col-md-9">
                                    <input 
                                    id="type-name"
                                    type="text" 
                                    className="form-control" 
                                    name="name"
                                    value={bankTrxType.name}
                                    onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="ext-fee" isCol={ true } text="Biaya Admin" isMandatory={ true } value={ bankTrxType.extFee } />
                                <div className="col-md-9">
                                    <InputNumber 
                                        id="ext-fee"
                                        name="extFee"
                                        value={ bankTrxType.extFee }
                                        parent={ true }
                                        onChange={ onFeeChange }
                                        onClick={ onClickFee } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="int-fee" isCol={ true } text="Biaya Layanan" isMandatory={ true } value={ bankTrxType.intFee } />
                                <div className="col-md-9">
                                    <InputNumber 
                                        id="int-fee"
                                        name="intFee"
                                        value={ bankTrxType.intFee }
                                        parent={ true }
                                        onChange={ onFeeChange }
                                        onClick={ onClickFee } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="is-active"
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            name="isActive"
                                            value={ bankTrxType.isActive }
                                            checked={ bankTrxType.isActive }
                                            onChange={ e => onActiveChange(e) } />   
                                        <label htmlFor="is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
