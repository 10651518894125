import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CSDetailList from './List';
import cSDetailPath from './config';

const CSDetailRoutes = () => {
    return (
        <Switch>
            <Route path={cSDetailPath.basePath} exact component={CSDetailList} />
        </Switch>
    );
};

export default CSDetailRoutes;