import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import _ from 'lodash';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import DayPicker from '../../../components/day-picker';
import InputNumeral from '../../../components/input-numeral';
import Select from '../../../components/select';
import { postData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import bankBalancePath from './config';
import constant from '../../../config/constant';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();
    
    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [selectedBankAcc, setSelectedBankAcc] = useState({ value: '', label: ''});

    const bankBalanceEntity = {
        id: 0,
        accountId: 0,
        balanceDate: undefined,
        balance: 0
    }

    const [bankBalance, setBankBalance] = useState(bankBalanceEntity);

    const onBalanceDateChange = day => {

        setBankBalance(data => ({ 
            ...data,
            balanceDate: formatDate(day, constant.DEFAULT_DATE)
        }));
    }

    const onBalanceChange = values => {

        const { value } = values;

        setBankBalance(data => ({
            ...data,
            balance: parseFloat(value)
        }));
    }

    const onReset = e => {

        e.preventDefault();

        setBankBalance(bankBalanceEntity);
    }

    const onSelectedBank = opt => {
        
        setSelectedBankAcc(code => ({
            ...code,
            value: opt.id,
            label: opt.label
        }));

        setBankBalance(data => ({
            ...data,
            accountId: opt.id
        }))

    }

    const onSubmit = (e) => {

        e.preventDefault();

        const postBankAccount = async () => {

            const result = await postData(bankBalancePath.api, bankBalance);

            if (!_.isEmpty(result)) {

                onSuccess(`Saldo ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };

        postBankAccount();
        
    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state) {
    
                setBankBalance(state);

                setSelectedBankAcc(code => ({
                    ...code,
                    value: state.bankAccountId,
                    label: state.bankAccountName
                }));
                
                setTitleInfo(`Detail: ${state.bankAccountName}`);

                setIsResetable(false);
    
            } else {
    
                setTitleInfo("Tambah Saldo");
                
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (bankBalance.accountId && bankBalance.balanceDate && bankBalance.balance) setIsDisabledSave(false);

    }, [bankBalance.accountId, bankBalance.balanceDate, bankBalance.balance]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ bankBalancePath.title } | { titleInfo }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="bank-account" text="Akun Bank" isMandatory={ true } value={ bankBalance.accountId } />
                                    <div>
                                        <Select
                                            id="bank-account"
                                            api={ bankBalancePath.bankAccApi }
                                            value={ selectedBankAcc }
                                            onChange={ onSelectedBank }
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal" isMandatory={ true } value={ bankBalance.balanceDate } />
                                    <div>
                                        <DayPicker id="trx-date" name="trxDate" valueDate={ bankBalance.balanceDate } onDayChange={ onBalanceDateChange } />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="balance" text="Saldo Di Bank" isMandatory={ true } value={ bankBalance.balance } />
                                    <InputNumeral
                                        id="balance"
                                        value={ bankBalance.balance }
                                        onValueChange={ onBalanceChange }
                                    />
                                </div>
                            </div>
                                
                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
