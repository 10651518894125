import React, {useState, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import { DateTime } from 'luxon';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import InputNumeral from '../../../components/input-numeral';
import MaxTransfer from '../../../components/max-transfer';
import { postData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import { getAuliaBalance, getRetailBalance, getPoBalance, getPbdoBalance, getBankBalance } from '../../../helper/cash-balance';
import constant from '../../../config/constant';
import cashTrfPath from './config';

const Action = () => {
    
    const data = useLocation();
    
    const history = useHistory();

    const [auliaBalance, , setAuliaBalance] = useGlobalState('auliaBalance');

    const [retailBalance, , setRetailBalance] = useGlobalState('retailBalance');

    const [poBalance, , setPoBalance] = useGlobalState('poBalance');

    const [pbdoBalance, , setPbdoBalance] = useGlobalState('pbdoBalance');

    const [bankBalance, , setBankBalance] = useGlobalState('bankBalance');

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isDisabledAmount, setIsDisabledAmount] = useState(true);
    
    const [titleInfo, setTitleInfo] = useState("");

    const [selectedCoaOut, setSelectedCoaOut] = useState({ value: '', label: ''});

    const [selectedCoaIn, setSelectedCoaIn] = useState({ value: '', label: ''});

    const [cashTrf, setCashTrf] = useState(
        {
            id: 0,
            apArId: 419,
            isDropingAulia: 0,
            trxDate: DateTime.local(),
            invoiceNo: `CT${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
            invoiceLineNo: null,
            amount:0,
            coaIn: "",
            coaInDesc: "",
            coaOut: "",
            coaOutDesc: "",
            masterCustomerId: 0,
        });

    const checkMax = (val, max) => val > max ? max : val; 

    const getMax = coaOut => {

        if (coaOut === '1015000') return auliaBalance;

        if (coaOut === '1014000') return retailBalance;

        if (coaOut === '1012000') return poBalance;

        if (coaOut === '1013000') return pbdoBalance;

        if (coaOut === '1011000') return bankBalance;

        return 0;
    }

    const onDropingAulia = e => {

        const { value } = e.target;

        if (value) setIsDisabledAmount(false);
        else setIsDisabledAmount(true);

        setCashTrf(trf => ({ 
            ...trf,
            isDropingAulia: parseInt(value) === 0 ? 1 : 0,
            coaIn: parseInt(value) === 0 ? '1025000': '',
            coaInDesc: parseInt(value) === 0 ? 'KAS MASUK AULIA' : '' 
        }));

    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setCashTrf(data => ({
            ...data,
            [name]: value
        }));
        
    };

    const onAmountChange = values => {

        const { value } = values;
        const val = parseFloat(value);
        const max = getMax(cashTrf.coaOut);
        const amt = cashTrf.isDropingAulia ? val : checkMax(val, max);
        
        setCashTrf(data => ({
            ...data,
            amount: amt
        }));
    }

    const onSelectChangeCoaOut = opt => {

        if (opt.accountNo !== cashTrf.coaIn) {

            setCashTrf(data => ({ 
                ...data,
                coaOut: opt.accountNo,
                coaOutDesc: opt.description
            }));
    
            setSelectedCoaOut(code => ({
                ...code,
                label: opt.label
            }));

        }
    }

    const onSelectChangeCoaIn = opt => {

        if (opt.accountNo !== cashTrf.coaOut) {

            setCashTrf(data => ({ 
                ...data,
                coaIn: opt.accountNo,
                coaInDesc: opt.description
            }));
    
            setSelectedCoaIn(code => ({
                ...code,
                label: opt.label
            }));

        }
    }

    const onDayChange = day => {

        setCashTrf({ 
            ...cashTrf,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    }

    const onReset = e => {

        e.preventDefault();

        setCashTrf(data => ({ 
            ...data,
            id: 0,
            apArId: 0,
            trxDate: DateTime.local(),
            invoiceNo: `CT${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
            invoiceLineNo: null,
            amount:0,
            coaIn: "",
            coaInDesc: "",
            coaOut: "",
            coaOutDesc: "",
            masterCustomerId: 0,
        }));

        setSelectedCoaOut(code => ({
            ...code,
            label: ""
        }));

    }

    const onSubmit = (e) => {

        e.preventDefault();

        const postBankTrx = () => {

            postData(cashTrfPath.baseApi, cashTrf).then(result => {

                if (!_.isEmpty(result)) {

                    getAuliaBalance();
                    getRetailBalance();
                    getPoBalance();
                    getPbdoBalance();
                    getBankBalance();

                    onSuccess(`Transaksi ${result.invoice_no} berhasil di tambahkan`);

                    history.goBack();

                }

            });
        };

        if (cashTrf.id === 0) {

            setAuliaBalance(0);
            setRetailBalance(0);
            setPoBalance(0);
            setPbdoBalance();
            setBankBalance(0);

            postBankTrx();
        
        }

    }

    useEffect(() => {

        function setCashTrfState() {

            if (data.state) {

                setCashTrf(data.state)

                setTitleInfo(`Detail: ${data.state.invoiceNo}`);

            } else {

                setTitleInfo("Tambah Data Baru");

            }
        }

        setCashTrfState();

    }, [data]);

    useEffect(() => {

        if (cashTrf.isDropingAulia) {

            if (cashTrf.amount > 0) setIsDisabledSave(false);
            else setIsDisabledSave(true);

        } else {

            if (cashTrf.coaIn && cashTrf.coaOut && ( cashTrf.amount > 0)) setIsDisabledSave(false);
            else setIsDisabledSave(true);

        }

        if (cashTrf.coaOut) setIsDisabledAmount(false);

    }, [cashTrf.coaIn, cashTrf.coaOut , cashTrf.amount, cashTrf.isDropingAulia]);

    return (

        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{cashTrfPath.title} | {titleInfo}</h3>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">

                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="cashTrf-number" text="No Transfer"/>
                                    <input 
                                    id="cashTrf-number"
                                    type="text" 
                                    className="form-control" 
                                    name="invoiceNo"
                                    value={cashTrf.invoiceNo}
                                    onChange={ e => onInputChange(e) } 
                                    readOnly
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal"/>
                                    <div>
                                        <DayPicker id="trx-date" name="trxDate" valueDate={cashTrf.trxDate} onDayChange={onDayChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="is-droping-aulia" text="Kas Aulia"/>
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="is-droping-aulia"
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            name="isDropingAulia"
                                            value={ cashTrf.isDropingAulia }
                                            checked={ cashTrf.isDropingAulia }
                                            onChange={ e => onDropingAulia(e) } />   
                                            <label htmlFor="is-droping-aulia" className="custom-control-label">Droping Kas Aulia</label>
                                    </div>
                                </div>
                            </div>

                            {
                                !cashTrf.isDropingAulia &&
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <Label htmlFor="coa-out" text="Transfer Dari" isMandatory={ !cashTrf.isDropingAulia } value={ cashTrf.coaOut } />
                                        <Select
                                            id="coa-out"
                                            paramFilter="name"
                                            entityId='101'
                                            api={ cashTrfPath.cashCoa }
                                            onChange={ onSelectChangeCoaOut }
                                            value={ selectedCoaOut } 
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <Label htmlFor="coa-in" text="Transfer Ke" isMandatory={ !cashTrf.isDropingAulia } value={ cashTrf.coaIn } />
                                        <Select
                                            id="coa-in"
                                            paramFilter="name"
                                            entityId='102'
                                            api={ cashTrfPath.cashCoa }
                                            onChange={ onSelectChangeCoaIn }
                                            value={ selectedCoaIn } 
                                        />
                                    </div>
                                </div>
                            }
                            {
                                cashTrf.isDropingAulia !== 0 &&
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <Label htmlFor="description" text="Deskripsi" value={ cashTrf.coaInDesc } />
                                        <textarea 
                                            id="description"
                                            className="form-control" 
                                            name="coaInDesc"
                                            value={cashTrf.coaInDesc}
                                            onChange={ e => onInputChange(e) } 
                                        />
                                    </div>
                                </div>
                            }

                            <div className="form-group row">
                                <div className="col-md-12">
                                <Label htmlFor="amount" text="Nominal" isMandatory={ true } value={ cashTrf.amount } />
                                <InputNumeral 
                                    id="amount"
                                    name="amount"
                                    value={ cashTrf.amount }
                                    onValueChange={ onAmountChange } 
                                    isDisabled= { isDisabledAmount }
                                />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                <Label />
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ true } 
                                        onReset={ onReset } />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="col-md-6">
                        <Note isAction={ true } />

                        <MaxTransfer coaOut={ cashTrf.coaOut } coaValue={ '1015000' } label={ 'Kas Aulia' } balance={ auliaBalance } />

                        <MaxTransfer coaOut={ cashTrf.coaOut } coaValue={ '1014000' } label={ 'Kas Toko' } balance={ retailBalance } />

                        <MaxTransfer coaOut={ cashTrf.coaOut } coaValue={ '1013000' } label={ 'Kas PBDO' } balance={ pbdoBalance } />

                        <MaxTransfer coaOut={ cashTrf.coaOut } coaValue={ '1012000' } label={ 'Kas TBS' } balance={ poBalance } />

                        <MaxTransfer coaOut={ cashTrf.coaOut } coaValue={ '1011000' } label={ 'Kas BRILink' } balance={ bankBalance } />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Action;
