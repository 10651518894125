import React, { useState, useEffect } from 'react';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import TextNumber from '../../../components/text-number';
import LoaderType from '../../../components/loader-type';
import { getData } from '../../../helper/request-response';
import banktrxtypePath from './config';

const List = () => {

    const [banktrxtypes, setBankTrxTypes] = useState([]);

    const [coa, setCoa] = useState([]);

    const [isLoad, setIsLoad] = useState(true);

    const [banktrxtypeFilter, setFilterBankTrxType] = useState({
        name: "",
        masterCoaId: ""
    });

    const getDetailPath = (id) => banktrxtypePath.detailPath.replace(":id", id);

    const onInputChange = e => {

        const { name, value } = e.target;

        setFilterBankTrxType(filter => ({ 
            ...filter,
            [name]: value
        }));
    };

    const onSubmit = e => {

        e.preventDefault();

        setIsLoad(true);

        const params = { 
            params: {
                name: banktrxtypeFilter.name,
                masterCoaId: banktrxtypeFilter.masterCoaId
            }
        };

        getBankTrxTypes(params);
    };

    const getBankTrxTypes = async(params) => {

        const result = await getData(banktrxtypePath.baseApi, params);
            
        setBankTrxTypes(result);

        setIsLoad(false);

    }

    const getCoa = async() => {

        const result = await getData(banktrxtypePath.baseApiCoa);

        setCoa(result);

    }

    const onReset = e => {

        e.preventDefault();

        setIsLoad(true);

        setFilterBankTrxType(filter => ({ 
            ...filter,
            name: "",
            masterCoaId: 0
        }));

        getBankTrxTypes();

        getCoa();

    }

    useEffect(() => { 

        getBankTrxTypes();

        getCoa();

     }, []);
    

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{banktrxtypePath.title}</h3>
            </div>
            <div className="card-body">

                {
                    isLoad &&
                    <LoaderType />
                }

                {
                    !isLoad &&
                    <>
                        <div className="row">

                            <div className="col-md-8 border-right">
                                <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                                    <div className="form-group row">
                                        <Label htmlFor="masterCoaId" isCol={ true } text="COA" />
                                        <div className="col-md-9">
                                            <select id="masterCoaId" className="form-control" name="masterCoaId" onChange={ e => onInputChange(e) } value={banktrxtypeFilter.masterCoaId}>
                                                <option value="">Pilih</option>
                                                {coa.map(({id, accountNo, description}) => {return(<option key={id} value={id}>{accountNo} - {description}</option>)})}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <Label htmlFor="banktrxtype-name" isCol={ true } text="Jenis Transaksi" />
                                        <div className="col-md-9">
                                            <input 
                                                id="banktrxtype-name" 
                                                type="text" 
                                                className="form-control"
                                                name="name"
                                                value={banktrxtypeFilter.name}
                                                onChange={ e => onInputChange(e) } />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <Label isCol={ true } />
                                        <div className="col-md-9">
                                            <FilterButton to={ banktrxtypePath.newPath } newLabel="Tambah Jenis Transaksi Baru" onClick={ onReset } />
                                        </div>
                                    </div>

                                </form>
                            </div>

                            <div className="col-md-4">
                                <Note isFilter={ true } label="Tambah Jenis Transaksi Baru" entity="Jenis Transaksi" />
                            </div>
                        </div>

                        <br />

                        <div className="row">
                            <div className="col-lg-12">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>COA</th>
                                            <th>Jenis Transaksi</th>
                                            <th>Biaya Adm</th>
                                            <th>Biaya Layanan</th>
                                            <th>Opsi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            banktrxtypes.map((banktrxtype, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <th scope="row">{ index + 1 }</th>
                                                        <td>{ banktrxtype.accountNo.substring(0,3) }.{ banktrxtype.accountNo.substring(3) }</td>
                                                        <td>{ banktrxtype.name }</td>
                                                        <td><TextNumber value={ banktrxtype.extFee } /></td>
                                                        <td><TextNumber value={ banktrxtype.intFee } /></td>
                                                        <td><LinkButton to={ {pathname: getDetailPath(banktrxtype.id), state: banktrxtype} } /></td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
