import apiPath from '../../../config/api';

const SaveCredPath = {
    title: "Setor Tabungan",
    basePath: "/akunting/setor-tabungan",
    newPath: "/akunting/setor-tabungan/baru",
    detailPath: "/akunting/setor-tabungan/opsi/:id",
    baseApi: apiPath.accountSaveCred,
    custFind: apiPath.masterCustFind
}

export default SaveCredPath;
