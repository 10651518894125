import React, { useState, useEffect } from 'react';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import AutoComplete from '../../../components/async-select';
import { getData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import rolePath from './config';

const List = () => {

    const [roles, setRoles] = useState([]);

    const [roleFilter, setFilterRole] = useState({
        name: "",
        isActive: 1
    });

    const getDetailPath = (id) => rolePath.detailPath.replace(":id", id);

    const onFilterChange = opt => {

        setFilterRole(role => ({ 
            ...role,
            name: opt.label
        }));
    }

    const onActiveChange = e => {

        setFilterRole(role => ({ 
            ...role,
            isActive: role.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = e => {

        e.preventDefault();

        const params = {
            params: { 
                name: roleFilter.name,
                'is-active': roleFilter.isActive 
            }
        };

        getRoles(params).then(result => {
            setRoles(result);
        });

    };

    const onReset = e => {

        e.preventDefault();

        setFilterRole(role => ({ 
            ...role,
            name: "",
            isActive: 1
        }));

        getRoles().then(result => {
            setRoles(result);
        });
    }

    const getRoles = async(param) => {

        const result = await getData(rolePath.baseApi, param);

        return result;
    }

    useEffect(() => { 
        
        getRoles().then(result => {
            setRoles(result);
        });
        
    }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ rolePath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >
                            <div className="form-group row">
                                <Label htmlFor="role-name" isCol={ true } text="Nama Role" />
                                <div className="col-md-9">
                                    <AutoComplete 
                                        id="role-name"
                                        filterApi={ rolePath.nmFilterApi }
                                        onSelectChange={ onFilterChange } 
                                        filter={ {label: roleFilter.name} }/>
                                </div>

                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="role-is-active"
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="isActive"
                                            value={ roleFilter.isActive }
                                            checked={ roleFilter.isActive }
                                            onChange={ e => onActiveChange(e) } />   
                                        <label htmlFor="role-is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ rolePath.newPath } newLabel="Tambah Role Baru" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Role Baru" entity="Role" />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nama Role</th>
                                        <th>Status Role</th>
                                        <th>Opsi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        roles.map((role, index) => {
                                            return(
                                                <tr key={ index }>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ role.name }</td>
                                                    <td>{ role.isActive === 1 ? constant.ACTIVE : constant.INACTIVE }</td>
                                                    <td><LinkButton to={ {pathname: getDetailPath(role.id), state: role} } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
