import React from 'react';
import NumberFormat from 'react-number-format';

/**
 * 
 * @param {Object} parameter - { id: string, name: string, value: any, parent: Boolean, onClick: requestCallback, onChange: requestCallback } 
 */
const InputNumber = ({ id, name, value, parent, onChange, onClick }) => {

    return(
        <NumberFormat 
            id={ id }
            placeholder="0"
            className="form-control"
            name={ name }
            value={ value }
            thousandSeparator={ true }
            onChange={ e => onChange(e) }
            onClick={ e => onClick(e, parent) } />
    );

}

export default InputNumber;
