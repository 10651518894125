import React, { useState, useEffect } from 'react';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import { getData } from '../../../helper/request-response';
import LoaderType from '../../../components/loader-type';
import constant from '../../../config/constant';
import coaPath from './config';

const List = () => {

    const [isLoad, setIsLoad] = useState(true);

    const [coa, setCoa] = useState([]);

    const [coaFilter, setCoaFilter] = useState({
        accountNo: "",
        description: "",
        accountType: "",
        isActive: 1
    });

    const getDetailPath = (id) => coaPath.detailPath.replace(":id", id);

    const onFilterChange = e => {

        const { name, value } = e.target;

        setCoaFilter(filter => ({ 
            ...filter,
            [name]: value
        }));
    };

    const onActiveChange = e => {

        setCoaFilter(filter => ({ 
            ...filter,
            isActive: coaFilter.isActive === 1 ? 0 : 1
        }));
        
    };

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: { 
                accountNo: coaFilter.accountNo, 
                description: coaFilter.description, 
                accountType: coaFilter.accountType 
            } 
        };

        getCoa(params);

    }

    const getCoa = async(params) => {

        setIsLoad(true);

        const result = await getData(coaPath.baseApi, params);

        setCoa(result);

        setIsLoad(false);

    }

    const onReset = (e) => {

        e.preventDefault();

        setCoaFilter(filter => ({
            ...filter,
            accountNo: "",
            description: "",
            accountType: ""
        }));

        getCoa();

    }

    useEffect(() => { getCoa() }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{coaPath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="coa-accno" isCol={ true } text="COA" />
                                <div className="col-md-9">
                                    <input 
                                        id="coa-accno" 
                                        type="text" 
                                        className="form-control"
                                        name="accountNo"
                                        value={coaFilter.accountNo}
                                        onChange={ e => onFilterChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="coa-desc" isCol={ true } text="Deskripsi" />
                                <div className="col-md-9">
                                    <input 
                                        id="coa-desc" 
                                        type="text" 
                                        className="form-control" 
                                        name="description"
                                        value={coaFilter.description}
                                        onChange={ e => onFilterChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="account-type" isCol={ true } text="Jenis" />
                                <div className="col-md-9">
                                    <select id="account-type" className="form-control" name="accountType" onChange={ e => onFilterChange(e) } value={coa.accountType}>
                                        <option value="">Pilih</option>
                                        <option value="AR">Kas Masuk</option>
                                        <option value="AP">Kas Keluar</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="coa-is-active"
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="isActive"
                                            value={ coaFilter.isActive }
                                            checked={ coaFilter.isActive }
                                            onChange={ e => onActiveChange(e) }
                                        />   
                                        <label htmlFor="coa-is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ coaPath.newPath } newLabel="Tambah COA Baru" onClick={ onReset } />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah COA Baru" entity="COA" />
                    </div>
                </div>

                <br />
                {
                    isLoad && <LoaderType />
                }
                {
                    !isLoad &&
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>COA</th>
                                            <th>Deskripsi</th>
                                            <th>Jenis</th>
                                            <th>Status</th>
                                            <th>Opsi</th>
                                        </tr>
                                    </thead>
                                    {
                                        coa &&
                                        <tbody>
                                            {
                                                coa.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{item.accountNo.substring(0,3)}.{item.accountNo.substring(3)}</td>
                                                            <td>{item.description}</td>
                                                            <td>{item.accountType === "AP" ? "Kas Keluar" : "Kas Masuk"}</td>
                                                            <td>{item.isActive === 1 ? constant.ACTIVE : constant.INACTIVE}</td>
                                                            <td><LinkButton to={ {pathname: getDetailPath(item.id), state: item} } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div> 
                }
            </div>
        </React.Fragment>
    );
};

export default List;
