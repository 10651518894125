import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BankAccList from './List';
import BankAccAction from './Action';
import bankAccPath from './config';

const CoaRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={ bankAccPath.base } exact component={ BankAccList } />
                <Route path={ bankAccPath.new } exact component={ BankAccAction } />
                <Route path={ bankAccPath.detail } exact component={ BankAccAction } />
            </Switch>
        </React.Fragment>
    );
};

export default CoaRoutes;
