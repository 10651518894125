import React, { useState, useEffect } from 'react';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import AutoComplete from '../../../components/async-select';
import { getData } from '../../../helper/request-response';
import buPath from './config';

const List = () => {

    const [businessUnits, setBusinessUnits] = useState([]);

    const [buFilter, setBuFilter] = useState({
        code: "",
        name: ""
    });

    const getDetailPath = (id) => buPath.detailPath.replace(":id", id); 

    const onCodeSelectChange = opt => {

        setBuFilter({ 
            ...buFilter,
            code: opt.label
        });

    }

    const onCodeInputChange = val => {

        setBuFilter({ 
            ...buFilter,
            code: val
        });
    }

    const onNameSelectChange = opt => {

        setBuFilter({ 
            ...buFilter,
            name: opt.label
        });
    }

    const onNameInputChange = val => {

        setBuFilter({ 
            ...buFilter,
            name: val
        });
    }

    const onSubmit = (e) => {

        e.preventDefault();

        const params = {
            params: {
                code: buFilter.code,
                name: buFilter.name 
            }
        };

        getBusinessUnit(params);

    }

    const getBusinessUnit = async(params) => {

        const result = await getData(buPath.baseApi, params);

        setBusinessUnits(result);

    }

    const onAddBu = (e) => {

        e.preventDefault();
        setBuFilter({
            ...buFilter,
            code: "",
            name: ""
        });

        getBusinessUnit();

    }

    useEffect(() => { getBusinessUnit() }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{buPath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>

                            <div className="form-group row">
                                <Label htmlFor="bu-code" isCol={ true } text="Kode Unit Bisnis" />
                                <div className="col-md-9">
                                    <AutoComplete 
                                        id="bu-code"
                                        filterApi={ buPath.buCodeFilter }
                                        onSelectChange={ onCodeSelectChange } 
                                        onInputChange={ onCodeInputChange }
                                        filter={ {label: buFilter.code} } 
                                        isCreateable={ false } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="bu-name" isCol={ true } text="Nama Unit Bisnis" />
                                <div className="col-md-9">
                                    <AutoComplete 
                                        id="bu-name"
                                        filterApi={ buPath.buNameFilter }
                                        onSelectChange={ onNameSelectChange } 
                                        onInputChange={ onNameInputChange }
                                        filter={ {label: buFilter.name} }
                                        isCreateable={ false } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ buPath.newPath } newLabel="Tambah Unit Bisnis Baru" onClick={ onAddBu } />
                                </div>
                            </div>
                            
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Unit Bisnis Baru" entity="Unit Bisnis" />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Kode Unit Bisnis</th>
                                        <th>Nama Unit Bisnis</th>
                                        <th>Opsi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        businessUnits.map((bu, index) => {
                                            return(
                                                <tr key={index}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{bu.code}</td>
                                                    <td>{bu.name}</td>
                                                    <td><LinkButton to={ {pathname: getDetailPath(bu.id), state: bu} } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
