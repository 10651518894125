import apiPath from '../../../config/api';

const PoCashPath = {
    title: "Saldo Kas PBDO",
    path: "/kas/pbdo",
    api: apiPath.cashPbdo,
    apiExcel: apiPath.cashPbdoExcel
}

export default PoCashPath;
