import apiPath from '../../../config/api';

const SaveDebPath = {
    title: "Tarik Tabungan",
    basePath: "/akunting/tarik-tabungan",
    newPath: "/akunting/tarik-tabungan/baru",
    detailPath: "/akunting/tarik-tabungan/opsi/:id",
    baseApi: apiPath.accountSaveDeb,
    custFind: apiPath.masterCustFind
}

export default SaveDebPath;
