import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import LinkButton from '../../../components/link-button';
import { getData, postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import userPath from './config';

const Action = () => {
    
    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [titleInfo, setTitleInfo] = useState("");
    
    const [roles, setRole] = useState([]);
    
    const [employees, setEmployee] = useState([]);

    const [user, setUser] = useState({
        id: 0,
        username: "",
        password: "",
        isActive: 1,
        employeeId: 0,
        roleId: 0
     });

    const onInputChange = e => {

        const { name, value } = e.target;

        setUser(user => ({ 
            ...user,
            [name]: value
        }));
    };

    const onActiveChange = e => {
        
        setUser(user => ({ 
            ...user,
            isActive: user.isActive === 1 ? 0 : 1
        }));
    };

    const onReset = e  => {

        e.preventDefault();

        setUser(user => ({
            ...user,
            username: "",
            password: "",
            isActive: 1,
            employeeId: 0,
            roleId: 0
        }));
    }

    const onSubmit = (e) => {

        e.preventDefault();

        const postUser = async () => {

            const result = await postData(userPath.baseApi, user);

            if (Object.keys(result).length) {

                onSuccess(`Pengguna ${result.username} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putUser = async () => {

            const result = await putData(`${userPath.baseApi}/${user.id}`, user);

            if (Object.keys(result).length) {
                
                onSuccess(`Pengguna ${result.username} berhasil di perbaharui`);

                history.goBack();

            }
        }

        if (user.id === 0) postUser();
        else putUser();

    }

    const getRoles = async() => {

        const result = await getData(userPath.baseApiRole);

        setRole(result);
        
    }

    const getEmployees = async() => {

        const result = await getData(userPath.emplUser);

        setEmployee(result);

    }

    useEffect(() => {

        const setUserState = () => {

            const state = refData.current.state;

            if (state) {
                
                setUser(state)

                setTitleInfo(`Detail: ${state.username}`);

                setIsResetable(false);

            } else {

                setTitleInfo("Tambah Data Baru");

            }
        }

        getRoles();

        getEmployees();

        setUserState();

    }, []);

    useEffect(() => {

        if (user.username && user.password && user.employeeId && user.roleId) setIsDisabledSave(false);

    }, [user.username, user.password, user.employeeId, user.roleId]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{userPath.title} | {titleInfo}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="employee-name" isCol={ true } text="Nama Karyawan" isMandatory={ true } value={ user.employeeId } />
                                <div className="col-md-9">
                                    <select id="employee-name" className="form-control" name="employeeId" onChange={ e => onInputChange(e) } value={user.employeeId}>
                                        <option value="">Pilih</option>
                                        {employees.map(({id, name}) => {return(<option key={id} value={id}>{name}</option>)})}
                                    </select>
                                    <span><LinkButton to={ userPath.newEmployeePath } className="stretched-link" label="Tambah Karyawan Baru" /></span>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="user-name" isCol={ true } text="Username" isMandatory={ true } value={ user.username } />
                                <div className="col-md-9">
                                    <input 
                                        id="user-name"
                                        type="text" 
                                        className="form-control" 
                                        name="username"
                                        value={user.username}
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="password" isCol={ true } text="Password" isMandatory={ true } value={ user.password } />
                                <div className="col-md-9">
                                    <input 
                                        id="password"
                                        type="password" 
                                        className="form-control" 
                                        name="password"
                                        value={user.password}
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="role" isCol={ true } text="Role" isMandatory={ true } value={ user.roleId } />
                                <div className="col-md-9">
                                    <select id="role" className="form-control" name="roleId" onChange={ e => onInputChange(e) } value={ user.roleId }>
                                        <option value="">Pilih</option>
                                        {roles.map(({id, name}) => {return(<option key={id} value={id}>{name}</option>)})}
                                    </select>
                                    <span><LinkButton to={ userPath.newRolePath } className="stretched-link" label="Tambah Role Baru" /></span>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="is-active"
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            name="isActive"
                                            value={ user.isActive }
                                            checked={ user.isActive }
                                            onChange={ e => onActiveChange(e) } 
                                        />   
                                        <label htmlFor="is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset }/>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
