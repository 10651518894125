import React from 'react';
import { Route } from 'react-router-dom';
import CustomerList from './List';
import CustomerAction from './Action';
import customerPath from './config';

const CustomerRoutes = () => {
    return (
        <React.Fragment>
            <Route path={customerPath.basePath} exact component={CustomerList} />
            <Route path={customerPath.newPath} exact component={CustomerAction} />
            <Route path={customerPath.detailPath} exact component={CustomerAction} />
        </React.Fragment>
    );
};

export default CustomerRoutes;
