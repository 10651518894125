import apiPath from '../../../config/api';

const LossesPath = {
    title: "Toko Pupuk dan Pertanian - Modul Kerugian",
    basePath: "/pupuk-dan-pertanian/kerugian",
    newPath: "/pupuk-dan-pertanian/kerugian/baru",
    detailPath: "/pupuk-dan-pertanian/kerugian/opsi/:id",
    base: apiPath.retailLosses,
    gnsFind: apiPath.masterGnsFind,
    gnsNmFilter: apiPath.masterGnsNmFilter,
    gnsCodeFilter: apiPath.masterGnsCodeFilter,
    gns: apiPath.masterGns,
    unitFilter: apiPath.masterUnitFilter,
    unit: apiPath.masterUnit
}

export default LossesPath;
