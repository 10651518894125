import React from 'react';
import { Route } from 'react-router-dom';
import CashLoanList from './List';
import CashLoanAction from './Action';
import cashLoanPath from './config';

const CashLoanRoutes = () => {
    return (
        <React.Fragment>
            <Route path={cashLoanPath.basePath} exact component={CashLoanList} />
            <Route path={cashLoanPath.newPath} exact component={CashLoanAction} />
            <Route path={cashLoanPath.detailPath} exact component={CashLoanAction} />
        </React.Fragment>
    );
};

export default CashLoanRoutes;