import React from 'react';
import { Route } from 'react-router-dom';
import ApArHeaderList from './List';
import ApArHeaderAction from './Action';
import aparheaderPath from './config';

const ApArHeaderRoutes = () => {
    return (
        <React.Fragment>
            <Route path={aparheaderPath.basePath} exact component={ApArHeaderList} />
            <Route path={aparheaderPath.newPath} exact component={ApArHeaderAction} />
            <Route path={aparheaderPath.detailPath} exact component={ApArHeaderAction} />
        </React.Fragment>
    );
};

export default ApArHeaderRoutes;