import apiPath from '../../../config/api';

const CoaPath = {
    title: "Module Code Of Accounting", 
    basePath: "/master/coa",
    newPath: "/master/coa/baru",
    detailPath: "/master/coa/opsi/:id",
    baseApi: apiPath.masterCoa
}

export default CoaPath;
