import React, {useState, useEffect, useCallback} from 'react';
import { DateTime } from 'luxon';
import { formatDate } from 'react-day-picker/moment';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import Label from '../../../components/label';
import Note from '../../../components/note';
import DayPicker from '../../../components/day-picker';
import ReportButton from '../../../components/report-button';
import TextNumber from '../../../components/text-number';
import { getData, getFile } from '../../../helper/request-response';
import { getDateApiParam, downloadFile } from '../../../helper/util';
import LoaderType from '../../../components/loader-type';
import constant from '../../../config/constant';
import cashAuliaPath from './config';

const List = () => {

    const [isLoad, setIsLoad] = useState(true);

    const [rows, ,] = useGlobalState('rows');

    const [auliaCash, setPoCash] = useState([]);

    const [startDate, setStartDate] = useState(DateTime.local());

    const [endDate, setEndDate] = useState(DateTime.local());

    const onStartDateChange = day => {

        setStartDate(formatDate(day, constant.DEFAULT_DATE));
        setEndDate(formatDate(day, constant.DEFAULT_DATE));
    };

    const onEndDateChange = day => {

        setEndDate(formatDate(day, constant.DEFAULT_DATE));
    };

    const onSubmit = e => {

        e.preventDefault();

        const params = getFilterParams({ 'startDate': startDate, 'endDate': endDate });
        getAuliaCash(params);
    }

    const onReset = e => {

        e.preventDefault();

        setStartDate(DateTime.local());
        setEndDate(DateTime.local());
    }

    const onExport = e => {

        e.preventDefault();
        
        const params = getFilterParams({ 'startDate': getDateApiParam(startDate), 'endDate': getDateApiParam(endDate) });

        getFile(cashAuliaPath.excel, params).then(response => downloadFile(response));
    }

    const getFilterParams = useCallback((filter) => {

        const params = { 
            params: { 
                'date-from': _.get(filter, 'startDate'),
                'date-to': _.get(filter, 'endDate'), 
                'rows': rows
            } 
        };

        return params;

    }, [rows]);

    const getAuliaCash = (params) => {

        setIsLoad(true);

        getData(cashAuliaPath.baseApi, params).then(result => {

            if (!_.isEmpty(result)) setPoCash(result[0]);

            setIsLoad(false);
            
        });

    }

    useEffect(() => {

        const params = getFilterParams({ 'startDate': getDateApiParam(DateTime.local()), 'endDate': getDateApiParam(DateTime.local()) });
        getAuliaCash(params);

     }, [getFilterParams]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{cashAuliaPath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } > 
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="startDate" text="Tanggal Dari" />
                                    <DayPicker id="startDate" name="startDate" onDayChange={ onStartDateChange } valueDate={ startDate } />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="endDate" text="Tanggal Ke" />
                                    <DayPicker id="endDate" name="endDate" onDayChange={ onEndDateChange } valueDate={ endDate } />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Data Transfer" entity="Transfer" />
                    </div>
                </div>
                {
                    isLoad && <LoaderType />
                }
                {
                    !isLoad && auliaCash.length > 0 &&
                    <>
                        <br />
                        <div className="col-lg-12 mb-4">
                            <div className="row">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Tanggal</th>
                                            <th>Transaksi</th>
                                            <th>No Faktur</th>
                                            <th>Tipe</th>
                                            <th>Pelanggan</th>
                                            <th>Deskripsi</th>
                                            <th>Kas Masuk</th>
                                            <th>Kas Keluar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            auliaCash.map((aulia, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <th scope="row">{ index + 1 }</th>
                                                        <td>{ DateTime.fromISO(aulia.trxDate).toFormat('dd-LLLL-yyyy') }</td>
                                                        <td>{ aulia.trxType }</td>
                                                        <td>{ aulia.invNo }</td>
                                                        <td>{ aulia.custType }</td>
                                                        <td>{ aulia.custName }</td>
                                                        <td>{ aulia.desc }</td>
                                                        <td><TextNumber value={ aulia.cashIn } /></td>
                                                        <td><TextNumber value={ aulia.cashOut } /></td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
