import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DateTime, Settings } from 'luxon';
import { formatDate } from 'react-day-picker/moment';
import { Modal } from 'react-responsive-modal';
import _ from 'lodash';
import { useGlobalState } from 'state-pool';

import Label from '../../../components/label';
import Note from '../../../components/note';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import TextNumber from '../../../components/text-number';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import ClickButton from '../../../components/click-button';
import LoaderType from '../../../components/loader-type';
import { getData, postData, deleteData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import salesPath from './config';

const List = () => {

    Settings.defaultLocale = 'id';

    const [rows, ,] = useGlobalState('rows');

    const rowsRef = useRef(rows);

    const filterEntity = {
        'invoiceNo': '',
        'trxDate': undefined,
        'trxDateFrom': undefined,
        'customerId': 0,
        'customerName': '',
        'trxStatus':'',
        'rows': rows
    }

    const [modalIsOpen, setIsOpen] = useState(false);

    const [isLoad, setIsLoad] = useState(true);

    const [sales, setSales] = useState([]);

    const [purchases, setPurchases] = useState([]);

    const [selectedSales, setSelectedSales] = useState({});

    const [selectedCust, setSelectedCust] = useState(constant.DEFAULT_SELECTED);

    const [salesFilter, setSalesFilter] = useState(filterEntity);

    /**
     * Get detail route path
     * @param {*} id 
     * @returns 
     */
    const getDetailPath = (id) => salesPath.detailPath.replace(":id", id);

    /**
     * Get filter data
     */
     const getParams = useCallback((filter) => {

        const params = { 
            'params': { 
                'invoice-no': _.get(filter, 'invoiceNo'), // filter parameter
                'trx-date': _.get(filter, 'trxDate'), // filter parameter
                'trx-date-from': _.get(filter, 'trxDateFrom'), // filter parameter
                'customer-id': _.get(filter, 'customerId'), // filter parameter
                'trx-status': _.get(filter, 'trxStatus'), // filter parameter
                'rows': _.get(filter, 'rows'), // filter parameter
                'id': _.get(filter, 'id'), // delete parameter
                'purchases': _.get(filter, 'purchases'), // delete parameter
                'recordType': _.get(filter, 'recordType') // delete parameter
            } 
        };

        return params;

    }, []);

    /**
     * Model close event
     * @returns 
     */
    const onCloseModal = () => setIsOpen(false);

    /**
     * Input change event
     * @param {*} e 
     */
    const onInputChange = e => {

        const { name, value } = e.target;

        setSalesFilter({ 
            ...salesFilter,
            [name]: value
        });
    };

    /**
     * Autocomplete customer change event
     * @param {*} opt 
     */
    const onCustChange = opt => {

        if (opt.id) {

            setSalesFilter(data => ({
                ...data,
                customerId: opt.id,
                customerName: opt.name
            }));

            setSelectedCust(cust => ({
                ...cust,
                value: opt.id,
                label: opt.name
            }));

        } else {

            setSalesFilter(data => ({
                ...data,
                customerName: opt.label
            }));

            setSelectedCust(cust => ({
                ...cust,
                value: 0,
                label: opt.label
            }));

        }
    }

    /**
     * End date change event
     * @param {*} day 
     */
    const onDayChange = day => {

        setSalesFilter({ 
            ...salesFilter,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    };

    /**
     * Start date change event
     * @param {*} day 
     */
    const onDayFromChange = day => {

        setSalesFilter({ 
            ...salesFilter,
            trxDate: formatDate(day, constant.DEFAULT_DATE),
            trxDateFrom: formatDate(day, constant.DEFAULT_DATE)
        });
    };

    /**
     * On delete event
     * @param {*} e 
     */
    const onDelete = e => {

        e.preventDefault();

        const idx = parseInt(e.currentTarget.value);

        const params = getParams({ 'id': sales[idx].id });

        getData(salesPath.purchasesFind, params).then(result => {

            setPurchases(result[0]);

            setSelectedSales(sales[idx]);

            setIsOpen(true);

        });

    }

    /**
     * On confirm delete
     * @param {*} e 
     */
    const onConfirmDelete = async(e) => {

        e.preventDefault();

        const params = getParams({ 'invoiceNo': selectedSales.invoiceNo, 'purchases': purchases, 'recordType': 'DELETE' });

        const purchaseHistories = await postData(salesPath.purchaseHistory, params);

        if (purchaseHistories) {

            const salesHistories = await postData(salesPath.salesHistory, params);

            if (salesHistories) {

                const distinctPurchases = Array.from(new Set(purchases.map(s => s.purchaseInvNo)));

                const deleteParams = getParams({ 'invoiceNo': selectedSales.invoiceNo, 'purchases': distinctPurchases })

                const deletePurchases = await deleteData(salesPath.purchaseBulk, deleteParams);

                if (!_.isEmpty(deletePurchases)) {

                    const deleteSales = await deleteData(salesPath.base, deleteParams);

                    if (!_.isEmpty(deleteSales)) {

                        const resetParams = getParams({ 'rows': salesFilter.rows });
                        getSales(resetParams);

                        setIsOpen(false);
                        
                    }

                }

            }

        }

    }

    /**
     * Get palmoil sales data from API
     * @param {*} params 
     */
    const getSales = params => {

        setIsLoad(true);

        getData(salesPath.base, params).then(result => {

            setSales(result);

            setIsLoad(false);

        });
    }

    /**
     * Submit event
     * @param {*} e 
     */
    const onSubmit = (e) => {

        e.preventDefault();

        const params = getParams({ 'invoiceNo': salesFilter.invoiceNo, 'trxDate': salesFilter.trxDate, 'trxDateFrom': salesFilter.trxDateFrom, 'customerId': salesFilter.customerId, 'trxStatus': salesFilter.trxStatus, 'rows': salesFilter.rows });
        getSales(params);

    }

    /**
     * On reset event
     * @param {*} e 
     */
    const onReset = (e) => {

        e.preventDefault();

        setSalesFilter(filterEntity);

        setSelectedCust(constant.DEFAULT_SELECTED);

        const params = getParams({ 'rows': salesFilter.rows });
        getSales(params);

    }

    /**
     * React UseEffect
     */
    useEffect(() => { 

        const params = getParams({ 'rows': rowsRef.current });
        getSales(params);
    
    }, [getParams]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ salesPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <Modal open={ modalIsOpen } onClose={ onCloseModal } center>
                        <div className="modal-header">
                            <h5 className="modal-title">KONFIRMASI PENGHAPUSAN DATA</h5>
                        </div>

                        <div className="modal-body">
                            <table className="table-borderless">
                                <tbody>
                                    <tr>
                                        <td>Faktur Penjualan</td>
                                        <td>:</td>
                                        <td>{ selectedSales.invoiceNo }</td>
                                    </tr>
                                    <tr>
                                        <td>PKS</td>
                                        <td>:</td>
                                        <td>{ selectedSales.customerName }</td>
                                    </tr>
                                    <tr>
                                        <td>Kendaraan</td>
                                        <td>:</td>
                                        <td>{ selectedSales.vehicleCode }</td>
                                    </tr>
                                    <tr>
                                        <td>Nama Supir</td>
                                        <td>:</td>
                                        <td>{ selectedSales.employeeName }</td>
                                    </tr>
                                    <tr>
                                        <td>Pembelian (Kg)</td>
                                        <td>:</td>
                                        <td><TextNumber value={ selectedSales.totalKgPurchase } /></td>
                                    </tr>
                                    <tr>
                                        <td>Total Pembelian (Rp)</td>
                                        <td>:</td>
                                        <td><TextNumber value={ selectedSales.totalPurchase } /></td>
                                    </tr>
                                    <tr>
                                        <td>Penjualan (Kg)</td>
                                        <td>:</td>
                                        <td><TextNumber value={ selectedSales.totalKgSales } /></td>
                                    </tr>
                                    <tr>
                                        <td>Total Penjualan (Rp)</td>
                                        <td>:</td>
                                        <td><TextNumber value={ selectedSales.totalSales } /></td>
                                    </tr>
                                </tbody>
                            </table>

                            {
                                purchases.length > 0 &&
                                <>
                                    <br />
                                    <h5 className="text-center"> Data Pembelian</h5>

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>No Faktur</th>
                                                <th>Tanggal</th>
                                                <th>Nama</th>
                                                <th>Jalur</th>
                                                <th>Barang/Jasa</th>
                                                <th>Qty (Kg)</th>
                                                <th>Total (Rp)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                purchases.map((purchase, index) => {

                                                    if (purchase.amount > 1) {

                                                        return(
                                                            <tr key={index}>
                                                                <td>{ purchase.purchaseInvNo }</td>
                                                                <td>{ DateTime.fromISO(purchase.purchaseDate).toFormat('dd-LLLL-yyyy') }</td>
                                                                <td>{ purchase.name }</td>
                                                                <td>{ purchase.path }</td>
                                                                <td>{ purchase.gnsName }</td>
                                                                <td><TextNumber value={ purchase.qty } /></td>
                                                                <td><TextNumber value={ purchase.amount } /></td>
                                                            </tr>
                                                        );

                                                    } else {

                                                        return '';
                                                        
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </>
                            }
                            
                            
                        </div>

                        <div className="modal-footer">
                            <ClickButton onClick={ onConfirmDelete } label="Hapus" className="btn btn-primary" />
                        </div>
                    </Modal>
                </div>

                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="inv-no" text="No Faktur" />
                                    <input 
                                        id="inv-no" 
                                        type="text" 
                                        className="form-control"
                                        name="invoiceNo"
                                        value={ salesFilter.invoiceNo }
                                        onChange={ e => onInputChange(e) }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="trx-status" text="Status Transaksi" />
                                    <select id="trx-status" className="form-control" name="trxStatus" onChange={ e => onInputChange(e) } value={salesFilter.trxStatus}>
                                        <option value="">Pilih</option>
                                        <option value="1">Commit</option>
                                        <option value="0">Draft</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="pabrik" text="Pabrik" />
                                    <Select
                                        id="pks"
                                        paramFilter="name"
                                        api={ salesPath.custFind }
                                        entityId="5"
                                        onChange={ onCustChange }
                                        value={ selectedCust }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal Penjualan Dari" />
                                    <DayPicker id="trx-date" name="trxDateFrom" onDayChange={ onDayFromChange } valueDate={ salesFilter.trxDateFrom } />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal Penjualan Ke" />
                                    <DayPicker id="trx-date" name="trxDate" onDayChange={ onDayChange } valueDate={ salesFilter.trxDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                
                            </div>
                            
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <FilterButton to={ salesPath.newPath } newLabel="Tambah Data Penjualan" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Data Penjualan" entity="Penjualan" />
                    </div>
                </div>
                
                <br />
                
                { isLoad && <LoaderType /> }
                {
                    !isLoad && sales.length > 0 &&
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>No Faktur</th>
                                            <th>Kendaraan</th>
                                            <th>Tanggal</th>
                                            <th>Nama Pabrik</th>
                                            <th>Beli (Rp)</th>
                                            <th>Jual (Rp)</th>
                                            <th>Status</th>
                                            <th>Opsi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sales.map((item, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <th scope="row">{ index + 1 }</th>
                                                        <td>{ item.invoiceNo }</td>
                                                        <td>{ item.vehicleCode }</td>
                                                        <td>{ DateTime.fromISO(item.trxDate).toFormat('dd-LLLL-yyyy') }</td>
                                                        <td>{ item.customerName }</td>
                                                        <td><TextNumber value={ item.totalPurchase } /></td>
                                                        <td><TextNumber value={ item.totalSales } /></td>
                                                        <td>{ item.isCommit ? "Commit" : "Draft" }</td>
                                                        <td className="btn-group">
                                                            <LinkButton to={ {pathname: getDetailPath(item.id), state: item} } label="Detail" />
                                                            { parseInt(item.isCommit) === 1 && <LinkButton to={ {pathname: getDetailPath(item.id), state: item, isEdit: true} } className="btn btn-primary" label="Ubah" /> }
                                                            <ClickButton onClick={ onDelete } label="Hapus" className="btn btn-danger" value={ index } />
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </React.Fragment>
    );
};

export default List;
