import React from 'react';
import { Switch, Route } from 'react-router-dom';
import MenuList from './List';
import MenuAction from './Action';
import SubMenuAction from './SubAction';
import menuPath from './config';

const MenuRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={menuPath.basePath} exact component={MenuList} />
                <Route path={menuPath.newPath} exact component={MenuAction} />
                <Route path={menuPath.detailPath} exact component={MenuAction} />
                <Route path={menuPath.newSubMenuPath} exact component={SubMenuAction} />
                <Route path={menuPath.detailSubMenuPath} exact component={SubMenuAction} />
            </Switch>
        </React.Fragment>
    );
};

export default MenuRoutes;
