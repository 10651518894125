import apiPath from '../../../config/api';

const BankTrxPath = {
    title: "Modul Transaksi Bank",
    basePath: "/brilink/transaksi",
    newPath: "/brilink/transaksi/baru",
    detailPath: "/brilink/transaksi/opsi/:id",
    printPath: "/brilink/transaksi/print",
    newType: "/brilink/jenis-transaksi/baru",
    newCustomer: "/master/pelanggan/baru",
    baseApi: apiPath.masterBrilinkTrx,
    baseApiCoa: apiPath.masterCoa,
    apArFilterCustId: apiPath.accountApArCustIdFilter,
    brilinkTypeNameFilterApi: apiPath.masterBrilinkTypeNameFilter,
    custFind: apiPath.masterCustFind
}

export default BankTrxPath;
