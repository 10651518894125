import React, {useState, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import { DateTime } from 'luxon';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import InputNumeral from '../../../components/input-numeral';
import { postData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import { getPoBalance } from '../../../helper/cash-balance';
import constant from '../../../config/constant';
import miscExpPath from './config';

const Action = () => {
    
    const data = useLocation();
    
    const history = useHistory();

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isNew, setIsNew] = useState(true);

    const [isResetable, setIsResetable] = useState(true);
    
    const [titleInfo, setTitleInfo] = useState("");

    const [selectedCoaCode, setSelectedCoaCode] = useState(constant.DEFAULT_SELECTED);

    const [, , setPoBalance] = useGlobalState('poBalance');

    const [miscExp, setMiscExp] = useState(
        {
            id: 0,
            apArId: 339, //Aulia Tani ID
            trxDate: DateTime.local(),
            invoiceNo: `ME${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
            invoiceLineNo: null,
            description: "",
            amount:0,
            coaAccountNo:"",
            masterCustomerId: 380 //Aulia Tani Customer ID
        });

    const onInputChange = e => {

        const { name, value } = e.target;
        
        const miscExpData = {
            ...miscExp,
            [name]: value
        } 

        setMiscExp(miscExpData);
        
    }

    const onAmountChange = values => {

        const { value } = values;
        const val = parseFloat(value);

        setMiscExp(data => ({
            ...data,
            amount: val
        }));
    }

    const onSelectChangeCoaCode = async (opt) => {

        setMiscExp(data => ({ 
            ...data,
            coaAccountNo:opt.accountNo
        }));

        setSelectedCoaCode(code => ({
            ...code,
            label: opt.label
        }));

    }

    const onDayChange = day => {

        setMiscExp({ 
            ...miscExp,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    }

    const onReset = e => {

        e.preventDefault();

        setMiscExp(data => ({ 
            ...data,
                id: 0,
                apArId: 339, //Aulia Tani ID
                trxDate: DateTime.local(),
                invoiceNo: `ME${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
                invoiceLineNo: null,
                description: "",
                amount:0,
                coaAccountNo:"",
                masterCustomerId: 380 //Aulia Tani Customer ID
        }));

        setSelectedCoaCode(code => ({
            ...code,
            label: ""
        }));
    }

    const onSubmit = (e) => {

        e.preventDefault();

        setPoBalance(0);

        const postBankTrx = async () => {

            const result = await postData(miscExpPath.baseApi, miscExp);

            if (!_.isEmpty(result)) {

                onSuccess(`Transaksi ${result.invoice_no} berhasil di tambahkan`);

                history.goBack();

                getPoBalance();

            }
        };

        if (miscExp.id === 0) postBankTrx();

    }

    useEffect(() => {

        function setMiscExpState() {

            if (data.state) {

                setMiscExp(data.state)

                setTitleInfo(`Detail: ${data.state.invoiceNo}`);

                setSelectedCoaCode(code => ({
                    ...code,
                    label: data.state.coaLabel
                }));

                setIsResetable(false);

                setIsNew(false);

            } else {

                setTitleInfo("Tambah Data Baru");

            }
        }

        setMiscExpState();

    }, [data]);

    useEffect(() => {

    if (miscExp.coaAccountNo && miscExp.description && (miscExp.amount > 0) ) setIsDisabledSave(false);

    
    if (!miscExp.coaAccountNo || !miscExp.description || !miscExp.amount || ( miscExp.amount <= 0) ) setIsDisabledSave(true);

    }, [miscExp.coaAccountNo, miscExp.description, miscExp.amount]);

    return (

        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{miscExpPath.title} | {titleInfo}</h3>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="miscExp-number" text="No Pembayaran"/>
                                <input 
                                id="miscExp-number"
                                type="text" 
                                className="form-control" 
                                name="invoiceNo"
                                value={miscExp.invoiceNo}
                                onChange={ e => onInputChange(e) } 
                                readOnly
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="trx-date" text="Tanggal"/>
                                <div>
                                    <DayPicker id="trx-date" name="trxDate" valueDate={miscExp.trxDate} onDayChange={onDayChange} />
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                            <Label htmlFor="coa-name" text="Jenis Biaya" isMandatory={ true } value={ miscExp.coaAccountNo } />
                                <Select
                                    id="coa-name"
                                    paramFilter="name"
                                    api={ miscExpPath.coaMiscExpFilterApi }
                                    onChange={ onSelectChangeCoaCode }
                                    value={ selectedCoaCode } 
                                />
                            </div>
                        </div>

                        

                    </div>
                    <div className="col-md-6">
                    <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="miscExp-description" text="Deskripsi"  isMandatory={ true } value={ miscExp.description }/>
                                <input 
                                id="miscExp-description"
                                type="text" 
                                className="form-control" 
                                name="description"
                                value={miscExp.description}
                                onChange={ e => onInputChange(e) } 
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                           
                            <div className="col-md-12">
                            <Label htmlFor="miscExp-amount" text="Nominal" isMandatory={ true } value={ miscExp.amount } />
                            <InputNumeral 
                                id="amount"
                                value={ miscExp.amount }
                                onValueChange={ onAmountChange } 
                            />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <div className="col-md-12">
                            <Label />
                                <ActionButton 
                                    isSave={ isNew }
                                    isDisabledSave={ isDisabledSave } 
                                    isReset={ isResetable } 
                                    onReset={ onReset } />
                            </div>
                        </div>

                        <Note isAction={ true } />
                    
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Action;
