import React from 'react';
import { Switch, Route } from 'react-router-dom';
import StockSalesList from './List';
import stockSalesPath from './config';

const StockSalesRoutes = () => {
    return (
        <Switch>
            <Route path={ stockSalesPath.basePath } exact component={ StockSalesList } />
        </Switch>
    );
};

export default StockSalesRoutes;