import React from 'react';
import { Route } from 'react-router-dom';
import SaveCredList from './List';
import SaveCredAction from './Action';
import saveCredPath from './config';

const SaveCredRoutes = () => {
    return (
        <React.Fragment>
            <Route path={saveCredPath.basePath} exact component={SaveCredList} />
            <Route path={saveCredPath.newPath} exact component={SaveCredAction} />
            <Route path={saveCredPath.detailPath} exact component={SaveCredAction} />
        </React.Fragment>
    );
};

export default SaveCredRoutes;