import React, { useEffect, useState } from 'react';
import 'moment/locale/id';
import moment from 'moment';
import { useGlobalState } from 'state-pool';

import LoaderType from '../../components/loader-type';
import CashBalance from '../../components/cash-balance';

const Balance = () => {

    const [isShowPettyCash, ,] = useGlobalState('isShowPettyCash');
    const [pettyCashLabel, ,] = useGlobalState('pettyCashLabel');
    const [auliaBalance, ,] = useGlobalState('auliaBalance');
    const [isShowRetailCash, ,] = useGlobalState('isShowRetailCash');
    const [retailCashLabel, ,] = useGlobalState('retailCashLabel');
    const [retailBalance, ,] = useGlobalState('retailBalance');
    const [isShowTBSCash, ,] = useGlobalState('isShowTBSCash');
    const [TBSCashLabel, ,] = useGlobalState('TBSCashLabel');
    const [poBalance, ,] = useGlobalState('poBalance');
    const [isShowPBDOCash, ,] = useGlobalState('isShowPBDOCash');
    const [PBDOCashLabel, ,] = useGlobalState('PBDOCashLabel');
    const [pbdoBalance, ,] = useGlobalState('pbdoBalance');
    const [isShowBankCash, ,] = useGlobalState('isShowBankCash');
    const [bankCashLabel, ,] = useGlobalState('bankCashLabel');
    const [bankBalance, ,] = useGlobalState('bankBalance');
    const [currentDate, setCurrentDate] = useState(undefined);

    useEffect(() => {

        const interval = setInterval(() => {

            setCurrentDate(moment().format('ddd DD MMM YYYY hh:mm:ss'));
            
        }, 1000);

        return () => clearInterval(interval);

      }, []);

    return(

        <div className="col-lg-12 mb-2">
            <div className="card">
                <div className="card-header" style={{ paddingTop: 10, paddingBottom: 10 }}>
                    { !currentDate && <h2 className="h6 text-uppercase mb-0">Informasi Saldo </h2> }
                    { currentDate && <h2 className="h6 text-uppercase mb-0">Saldo Per { currentDate } </h2> }
                </div>
                <div className="card-body" style={{ paddingBottom: 10 }}>
                    { !currentDate && <LoaderType /> }
                    {
                        currentDate &&
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="d-flex flex-wrap justify-content-between align-items-start align-items-sm-center mb-2 flex-column flex-sm-row">
                                    { isShowPettyCash === "1" && <CashBalance balance={ auliaBalance } label={ pettyCashLabel } /> }
                                    { isShowPBDOCash === "1" && <CashBalance balance={ pbdoBalance } label={ PBDOCashLabel } /> }
                                    { isShowTBSCash === "1" && <CashBalance balance={ poBalance } label={ TBSCashLabel } /> }
                                    { isShowRetailCash === "1" && <CashBalance balance={ retailBalance } label={ retailCashLabel } /> }
                                    { isShowBankCash === "1" && <CashBalance balance={ bankBalance } label={ bankCashLabel } /> }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Balance;
