import axios from 'axios';

axios.defaults.baseURL= process.env.REACT_APP_BASE_API;
axios.defaults.headers.get['Accept'] = 'application/json';

axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.defaults.headers.put['Accept'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

export default axios;
