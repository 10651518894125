import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import rolePath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();

    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [role, setRole] = useState({
        id: 0,
        name: "",
        isActive: 1
    });

    const onReset = e => {

        e.preventDefault();

        setRole(role => ({ 
            ...role,
            name: "",
            isActive: 1
        }));
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setRole(role => ({ 
            ...role,
            [name]: value
        }));
    };

    const onActiveChange = e => {
        
        setRole(role => ({ 
            ...role,
            isActive: role.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postRole = async () => {

            const result = await postData(rolePath.baseApi, role);
            
            if (Object.keys(result).length) {

                onSuccess(`Role ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putRole = async () => {

            const result = await putData(`${rolePath.baseApi}/${role.id}`, role);

            if (Object.keys(result).length) {
                
                onSuccess(`Role ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        }

        if (role.id === 0 && role.name) postRole();
        else putRole();

    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;

            if (state) {
                
                setRole(state);
                
                setTitleInfo(`Detail: ${state.name}`);

                setIsResetable(false);
    
            } else {
    
                setTitleInfo("Tambah Role Baru");
    
            }
        }
    
        init();

    }, []);

    useEffect(() => {

        if (role.name) setIsDisabledSave(false);

    }, [role.name]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{rolePath.title} | {titleInfo}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="role-name" isCol={ true } text="Nama Role" isMandatory={ true } value={ role.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="role-name"
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        value={role.name}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-4">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="is-active"
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            name="isActive"
                                            value={ role.isActive }
                                            checked={ role.isActive }
                                            onChange={ e => onActiveChange(e) } />   
                                        <label htmlFor="is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset }/>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Action;
