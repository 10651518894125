import React from 'react';
import { Link } from 'react-router-dom';

import constant from '../config/constant';

const Filter = (props) => {
    
    return(
        <div className="form-group row">
            <div className="col-md-2"></div>
            <div className="col-md-4">
                <div className="d-flex">
                    <button type="submit" className="p-2 flex-fill btn btn-info">{constant.SEARCH}</button>
                    &nbsp;
                    <Link to={props.path} className="p-2 flex-fill btn btn-primary">{props.label}</Link>
                    &nbsp;
                    <button onClick= { e => props.onButtonClick(e) } className="p-2 flex-fill btn btn-secondary">{constant.RESET}</button>
                </div>
            </div>
        </div>
    );

}

export default Filter;
