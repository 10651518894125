import React, {useState, useEffect} from 'react';
import { formatDate } from 'react-day-picker/moment';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import TextNumber from '../../../components/text-number';
import DayPicker from '../../../components/day-picker';
import LinkButton from '../../../components/link-button';
import {getData} from '../../../helper/request-response';
import constant from '../../../config/constant';
import miscExpPath from './config';

const List = () => {

    const [miscExps, setMiscExps] = useState([]);

    const getDetailPath = (invoiceNo) => miscExpPath.detailPath.replace(":id", invoiceNo);

    const [miscExpFilter, setFilterMiscExp] = useState({
        trxDate: "",
        description: ""
    });

    const onInputChange = e => {

        setFilterMiscExp({ 
            ...miscExpFilter,
            [e.target.name]: e.target.value
        });
    };

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                trxDate: miscExpFilter.trxDate,
                description: miscExpFilter.description,
            }
        };

        getMiscExps(params);

    };

    const getMiscExps = async(params) => {

        const result = await getData(miscExpPath.baseApi, params);
            
        setMiscExps(result);

    }

    const onDayChange = day => {

        setFilterMiscExp(trx => ({ 
            ...trx,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));
    }

    const onReset = e => {

        e.preventDefault();

        setFilterMiscExp(trx => ({ 
            ...miscExpFilter,
            trxDate: "",
            description: ""
        }));

        getMiscExps();

    }

    useEffect(() => { 

        getMiscExps();

     }, []);
    

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{miscExpPath.title}</h3>
            </div>
            <div className="card-body">
            <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <Label htmlFor="trx-date" isCol={ true } text="Tanggal Pembelian" />
                                <div className="col-md-9">
                                    <DayPicker id="trx-date" name="trxDate" onDayChange={ onDayChange } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="miscExp-description" isCol={ true } text="Deskripsi" />
                                <div className="col-md-9">
                                    <input 
                                        id="miscExp-description" 
                                        type="text" 
                                        className="form-control"
                                        name="description"
                                        value={ miscExpFilter.description }
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ miscExpPath.newPath } newLabel="Tambah Pembayaran Baru" onClick={ onReset } />
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Transaksi Baru" entity="Transaksi" />
                    </div>
                </div>

                <div className="col-lg-12 mb-4">
                    <div className="row">
                        <table className="table table-striped table-hover card-text">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Tanggal</th>
                                    <th>Nama Pelanggan</th>
                                    <th>Deskripsi</th>
                                    <th>Total</th>
                                    <th>Opsi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    miscExps.map((miscExp, index) => {
                                        return(
                                            <tr key={index}>
                                                <th scope="row">{ index + 1 }</th>
                                                <td>{ miscExp.invoiceNo }</td>
                                                <td>{ `${formatDate(miscExp.trxDate, 'LL', constant.LOCALE)}` }</td>
                                                <td>{ miscExp.description }</td>
                                                <td><TextNumber value={ miscExp.amount } /></td>
                                                <td><LinkButton to={ {pathname: getDetailPath(miscExp.invoiceNo), state: miscExp} } /></td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
