import apiPath from '../../../config/api';

const CashTrfPath = {
    title: "Transfer Saldo Kas",
    basePath: "/kas/transfer-kas",
    newPath: "/kas/transfer-kas/baru",
    detailPath: "/kas/transfer-kas/opsi/:id",
    baseApi: apiPath.cashTrf,
    cashCoa: apiPath.masterCoaCash
}

export default CashTrfPath;
