import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HarvestList from './List';
import harvestPath from './config';

const HarvestRoute = () => {
    return (
        <Switch>
            <Route path={ harvestPath.basePath } exact component={ HarvestList } />
        </Switch>
    );
};

export default HarvestRoute;
