import React from 'react';

import 'moment/locale/id';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import constant from '../config/constant';

const DayPicker = (props) => {

    return(
        <DayPickerInput 
            inputProps={{ 
                className: 'form-control',
                id: props.id,
                name: props.name
            }}
            formatDate={formatDate}
            parseDate={parseDate}
            format="LL"
            placeholder={`${formatDate(new Date(), 'LL', constant.LOCALE)}`}
            dayPickerProps={{
                locale: constant.LOCALE,
                localeUtils: MomentLocaleUtils
            }}
            value= {`${formatDate(props.valueDate, 'LL', constant.LOCALE)}`}
            onDayChange={day => props.onDayChange(day)}
        />
    );
}

export default DayPicker;