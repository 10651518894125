import React from 'react';
import {Switch, Route} from 'react-router-dom';
import StockList from './List';
import StockGenerate from './Generate';
import StockAction from './Action';
import stockPath from './config';

const SalesRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={stockPath.basePath} exact component={StockList} />
                <Route path={stockPath.newPath} exact component={StockGenerate} />
                <Route path={stockPath.detailPath} exact component={StockAction} />
            </Switch>
        </React.Fragment>
    );
};

export default SalesRoutes;
