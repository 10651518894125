import React, { useState, useEffect } from 'react';
import { DateTime, Settings } from 'luxon';
import { formatDate } from 'react-day-picker/moment';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import TextNumber from '../../../components/text-number';
import { getData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import lossesPath from './config';

const List = () => {

    Settings.defaultLocale = 'id';

    const [selectedGns, setSelectedGns] = useState({ value: '', label: ''});

    const [losses, setLosses] = useState([]);

    const lossesFilterEntity = {

        invoiceNo: "",
        trxDate: null,
        goodsId: 0,
        goodsName: ""
    }

    const [lossesFilter, setLossesFilter] = useState(lossesFilterEntity);

    const getDetailPath = (id) => lossesPath.detailPath.replace(":id", id);

    const onInputChange = e => {

        const { name, value } = e.target;

        setLossesFilter(filter => ({ 
            ...filter,
            [name]: value
        }));
    };

    const onGnsChange = (opt) => {

        if (opt.id) {

            setLossesFilter(filter => ({
                ...filter,
                goodsId: opt.id,
                goodsName: opt.name
            }));

            setSelectedGns(gns => ({
                ...gns,
                value: opt.id,
                label: opt.name
            }));

        } else {

            setLossesFilter(filter => ({
                ...filter,
                goodsName: opt.label
            }));

            setSelectedGns(gns => ({
                ...gns,
                value: '',
                label: opt.label
            }));

        }
    }

    const onDayChange = day => {

        setLossesFilter(filter => ({ 
            ...filter,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const params = { 
            params: { 
                'invoice-no': lossesFilter.invoiceNo, 
                'trx-date': lossesFilter.trxDate, 
                'goods-and-services-id': lossesFilter.customerId 
            } 
        };

        getLosses(params).then(result => {

            setLosses(result);

        });

    }

    const getLosses = async(params) => {

        const result = await getData(lossesPath.base, params);

        return result;

    }

    const onReset = (e) => {

        e.preventDefault();

        setLossesFilter(lossesFilterEntity);

        setSelectedGns(gns => ({
            ...gns,
            value: '',
            label: ''
        }));

        getLosses().then(result => {

            setLosses(result);

        });

    }

    useEffect(() => { 
        
        getLosses().then(result => {

            setLosses(result);

        });
    
    }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ lossesPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="inv-no" isCol={ true } text="No Faktur" />
                                <div className="col-md-9">
                                    <input 
                                        id="inv-no" 
                                        type="text" 
                                        className="form-control"
                                        name="accountNo"
                                        value={ lossesFilter.invoiceNo }
                                        onChange={ e => onInputChange(e) }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="gns" isCol={ true } text="Barang dan Jasa" />
                                <div className="col-md-9">
                                    <Select
                                        id="gns"
                                        api={ lossesPath.gnsFind }
                                        value={ selectedGns } 
                                        entityId="2"
                                        onChange={ onGnsChange }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Tanggal Kerugian" />
                                <div className="col-md-9">
                                    <DayPicker id="trx-date" name="trx-date" onDayChange={ onDayChange } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ lossesPath.newPath } newLabel="Tambah Data Kerugian" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Data Kerugian Baru" entity="Data Kerugian" />
                    </div>
                </div>

                <br />

                <div className="row">
                {
                    losses.length > 0 &&
                    <div className="col-lg-12 mb-4">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Referensi</th>
                                        <th>Tanggal</th>
                                        <th>Total</th>
                                        <th>Opsi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        losses.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ item.invoiceNo }</td>
                                                    <td>{ DateTime.fromISO(item.trxDate).toFormat('dd-LLLL-yyyy') }</td>
                                                    <td><TextNumber value={ item.totalLosses } /></td>
                                                    <td><LinkButton to={ {pathname: getDetailPath(item.id), state: item} } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
