import { store } from 'state-pool';
import { DateTime } from 'luxon';
import _ from 'lodash';

import api from '../config/api'
import { getData } from './request-response';
import { getDateApiParam } from './util';

export function getRetailBalance() {

    getData(`${api.cashRetail}/balance`, { params: { 'current-date': getDateApiParam(DateTime.local()) }}).then(result => {

        const retailBalance = _.get(result[0][0], 'retailBalance');
        store.setState('retailBalance', retailBalance);

    });

}

export function getAuliaBalance() {

    getData(`${api.cashAulia}/balance`).then(result => {

        const auliaBalance = _.get(result[0][0], 'auliaBalance');
        store.setState('auliaBalance', auliaBalance);

    });

}

export function getPoBalance() {

    getData(`${api.cashPo}/balance`, { params: { 'current-date': getDateApiParam(DateTime.local()) }}).then(result => {

        const poBalance = _.get(result[0][0], 'poBalance');
        store.setState('poBalance', poBalance);

    });

}

export function getPbdoBalance() {

    getData(`${api.cashPo}/pbdo/balance`, { params: { 'current-date': getDateApiParam(DateTime.local()) }}).then(result => {

        const pbdoBalance = _.get(result[0][0], 'pbdoBalance');
        store.setState('pbdoBalance', pbdoBalance);

    });

}

export function getBankBalance() {

    getData(`${api.cashBank}/balance`, { params: { 'current-date': getDateApiParam(DateTime.local()) }}).then(result => {

        const bankBalance = _.get(result[0][0], 'bankBalance');;
        store.setState('bankBalance', bankBalance);

    });

}
