import React from 'react';
import { useHistory } from 'react-router-dom';

import constant from '../config/constant';

const Create = (props) => {

    const history = useHistory();

    const onButtonClick = e => {
        
        e.preventDefault();

        history.goBack();
    }

    return(
        
        <div className="form-group row">
            <div className="col-md-2"></div>
            <div className="col-md-4">
                <div className="d-flex">
                    
                    <button type="submit" className="p-2 flex-fill btn btn-success">{constant.SAVE}</button>
                    &nbsp;
                    <button onClick= { e => onButtonClick(e) } className="p-2 flex-fill btn btn-info">{constant.BACK}</button>

                </div>
                
                
            </div>
        </div>
    );

}

export default Create;
