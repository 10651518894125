import React from 'react';
import { Route } from 'react-router-dom';
import UserList from './List';
import UserAction from './Action';
import userPath from './config';

const UserRoutes = () => {
    return (
        <React.Fragment>
            <Route path={userPath.basePath} exact component={UserList} />
            <Route path={userPath.newPath} exact component={UserAction} />
            <Route path={userPath.detailPath} exact component={UserAction} />
        </React.Fragment>
    );
};

export default UserRoutes;