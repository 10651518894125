import React from 'react';
import { DateTime } from 'luxon';

import Bars from './bars';

const TrxDate = ({ date, label }) => {

    return (
        <div className="d-flex justify-content-between align-items-start align-items-sm-center mb-2 flex-column flex-sm-row">
            <div className="left d-flex align-items-center">
                <div className="icon icon-lg shadow mr-3 text-gray"><i className="fas fa-sync"></i></div>
                <div className="text">
                    <h6 className="mb-0 d-flex align-items-center"> <span>{ label }</span></h6>
                    <div className="mb-0 d-flex align-items-center text-blue">
                        { date === undefined && <Bars /> }
                        { date !== undefined && <strong>{ DateTime.fromISO(date).toFormat('dd-LLLL-yyyy HH:mm:ss') }</strong> }
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default TrxDate;
