import React, { useState, useEffect } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';
import _ from 'lodash';

import Label from '../../../components/label';
import InputNumeral from '../../../components/input-numeral';
import TextNumber from '../../../components/text-number';
import ReportButton from '../../../components/report-button';
import DayPicker from '../../../components/day-picker';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile, getDateApiParam } from '../../../helper/util';
import constant from '../../../config/constant';
import salesPurchasePath from './config';
import LoaderType from '../../../components/loader-type';

const List = () => {

    const [isLoad, setIsLoad] = useState(true);

    const [startDate, setStartDate] = useState(DateTime.local());

    const [endDate, setEndDate] = useState(DateTime.local());

    const [salesPurchaseSummary, setSalesPurchaseSummary] = useState({
        summary: 0,
        farm: 0,
        agent: 0
    });

    const [salesPurchase, setSalesPurchase] = useState([]);

    const onStartDateChange = day => {
        
        setStartDate(formatDate(day, constant.DEFAULT_DATE));

        setEndDate(formatDate(day, constant.DEFAULT_DATE));
    }

    const onEndDateChange = day => setEndDate(formatDate(day, constant.DEFAULT_DATE));

    const getExcel = async(param, url) => await getFile(url, param);

    const populateSalesPurchase = () => {

        setIsLoad(true);

        const params = {
            params: { 
                'start-date': DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                'end-date': DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT)
            }
        };

        getData(salesPurchasePath.base, params).then(result => setSalesPurchase(result[0]));

        getData(salesPurchasePath.salesPurchaseSumm, params).then(result => {

            if (!_.isEmpty(result[0])) {

                setSalesPurchaseSummary(data => ({
                    ...data,
                    summary: _.get(result[0][0], 'qty'),
                    farm: _.get(result[0][0], 'farmQty'),
                    agent: _.get(result[0][0], 'agentQty')
                }));
            }

            setIsLoad(false);
        });


    }

    const onExport = e => {

        e.preventDefault();

        const params = {
            params: { 
                'start-date': getDateApiParam(startDate),
                'end-date': getDateApiParam(endDate)
            }
        };

        getExcel(params, salesPurchasePath.baseExcel).then(response => {

            downloadFile(response);

        });

    }

    const onReset = e => {

        e.preventDefault();

        setStartDate(DateTime.local());

    }

    const onSubmit = e => {

        e.preventDefault();

        populateSalesPurchase();

    }

    useEffect(populateSalesPurchase, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ salesPurchasePath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="start-date" text="Tanggal Dari" />
                                    <DayPicker id="start-date" name="startDate" onDayChange={ onStartDateChange } valueDate={ startDate } />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="end-date" text="Tanggal Ke" />
                                    <DayPicker id="end-date" name="endDate" onDayChange={ onEndDateChange } valueDate={ endDate } />
                                </div>

                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>

                    {
                        !isLoad &&
                        <div className="col-md-6">

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <h3>Rekapitulasi</h3>
                                </div>
                            </div>

                            <div className="form-group row">

                                <div className="col-md-12">
                                    <Label htmlFor="summary" text="Rekap Kg" />
                                    <InputNumeral
                                        value={ salesPurchaseSummary.summary }
                                        display={ true }
                                    />
                                </div>

                            </div>

                            <div className="form-group row">

                                <div className="col-md-6">
                                    <Label htmlFor="farm-qty" text="Kg Petani" />
                                    <InputNumeral
                                        value={ salesPurchaseSummary.farm }
                                        display={ true }
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="agent-qty" text="Kg Agen" />
                                    <InputNumeral
                                        value={ salesPurchaseSummary.agent }
                                        display={ true }
                                    />
                                </div>

                            </div>
                        </div>
                    }
                </div>

                <br />

                {
                    isLoad &&
                    <div className="row">
                        <div className="col-lg-12 text-center" >
                            <LoaderType />
                        </div>
                    </div>
                }
                {
                    !isLoad && salesPurchase.length > 0 &&
                    <>
                        <br />
                    
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Tanggal</th>
                                                <th>No Faktur</th>
                                                <th>PKS</th>
                                                <th>Barang Dan Jasa</th>
                                                <th>Kode Kendaraan</th>
                                                <th>Driver</th>
                                                <th>Pembelian (Kg)</th>
                                                <th>Harga Beli (Rp)</th>
                                                <th>Total Beli (Rp)</th>
                                                <th>Penjualan (Kg)</th>
                                                <th>Harga Jual (Rp)</th>
                                                <th>Total Jual (Rp)</th>
                                                <th>Selisih Tonase (Kg)</th>
                                                <th>Selisih Harga (Rp)</th>
                                                <th>Profit (Rp)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                salesPurchase.map((co, index) => {
                                                    return(
                                                        <tr key={ index }>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ `${formatDate(co.trxDate, 'LL', constant.LOCALE)}` }</td>
                                                            <td>{ co.salesInvoiceNo }</td>
                                                            <td>{ co.millName }</td>
                                                            <td>{ co.gnsName }</td>
                                                            <td>{ co.vehicleCode }</td>
                                                            <td>{ co.driver }</td>
                                                            <td><TextNumber value={ co.purchaseQty } /></td>
                                                            <td><TextNumber value={ co.purchasePrice } /></td>
                                                            <td><TextNumber value={ co.totalPurchase } /></td>
                                                            <td><TextNumber value={ co.salesQty } /></td>
                                                            <td><TextNumber value={ co.sellingPrice } /></td>
                                                            <td><TextNumber value={ co.totalSales } /></td>
                                                            <td><TextNumber value={ co.diffQtySalesPurchase } /></td>
                                                            <td><TextNumber value={ co.diffPriceSalesPurchase } /></td>
                                                            <td><TextNumber value={ co.profitSalesPurchase } /></td>
                                                            
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
