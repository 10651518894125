import React from 'react';
import { Route } from 'react-router-dom';
import ReceivableList from './List';
import ReceivableAction from './Action';
import receivablePath from './config';

const ReceivableRoutes = () => {
    return (
        <React.Fragment>
            <Route path={receivablePath.basePath} exact component={ReceivableList} />
            <Route path={receivablePath.newPath} exact component={ReceivableAction} />
            <Route path={receivablePath.detailPath} exact component={ReceivableAction} />
        </React.Fragment>
    );
};

export default ReceivableRoutes;