import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SalesPurchaseList from './List';
import salesPurchasePath from './config';

const PoSalesPurchaseRoutes = () => {
    return (
        <Switch>
            <Route path={salesPurchasePath.basePath} exact component={SalesPurchaseList} />
        </Switch>
    );
};

export default PoSalesPurchaseRoutes;
