import React from 'react';
import { Route } from 'react-router-dom';
import BankTrxList from './List';
import BankTrxAction from './Action';
import banktrxPath from './config';

const BankTrxRoutes = () => {
    return (
        <React.Fragment>
            <Route path={banktrxPath.basePath} exact component={BankTrxList} />
            <Route path={banktrxPath.newPath} exact component={BankTrxAction} />
            <Route path={banktrxPath.detailPath} exact component={BankTrxAction} />
        </React.Fragment>
    );
};

export default BankTrxRoutes;