import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import buPath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();

    const [titleInfo, setTitleInfo] = useState("");

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [bu, setBu] = useState({
        id: 0,
        code: "",
        name: ""
    });

    const onInputChange = e => {

        const { name, value } = e.target;

        setBu(bu => ({ 
            ...bu,
            [name]: value
        }));
    };

    const onReset = e => {

        e.preventDefault();

        setBu(bu => ({
            ...bu,
            code: "",
            name: ""
        }));
    }

    const onSubmit = e => {
        
        e.preventDefault();

        const postBusinessUnit = async () => {

            const result = await postData(buPath.baseApi, bu);

            if (Object.keys(result).length) {

                onSuccess(`Unit bisnis ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putBusinessUnit = async () => {

            const result = await putData(`${buPath.baseApi}/${bu.id}`, bu);

            if (Object.keys(result).length) {
                
                onSuccess(`Unit bisnis ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        };

        if (bu.id === 0 && bu.code && bu.name) postBusinessUnit();
        else putBusinessUnit();

    }

    useEffect(() => {

        const init = () => {

            const state = refData.current.state;
            
            if (state) {
                
                setBu(state);

                setTitleInfo(`Detail: ${state.name}`);

                setIsResetable(false);

            } else {
                
                setTitleInfo("Tambah Unit Bisnis Baru");

            }
        };

        init();

    }, []);

    useEffect(() => {

        if (bu.code && bu.name) setIsDisabledSave(false);

    }, [bu.code, bu.name]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{buPath.title} | {titleInfo}</h3>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={e => onSubmit(e)}>
                            <div className="form-group row">
                                <Label htmlFor="bu-code" isCol={ true } text="Kode Unit Bisnis" isMandatory={ true } value={ bu.code } />
                                <div className="col-md-9">
                                    <input 
                                        id="bu-code"
                                        type="text" 
                                        className="form-control" 
                                        name="code"
                                        value={bu.code}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="bu-name" isCol={ true } text="Name Unit Bisnis" isMandatory={ true } value={ bu.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="bu-name" 
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={bu.name}
                                        onChange={ e => onInputChange(e) }/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset }/>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
