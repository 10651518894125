import React, { useState, useEffect } from 'react';
import { DateTime, Settings } from 'luxon';
import { formatDate } from 'react-day-picker/moment';

import Label from '../../../components/label';
import Note from '../../../components/note';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import TextNumber from '../../../components/text-number';
import LoaderType from '../../../components/loader-type';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import stockPath from './config';

const List = () => {

    Settings.defaultLocale = 'id';

    const [isLoad, setIsLoad] = useState(true);

    const [stock, setStock] = useState([]);

    const [selectedGoods, setSelectedGoods] = useState(constant.DEFAULT_SELECTED);

    const stockFilterEntity = {
        id: 0,
        periode: undefined,
        gnsId: 0,
    }

    const [stockFilter, setStockFilter] = useState(stockFilterEntity);

    const getDetailPath = (id) => stockPath.detailPath.replace(":id", id);

    const onStockDateChange = day => {

        setStockFilter({ 
            ...stockFilter,
            periode: formatDate(day, constant.DEFAULT_DATE)
        });
    };

    const onGoodsChange = opt => {

        if (opt.id) {

            setStockFilter(filter => ({ 
                ...filter,
                gnsId: opt.id
            }));

            setSelectedGoods(goods => ({
                ...goods,
                value: opt.id,
                label: opt.label
            }));
        } 
    }

    const getParams = () => {

        const params = { 
            params: { 
                'periode': stockFilter.periode, 
                'gns-id': stockFilter.gnsId,
                'stock-id': stockFilter.id
            } 
        };

        return params;
    }

    const onExport = e =>  {

        e.preventDefault();

        const params = getParams();

        getFile(stockPath.excel, params).then(response => downloadFile(response));

    }

    const onSubmit = e => {

        e.preventDefault();

        setIsLoad(true);

        const params = getParams();

        getStocks(params).then(result => {

            setStock(result);

            setIsLoad(false);

        });

    }

    const getStocks = async(params) => {

        const result = await getData(stockPath.api, params);

        return result;

    }

    /**
     * Event triggerd when reset button clicked.
     * @param {Object} e 
     */
    const onReset = (e) => {

        e.preventDefault();

        setIsLoad(true);

        setStockFilter(stockFilterEntity);

        setSelectedGoods(constant.DEFAULT_SELECTED);

        getStocks().then(result => {

            setStock(result);

            setIsLoad(false);

        });

    }

    useEffect(() => { 
        
        getStocks().then(result => {

            setStock(result);

            setIsLoad(false);

        });
    
    }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ stockPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="stock-date" text="Tanggal" />
                                    <DayPicker id="stock-date" name="stockDate" onDayChange={ onStockDateChange } valueDate={ stockFilter.trxDateFrom } />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="goods-name" text="Nama Barang" />
                                    <Select
                                        id="goods-name"
                                        api={ stockPath.goods }
                                        onChange={ onGoodsChange }
                                        value={ selectedGoods }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <FilterButton to={ stockPath.newPath } newLabel="Hitung Stock" onClick={ onReset } isExport={ true } onExport={ onExport } />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Data Penjualan" entity="Penjualan" />
                    </div>
                </div>

                { isLoad && <LoaderType /> }

                {
                    !isLoad && stock.length > 0 &&
                    <>
                        <br />
                                
                        <div className="col-lg-12 mb-4">
                            <div className="row">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Kategori</th>
                                                <th>Periode</th>
                                                <th>Kode Stock</th>
                                                <th>Kode Barang</th>
                                                <th>Nama Barang</th>
                                                <th>Jumlah Stock</th>
                                                <th>Opsi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                stock.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ item.description }</td>
                                                            <td>{ DateTime.fromISO(item.periode).toFormat('dd-LLLL-yyyy') }</td>
                                                            <td>{ item.stockId }</td>
                                                            <td>{ item.code }</td>
                                                            <td>{ item.name }</td>
                                                            <td><TextNumber value={ item.stock } /></td>
                                                            <td><LinkButton to={ {pathname: getDetailPath(item.stockId), state: item} } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
