import apiPath from '../../../config/api';

const RentTrxPath = {
    title: "Transaksi Rental",
    basePath: "/rental/rental-mobil",
    newPath: "/rental/rental-mobil/baru",
    detailPath: "/rental/rental-mobil/opsi/:id",
    baseApi: apiPath.rentalTrx,
    emplFind: apiPath.masterEmplFind,
    custFind: apiPath.masterCustFind,
    vehicleFind: apiPath.masterVehicleFind
}

export default RentTrxPath;
