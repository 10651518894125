import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CashOutList from './List';
import poCashoutPath from './config';

const PoCashOutRoutes = () => {
    return (
        <Switch>
            <Route path={poCashoutPath.basePath} exact component={CashOutList} />
        </Switch>
    );
};

export default PoCashOutRoutes;