import apiPath from '../../../config/api';

const UnitPath = {
    title: "Modul Satuan", 
    basePath: "/master/satuan",
    newPath: "/master/satuan/baru",
    detailPath: "/master/satuan/opsi/:id",
    baseApi: "/master/unit",
    unitFilter: apiPath.masterUnitFilter
}

export default UnitPath;
