import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CustSaldoList from './List';
import custSaldoPath from './config';

const CustSaldoRoutes = () => {
    return (
        <Switch>
            <Route path={custSaldoPath.basePath} exact component={CustSaldoList} />
        </Switch>
    );
};

export default CustSaldoRoutes;