import api from '../../../config/api';

const BankBalancePath = {
    'title': "Saldo Di Bank",
    'base': "/kas/bank",
    'new': "/kas/bank/baru",
    'detail': "/kas/bank/opsi/:id",
    'api': api.bankBalance,
    'bankApi': api.masterBankFind,
    'bankAccApi': api.masterBankAccountFind
}

export default BankBalancePath;
