import React from 'react';
import { Route } from 'react-router-dom';
import RentTrxList from './List';
import RentTrxAction from './Action';
import renttrxPath from './config';

const RentTrxRoutes = () => {
    return (
        <React.Fragment>
            <Route path={renttrxPath.basePath} exact component={RentTrxList} />
            <Route path={renttrxPath.newPath} exact component={RentTrxAction} />
            <Route path={renttrxPath.detailPath} exact component={RentTrxAction} />
        </React.Fragment>
    );
};

export default RentTrxRoutes;