import React, {useState, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import { DateTime } from 'luxon';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import InputNumeral from '../../../components/input-numeral';
import { postData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import constant from '../../../config/constant';
import payablePath from './config';
import { getRetailBalance } from '../../../helper/cash-balance';

const Action = () => {
    
    const data = useLocation();
    
    const history = useHistory();

    const [, , setRetailBalance] = useGlobalState('retailBalance');

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [isNew, setIsNew] = useState(true);
    
    const [titleInfo, setTitleInfo] = useState("");

    const [selectedSupplierName, setSelectedSupplierName] = useState(constant.DEFAULT_SELECTED);

    const [payable, setPayable] = useState(
        {
            id: 0,
            apArId: 0,
            trxDate: DateTime.local(),
            invoiceNo: `AP${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
            invoiceLineNo: null,
            amount:0,
            masterCustomerId: 0,
            loanBalance:0,
            outstanding:0,
            supplierName:"",
            isChecked: 0
        });

    const onInputChange = e => {

        const { name, value } = e.target;
        
        const payableData = {
            ...payable,
            [name]: value
        } 

        setPayable(payableData);
        
    };

    const onCheckboxChange = e => {

        if(payable.isChecked === 0){
            setPayable(data => ({
                ...data,
                amount: payable.loanBalance,
                isChecked: payable.isChecked === 1 ? 0 : 1
            }));
        }else{
            setPayable(data => ({
                ...data,
                amount: 0,
                isChecked: payable.isChecked === 1 ? 0 : 1
            }));
        }
        

    }

    const onAmountChange = values => {

        const { value } = values;
        const val = parseFloat(value);

        if (payable.loanBalance >= val){
            setPayable(data => ({
                ...data,
                amount: val,
                outstanding: calcOutstanding(data.loanBalance, val)
            }));
        }else{
            setPayable(data => ({
                ...data,
                amount: payable.loanBalance,
                outstanding:0
            }));
        }

        
    }

     /**
     * Supplier change event.
     * @param {Object} opt - Option object.
     */
    const onChangeSupplier = opt => {

        if (opt.id) {

            setPayable(data => ({
                ...data,
                apArId: opt.apArId,
                masterCustomerId: opt.id,
                supplierName: opt.name,
                loanBalance: opt.arAmount,
            }));

            setSelectedSupplierName(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    const onDayChange = day => {

        setPayable({ 
            ...payable,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    }

    const onReset = e => {

        e.preventDefault();

        setPayable(data => ({ 
            ...data,
                id: 0,
                apArId: 0,
                trxDate: DateTime.local(),
                invoiceNo: `AP${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
                invoiceLineNo: null,
                amount:0,
                masterCustomerId: 0,
                loanBalance:0,
                outstanding:0,
                supplierName:"",
                isChecked: 0
        }));

        setSelectedSupplierName(name => ({
            ...name,
            label: ""
        }));
    }

    /**
     * Calculate outstanding. Return 0 if the result less than 0.
     * @param {Number} loanBalance - Sub total of transaction.
     * @param {Number} amount - Payment.
     */
    const calcOutstanding = (loanBalance, amount) =>  {
        
        const outstanding = parseFloat(loanBalance) - parseFloat(amount);

        return outstanding < 0 ? 0 : outstanding;
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postBankTrx = async () => {

            setIsDisabledSave(true);
            setRetailBalance(0);

            const result = await postData(payablePath.baseApi, payable);

            if (!_.isEmpty(result)) {

                onSuccess(`Transaksi ${result.invoice_no} berhasil di tambahkan`);

                history.goBack();

                getRetailBalance();

            }
        };

        if (payable.id === 0) postBankTrx();

    }

    useEffect(() => {

        function setPayableState() {

            if (data.state) {

                setPayable(data.state)

                setIsResetable(false);

                setTitleInfo(`Detail: ${data.state.invoiceNo}`);

                setSelectedSupplierName(code => ({
                    ...code,
                    value: data.state.custId,
                    label: data.state.custName
                }));

                setIsNew(false);

            } else {

                setTitleInfo("Tambah Data Baru");

            }
        }

        setPayableState();

    }, [data]);

    useEffect(() => {

        if (payable.supplierName && ( payable.amount > 0) ) setIsDisabledSave(false);

        if (!payable.supplierName || ( payable.amount <= 0) ) setIsDisabledSave(true);

    }, [payable.supplierName , payable.amount]);

    return (

        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{payablePath.title} | {titleInfo}</h3>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="account-type" text="No Pembayaran"/>
                                <input 
                                id="payable-number"
                                type="text" 
                                className="form-control" 
                                name="invoiceNo"
                                value={payable.invoiceNo}
                                onChange={ e => onInputChange(e) } 
                                readOnly
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="account-type" text="Tanggal"/>
                                <div>
                                    <DayPicker id="trx-date" name="trxDate" valueDate={payable.trxDate} onDayChange={onDayChange} />
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="supplier-name" text="Supplier" isMandatory={ true } value={ payable.supplierName } />
                                <Select
                                    id="supplier-name"
                                    paramFilter="name"
                                    api={ payablePath.custFind }
                                    entityId="6"
                                    onChange={ onChangeSupplier }
                                    value={ selectedSupplierName }
                                />
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                    <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                        {isNew &&
                        <div className="form-group row">
                            <div className="col-md-12">
                            <Label htmlFor="account-type" text="Total Hutang"/>
                                <InputNumeral 
                                    id="loanBalance"
                                    value={ payable.loanBalance }
                                    display={ true }
                                />
                            </div>
                        </div>
                        }

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="account-type" text="Jumlah Bayar" isMandatory={ true } value={ payable.amount } />
                                <InputNumeral 
                                    id="amount"
                                    value={ payable.amount }
                                    onValueChange={ onAmountChange }
                                />
                            </div>
                        </div>
                        
                        {isNew &&
                        <div className="form-group row">
                            <div className="col-md-12">
                                <div className="custom-control custom-checkbox">
                                    <input 
                                        id="pay-all"
                                        type="checkbox"
                                        className="custom-control-input"
                                        name="payAll"
                                        value={ payable.isChecked }
                                        checked={ payable.isChecked }
                                        onChange={ e => onCheckboxChange(e) }
                                    />   
                                    <label htmlFor="pay-all" className="custom-control-label">Bayar Semua</label>
                                </div>
                            </div>
                        </div>
                        }

                        {isNew &&
                        <div className="form-group row">
                            <div className="col-md-12">
                            <Label htmlFor="outstanding" text="Sisa Hutang"/>
                                <InputNumeral 
                                    id="outstanding"
                                    value={ payable.outstanding }
                                    display={ true }
                                />
                            </div>
                        </div>
                        }
                        
                        <div className="form-group row">
                            <div className="col-md-12">
                            <Label />
                                <ActionButton 
                                    isSave={ isNew }
                                    isDisabledSave={ isDisabledSave } 
                                    isReset={ isResetable } 
                                    onReset={ onReset } />
                            </div>
                        </div>

                        <Note isAction={ true } />
                    
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Action;
