import React, { useState, useEffect } from 'react';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import { getData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import employeePath from './config';

const List = () => {

    const [employees, setEmployees] = useState([]);

    const [designations, setDesignation] = useState([]);

    const [employeeFilter, setFilterEmployee] = useState({
        name: "",
        masterDesignationId: "",
        isActive: 1
    });

    const getDetailPath = (id) => employeePath.detailPath.replace(":id", id);

    const onInputChange = e => {

        const { name, value } = e.target;

        setFilterEmployee(emp => ({ 
            ...emp,
            [name]: value
        }));
    };

    const onActiveChange = e => {

        setFilterEmployee(empl => ({ 
            ...empl,
            isActive: empl.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: { 
                name: employeeFilter.name, 
                masterDesignationId: employeeFilter.masterDesignationId 
            }
        };

        getEmployees(params);

    };

    const getEmployees = async(params) => {

        const result = await getData(employeePath.baseApi, params);
            
        setEmployees(result);

    }

    const onReset = e => {

        e.preventDefault();

        setFilterEmployee({ 
            ...employeeFilter,
            name: "",
            masterDesignationId: 0
        });

        getEmployees();

    }

    const getDesignations = async() => {

        const result = await getData(employeePath.baseApiDesignation);

        setDesignation(result);

    }

    useEffect(() => { 

        getEmployees();
        
        getDesignations();

    }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{employeePath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >
                            <div className="form-group row">
                                <Label htmlFor="employee-name" isCol={ true } text="Nama Karyawan" />
                                <div className="col-md-9">
                                    <input 
                                        id="employee-name" 
                                        type="text" 
                                        className="form-control"
                                        name="name"
                                        value={employeeFilter.name}
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="designation" isCol={ true } text="Jabatan" />
                                <div className="col-md-9">
                                    <select id="designation" className="form-control" name="masterDesignationId" onChange={ e => onInputChange(e) } value={ employeeFilter.masterDesignationId }>
                                        <option value="">Pilih</option>
                                        {designations.map(({id, name}) => {return(<option key={id} value={id}>{name}</option>)})}
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-4">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="employee-is-active"
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="isActive"
                                            value={ employeeFilter.isActive }
                                            checked={ employeeFilter.isActive }
                                            onChange={ e => onActiveChange(e) } />   
                                        <label htmlFor="employee-is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ employeePath.newPath } newLabel="Tambah Karyawan Baru" onClick={ onReset } />
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Karyawan Baru" entity="Karyawan" />
                    </div>

                </div>

                <br />

                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nama Karyawan</th>
                                        <th>Jabatan</th>
                                        <th>No Telepon</th>
                                        <th>Status Karyawan</th>
                                        <th>Opsi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        employees.map((employee, index) => {
                                            return(
                                                <tr key={index}>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ employee.name }</td>
                                                    <td>{ employee.designationName }</td>
                                                    <td>{ employee.phoneNo }</td>
                                                    <td>{ employee.isActive === 1 ? constant.ACTIVE : constant.INACTIVE }</td>
                                                    <td><LinkButton to={ {pathname: getDetailPath(employee.id), state: employee} } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
