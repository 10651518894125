import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import AutoComplete from '../../../components/async-select';
import Filter from '../../../components/filter';
import {getData} from '../../../helper/request-response';
import constant from '../../../config/constant';
import aparheaderPath from './config';

const List = () => {

    const [aparheaders, setApArHeaders] = useState([]);

    const [aparheaderFilter, setFilterApArHeader] = useState({
        customerCode: ""
    });

    const getDetailPath = (id) => aparheaderPath.detailPath.replace(":id", id);

    const onSelectChange = opt => {

        setFilterApArHeader({ 
            ...aparheaderFilter,
            customerCode: opt.label
        });
    }

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                customerCode: aparheaderFilter.customerCode
            }
        };

        getApArHeaders(params);

    };

    const getApArHeaders = async(params) => {

        const result = await getData(aparheaderPath.baseApi, params);
            
        setApArHeaders(result);

    }

    const onButtonClick = e => {

        e.preventDefault();

        setFilterApArHeader({ 
            ...aparheaderFilter,
            customerCode: ""
        });

        getApArHeaders();

    }

    useEffect(() => { 

        getApArHeaders();

     }, []);
    

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{aparheaderPath.title}</h3>
            </div>
            <div className="card-body">
                <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                    <div className="form-group row">
                        <label htmlFor="apArHeader-customerCode" className="col-md-2 form-control-label">Kode Pelanggan</label>

                        <div className="col-md-4">
                            <AutoComplete 
                                filterApi={aparheaderPath.customerNameFilterApi}
                                onSelectChange={onSelectChange}
                                filter={{id: aparheaderFilter.id, label: aparheaderFilter.customerCode}}
                             />
                        </div>
                    </div>

                    <Filter onButtonClick={onButtonClick} path={aparheaderPath.newPath} label="Tambah AP AR Baru" />
                </form>

                <div className="col-lg-12 mb-4">
                    <div className="row">
                        <table className="table table-striped table-hover card-text">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Kode Pelanggan</th>
                                    <th>Nama Pelanggan</th>
                                    <th>Status</th>
                                    <th>Opsi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    aparheaders.map((aparheader, index) => {
                                        return(
                                            <tr key={index}>
                                                <th scope="row">{ index + 1 }</th>
                                                <td>{ aparheader.customerCode }</td>
                                                <td>{ aparheader.customerName }</td>
                                                <td>{ aparheader.isActive === 1 ? constant.ACTIVE : constant.INACTIVE }</td>
                                                <td><Link to={{ pathname: getDetailPath(aparheader.id), state: aparheader }} className="btn btn-success">{constant.DETAIL}</Link></td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
