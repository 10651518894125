import { toast } from 'react-toastify';

const config = {
    info: {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    },
    close: {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }
}

export function onSuccess(message) {
    toast.success(`${message}`, config.close)
};

export function onError(error) {

    if (error.response) toast.error(error.response.data.message, config.info);
    else {
        console.error(error);
        toast.error("Error, see console!", config.info);
    }
}
