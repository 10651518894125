import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import _ from 'lodash';

import { postData } from '../helper/request-response';
import { onSuccess } from '../helper/toaster';

const TrxCheckBox = ({ data, postUrl, deleteUrl, isAllowDelete }) => {

    const [trx, setTrx] = useState({
        'id': 0,
        'invoiceNo': '',
        'status': false
    });

    const [val, setVal] = useState(false);

    useEffect(() => {

        setTrx(t => ({
            ...t,
            id: data.id,
            invoiceNo: data.invoiceNo,
            customerId: data.customerId,
            aparDetailId: data.apardetailId,
            status: data.status
        }));

        setVal(data.status);

    }, [data]);

    const onStatusChanged = e => {

        const post = async () => {

            const result = await postData(postUrl, {
                'invoice-no': trx.invoiceNo,
                'cashin-date': DateTime.local(),
                'customer-id': trx.customerId,
                'ap-ar-detail-id': trx.aparDetailId
            });
            
            if (!_.isEmpty(result)) {

                onSuccess(`Dana penerimaan untuk ${trx.customerName} berhasil di tambahkan`);
                
                setTrx(disb => ({
                    ...disb,
                    id: result.id
                }));

            }
        };

        const checked = val === 1 ? 0 : 1;
        
        post();

        setVal(checked);
    }

    return(
        <React.Fragment>
            {
                !isAllowDelete &&
                <span className="custom-control custom-checkbox text-center">
                    <input 
                        id={`trx-${trx.invoiceNo}`}
                        type="checkbox"
                        className="custom-control-input"
                        checked={ val }
                        value={ val }
                        onChange={ e => onStatusChanged(e) } 
                        disabled={ trx.id ? true : false }/>   
                        <label htmlFor={`trx-${trx.invoiceNo}`} className="custom-control-label">&nbsp;</label>
                </span>
            }
            {
                isAllowDelete &&
                <span className="custom-control custom-checkbox text-center">
                    <input 
                        id={`trx-${trx.invoiceNo}`}
                        type="checkbox"
                        className="custom-control-input"
                        checked={ val }
                        value={ val }
                        onChange={ e => onStatusChanged(e) } 
                        />   
                        <label htmlFor={`trx-${trx.invoiceNo}`} className="custom-control-label">&nbsp;</label>
                </span>
            }
        </React.Fragment>
    );
}

export default TrxCheckBox;
