import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import LinkButton from '../../../components/link-button';
import { getData, postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import constant from '../../../config/constant';
import menuPath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);
    
    const history = useHistory();

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isAddWithPage, setIsAddWithPage] = useState(false);

    const [isResetable, setIsResetable] = useState(true);

    const [titleInfo, setTitleInfo] = useState("");

    const [menu, setMenu] = useState({
        id: 0,
        name: "",
        label: "",
        icon: "",
        path: "#",
        parentId: null,
        isActive: 1,
        subs: []
    });

    const newSubMenuPath = {
        
        'pathname': menuPath.newSubMenuPath.replace(":parentId", menu.id),
        'state': {
            "parentLabel": menu.label,
            "parentId": menu.id
        }
    };

    const getDetailSubMenuPath = (parentId, menuId) => menuPath.detailSubMenuPath.replace(":parentId", parentId).replace(":id", menuId);

    const onReset = e => {

        e.preventDefault();

        setMenu(mn => ({
            ...mn,
            name: "",
            label: "",
            icon: "",
            path: "#",
            parentId: null,
            isActive: 1,
        }));
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setMenu(mn => ({ 
            ...mn,
            [name]: value
        }));

    };

    const onActiveChange = e => {

        setMenu(mn => ({ 
            ...mn,
            isActive: menu.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postMenu = async () => {

            const result = await postData(menuPath.baseApi, menu);

            if (Object.keys(result).length) {

                onSuccess(`Menu group ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putMenu = async () => {

            const result = await putData(`${menuPath.baseApi}/${menu.id}`, menu);

            if (Object.keys(result).length) {
                
                onSuccess(`Menu group ${result.label} berhasil di perbaharui`);

                history.goBack();

            }
        }

        if (menu.id === 0) postMenu();
        else putMenu();
        
    }

    useEffect(() => {

        const getSubMenu = async (param) => {

            const result = await getData(
                `${menuPath.menuGroupApi}/${menu.id}`,
                { params: { 'parent-id': param.id }}
            );

            setMenu(menu => ({
                ...menu,
                id: param.id,
                name: param.name,
                label: param.label,
                icon: param.icon,
                path: param.path,
                parentId: param.parentId,
                isActive: param.isActive,
                subs: result
            }));
        }

        const init = () => {

            const state = refData.current.state;

            if (state) {
                
                getSubMenu(state);

                setTitleInfo(`Detail: ${state.label}`);

                setIsAddWithPage(true);

                setIsResetable(false);
    
            } else {
    
                setTitleInfo("Tambah Menu Group Baru");
    
            }
        }
    
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (menu.name && menu.label) setIsDisabledSave(false);

    }, [menu.name, menu.label]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{menuPath.title} | {titleInfo}</h3>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="menu-group-name" isCol={ true } text="Nama Menu Group" isMandatory={ true } value={ menu.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="menu-group-name"
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        value={ menu.name }
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="menu-group-label" isCol={ true } text="Label Menu Group" isMandatory={ true } value={ menu.label } />
                                <div className="col-md-9">
                                    <input 
                                        id="menu-group-label"
                                        type="text" 
                                        className="form-control" 
                                        name="label"
                                        value={menu.label}
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="menu-group-icon" isCol={ true } text="Icon" />
                                <div className="col-md-9">
                                    <input 
                                        id="menu-group-icon"
                                        type="text" 
                                        className="form-control" 
                                        name="icon"
                                        value={ menu.icon }
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="menu-group-active"
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            name="isActive"
                                            value={ menu.isActive }
                                            checked={ menu.isActive }
                                            onChange={ e => onActiveChange(e) } 
                                        />   
                                        <label htmlFor="menu-group-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave }
                                        isAddWithPage={ isAddWithPage }
                                        isReset={ isResetable }
                                        onReset={ onReset }
                                        newPagePath ={ newSubMenuPath }
                                        addLabel={`Tambah Sub ${menu.label} Baru`} />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isAction={ true } />
                        {
                            !isResetable &&
                            <p>
                                Klik tombol <span><strong>[Tambah Sub {menu.label}]</strong></span> untuk manambahkan <strong>Sub Menu {menu.label}</strong> baru.
                            </p>
                        }
                    </div>

                </div>

                <br />

                <div className="row">
                    {
                        menu.id !== 0 &&
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Group Menu</th>
                                        <th>Sub Menu</th>
                                        <th>Icon</th>
                                        <th>Status</th>
                                        <th>Opsi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        menu.subs.map((sub, index) => {
                                            return(
                                                <tr key={index}>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{menu.label}</td>
                                                    <td>{sub.label}</td>
                                                    <td><i className={sub.icon}></i></td>
                                                    <td>{menu.isActive === 1 ? constant.ACTIVE : constant.INACTIVE}</td>
                                                    <td><LinkButton to={ { pathname: getDetailSubMenuPath(menu.id, sub.id), state: {"sub": sub, "parentLabel": menu.label} }} /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default Action;
