import React from "react";
import constant from '../../../config/constant';

const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div className="ticket" ref={ref}>
          <p className="centered title-header">{constant.BILL.COMP_NAME}</p>
          <p className="centered" >{constant.BILL.COMP_DESC}<br/>{constant.BILL.COMP_ADDR_LINE1}<br/>{constant.BILL.COMP_ADDR_LINE2}</p>
          
          <p className="centered border-dot-top border-dot-btm" >FAKTUR/NOTA TRANSAKSI BRI LINK</p>

          <table className="cust-info">
            <tr>
              <td>NO</td>
              <td>&nbsp;:&nbsp;</td>
              <td>{props.invoiceNo}</td>
            </tr>
            <tr>
              <td>TANGGAL</td>
              <td>&nbsp;:&nbsp;</td>
              <td>{props.trxDate}</td>
            </tr>
            <tr>
              <td>KD. CUST</td>
              <td>&nbsp;:&nbsp;</td>
              <td>{props.custCode}</td>
            </tr>
            <tr>
              <td>N A M A</td>
              <td>&nbsp;:&nbsp;</td>
              <td>{props.custName}</td>
            </tr>
          </table>
          <br/>
          <table>
              <thead>
                  <tr className="border-dash-top border-dash-btm">
                      <td className="description">NAMA BARANG</td>
                      <td className="quantity">QTY</td>
                      <td className="price">@ HARGA</td>
                      <td className="total">JUMLAH</td>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td className="description">{props.trxDesc}</td>
                      <td className="quantity">{props.qty}</td>
                      <td className="price"><p class="alignright">{props.amount.toLocaleString()}</p></td>
                      <td className="total"><p class="alignright">{(props.qty * props.amount).toLocaleString()}</p></td>
                  </tr>
              </tbody>
              <tbody>
                  <tr>
                      <td className="description">Biaya Admin</td>
                      <td className="quantity">{props.qty}</td>
                      <td className="price"><p class="alignright">{props.intFee.toLocaleString()}</p></td>
                      <td className="total"><p class="alignright">{props.intFee.toLocaleString()}</p></td>
                  </tr>
              </tbody>
              <tbody className="border-solid-btm">
                  <tr>
                      <td className="description">Biaya Layanan</td>
                      <td className="quantity">{props.qty}</td>
                      <td className="price"><p class="alignright">{props.extFee.toLocaleString()}</p></td>
                      <td className="total"><p class="alignright">{props.extFee.toLocaleString()}</p></td>
                  </tr>
              </tbody>
          </table>
          
          <table>
            <tr>
              <td className="left">
                <p class="alignleft">Sub Total</p>
              </td>
              <td className="right">
                <p class="alignleft">Rp</p>
                <p class="alignright">{props.subTotal.toLocaleString()}</p>
              </td>
            </tr>

            <tr>
              <td className="left">
                <p class="alignleft">Jumlah Bayar</p>
                <p class="alignright">- &nbsp;</p>
              </td>
              <td className="right border-dash-btm">
                <p class="alignleft">Rp</p>
                <p class="alignright">{props.payAmount.toLocaleString()}</p>
              </td>
            </tr>

            <tr>
              <td className="left">
                <p class="alignleft">Sisa Pembayaran</p>
              </td>
              <td className="right">
                <p class="alignleft">Rp</p>
                <p class="alignright">{props.outstanding.toLocaleString()}</p>
              </td>
            </tr>

            <tr>
              <td className="left">
                <p class="alignleft">Kembalian</p>
              </td>
              <td className="right">
                <p class="alignleft">Rp</p>
                <p class="alignright">{props.balance.toLocaleString()}</p>
              </td>
            </tr>
          </table>
          
          
          <br/>
          <div>
            <p className="centered">{constant.BILL.FOOTER}</p>
          </div>
            
        </div>
    );
})

export default ComponentToPrint;