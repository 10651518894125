import React, { useState, useEffect } from 'react';
import LoaderType from '../../../components/loader-type';

import Label from '../../../components/label';
import TextNumber from '../../../components/text-number';
import ReportButton from '../../../components/report-button';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import custSaldoPath from './config';

const List = () => {

    const [custSaldo, setCustSaldo] = useState([]);

    const [isLoad, setIsLoad] = useState(true);

    const [custSaldoFilter, setFilterCustSaldo] = useState({
        name: ""
    });

    const getExcel = async(param, url) => await getFile(url, param);

    const onExport = e => {

        e.preventDefault();

        const params = {
        };

        getExcel(params, custSaldoPath.accountCustSaldoExcel).then(response => {

            downloadFile(response);

        });

    }

    const onReset = e => {

        e.preventDefault();

        setFilterCustSaldo(trx => ({ 
            ...custSaldoFilter,
            name: ""
        }));

        getCustSaldos();

    }

    const onInputChange = e => {

        setFilterCustSaldo({ 
            ...custSaldoFilter,
            [e.target.name]: e.target.value
        });
    };


    const getCustSaldos = async(params) => {

        setIsLoad(true);

        const result = await getData(custSaldoPath.baseApi, params);

        setCustSaldo(result);

        setIsLoad(false);
    }

    const onSubmit = e => {

        e.preventDefault();

        setIsLoad(true);

        const params = { 
            params: {
                name: custSaldoFilter.name,
            }
        };

        getCustSaldos(params);

    };

    useEffect(() => { 

        getCustSaldos();

    }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ custSaldoPath.title }</h3>
            </div>
            <div className="card-body">
                {
                    isLoad &&
                    <LoaderType />
                }
                {
                    !isLoad &&
                    <>
                        <div className="row">
                            <div className="col-md-6 border-right">
                                <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >
                                    
                                    <div className="form-group row">
                                        <Label htmlFor="custSaldo-name" isCol={ true } text="Nama" />
                                        <div className="col-md-9">
                                            <input 
                                                id="custSaldo-name" 
                                                type="text" 
                                                className="form-control"
                                                name="name"
                                                value={ custSaldoFilter.name }
                                                onChange={ e => onInputChange(e) } />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <Label isCol={ true } />
                                        <div className="col-md-9">
                                            <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <br />

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nama</th>
                                                <th>Tipe</th>
                                                <th>Tabungan</th>
                                                <th>Hutang</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                custSaldo.map((cs, index) => {
                                                    return(
                                                        <tr key={ index }>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ cs.name }</td>
                                                            <td>{ cs.type }</td>
                                                            <td><TextNumber value={ cs.apAmount } /></td>
                                                            <td><TextNumber value={ cs.arAmount } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
