import React, { useState, useEffect } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';

import Label from '../../../components/label';
import InputNumeral from '../../../components/input-numeral';
import TextNumber from '../../../components/text-number';
import ReportButton from '../../../components/report-button';
import DayPicker from '../../../components/day-picker';
import Checkbox from '../../../components/trx-checkbox';
import LoaderType from '../../../components/loader-type';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import cashinPath from './config';

const List = () => {

    const [isLoad, setIsLoad] = useState(true);

    const [date, setDate] = useState(DateTime.local());

    const [cashin, setCashin] = useState([]);

    const [salesSummary, setSalesSummary] = useState({
        total: 0,
        amount: 0 
    });

    const onDayChange = day => setDate(formatDate(day, constant.DEFAULT_DATE));

    const getSales = async(param, url) => await getData(url, param);

    const getExcel = async(param, url) => await getFile(url, param);

    const onExport = e => {

        e.preventDefault();

        const params = {
            params: { 
                'sales-date': DateTime.fromISO(date).toFormat(constant.PARAM_DATE_FORMAT)
            }
        };

        getExcel(params, cashinPath.excel).then(response => {

            downloadFile(response);

        });

    }

    const onReset = e => {

        e.preventDefault();

        setDate(DateTime.local());

    }

    useEffect(() => { 

        const populateCashin = () => {

            setIsLoad(true);

            const params = {
                params: { 
                    'sales-date': DateTime.fromISO(date).toFormat(constant.PARAM_DATE_FORMAT)
                }
            };
    
            getSales(params, cashinPath.summary).then(result => {
    
                setSalesSummary(sum => ({
                    ...sum,
                    total: result[0][0].totalKg,
                    amount: result[0][0].totalSales
                }));
    
            });
            
            getSales(params, cashinPath.api).then(result => {
    
                setCashin(result[0]);
                
                setIsLoad(false);
            });
    
        }

        populateCashin();
        
    }, [date]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ cashinPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" >
                            
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="trx-date" text="Tanggal" />
                                    <DayPicker id="trx-date" name="trx-date" onDayChange={onDayChange} valueDate={ date } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ false } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>
                    {
                        !isLoad &&
                        <div className="col-md-6">
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <h3>Rekapitulasi {`${formatDate(date, 'LL', constant.LOCALE)}`}</h3>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label text="Total Kilogram (Kg)" />
                                    <InputNumeral
                                        value={ salesSummary.total }
                                        display={ true }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label text="Total Pembelian (Rp)" />
                                    <InputNumeral
                                        value={ salesSummary.amount }
                                        display={ true }
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                {
                    isLoad &&
                    <LoaderType />
                }

                {
                    !isLoad && cashin.length > 0 &&
                    <>
                        <br />

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Invoice</th>
                                                <th>Tipe Pelanggan</th>
                                                <th>Kode</th>
                                                <th>Nama</th>
                                                <th>Jumlah (Kg)</th>
                                                <th>Penjualan (Rp)</th>
                                                <th>Dana di Terima</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cashin.map((ci, index) => {
                                                    return(
                                                        <tr key={ index }>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ ci.invoiceNo }</td>
                                                            <td>{ ci.customerType }</td>
                                                            <td>{ ci.customerCode }</td>
                                                            <td>{ ci.customerName }</td>
                                                            <td><TextNumber value={ ci.qty } /></td>
                                                            <td><TextNumber value={ ci.payment } /></td>
                                                            <td><Checkbox data={ ci } postUrl={ cashinPath.api } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
