import React, { useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { getData } from '../helper/request-response';

const Autocomplete = (props) => {

    const [source, setSource] = useState([]);

    const filterLabel = (inputValue) => {

        return source.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));

    }

    const loadOptions = (inputValue, callback) => {
        
        setTimeout(async() => {

            const params = {
                params: { 
                    keyword: inputValue,
                    'entity-type': props.entityType
                }
            };

            const result = await getData(props.filterApi, params);

            setSource(result);

            callback(filterLabel(inputValue));

        }, 250);

    }

    const onSelectChange = opt => {

        if (opt) props.onSelectChange(opt);

    }

    return(
        <AsyncCreatableSelect
            cacheOptions
            loadOptions={ loadOptions }
            defaultOptions
            onChange={ opt => onSelectChange(opt) }
            formatCreateLabel={ props.isCreateable ? keyword => `${props.noResultText} ${keyword}` : keyword => `${keyword} tidak ditemukan` }
            name="label"
            value={ {label: props.filter.label} }
        />
    );

}

export default Autocomplete;
