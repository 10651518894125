import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ClickButton from './click-button';
import constant from '../config/constant';

/**
 * Back Button.
 * @param {Object} props - { className: string }.
 */
const BackButton = (props) => {

    const [className, setClassName] = useState("p-2 flex-fill btn btn-info");

    const history = useHistory();

    const onClick = e => {
        
        e.preventDefault();

        history.goBack();
    }

    useEffect(() => {

        if (props.className) setClassName(props.className);

    }, [props]);

    return(<ClickButton onClick={ onClick } className={ className } label={ constant.BACK } />);
}

export default BackButton;
