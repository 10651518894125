import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BankBalanceList from './List';
import BankBalanceAction from './Action';
import bankBalancePath from './config';

const CoaRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={ bankBalancePath.base } exact component={ BankBalanceList } />
                <Route path={ bankBalancePath.new } exact component={ BankBalanceAction } />
                <Route path={ bankBalancePath.detail } exact component={ BankBalanceAction } />
            </Switch>
        </React.Fragment>
    );
};

export default CoaRoutes;
