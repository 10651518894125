import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { postData, deleteData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import menuAccessPath from './config';

const AccessCheckbox = ({ menu, roleId }) => {

    const [menuAccess, setMenuAccess] = useState({});

    const [role, setRole] = useState();

    const [val, setVal] = useState("false");

    const [isDisabled, setIsDisable] = useState(false);

    useEffect(() => {

        setMenuAccess(menu);

        setRole(roleId);

        setVal(menu.access);

    }, [menu, roleId]);

    const onAccessChanged = e => {

        const postMenuAccess = async () => {

            setIsDisable(true);

            const result = await postData(menuAccessPath.baseApi, {
                id: menuAccess.accessId,
                menuId: menuAccess.menuId,
                roleId: role,
                isActive: 1
            });
            
            if (!_.isEmpty(result)) {

                onSuccess(`Akses role untuk ${menuAccess.menu} berhasil di tambahkan`);

                setMenuAccess(access => ({
                    ...access,
                    accessId: result.id
                }));

                setIsDisable(false);

            }
        };

        const deleteAccess = async(accessId) => {

            setIsDisable(true);

            const result = await deleteData(menuAccessPath.baseApi, {'params': { 'id': accessId }});

            if (!_.isEmpty(result)) {

                setMenuAccess(access => ({
                    ...access,
                    accessId: null
                }));

                onSuccess(`Akses role untuk ${menuAccess.menu} berhasil di perbaharui`);

                setIsDisable(false);

            }

        }

        const checked = val === 1 ? 0 : 1;

        if (menuAccess.accessId === null) postMenuAccess();
        else deleteAccess(menuAccess.accessId);

        setVal(checked);
    }

    return(
        <span className="custom-control custom-checkbox text-center">
            <input 
                id={`menu-${menu.menuId}`}
                disabled={ isDisabled }
                type="checkbox"
                className="custom-control-input"
                checked={ val }
                value={ val }
                onChange={ e => onAccessChanged(e) } />   
                <label htmlFor={`menu-${menu.menuId}`} className="custom-control-label">&nbsp;</label>
        </span>
    );
}

export default AccessCheckbox;