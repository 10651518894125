import apiPath from '../../../config/api';

const StockSalesPath = {
    title: "Retail - Persediaan dan Penjualan",
    basePath: "/pupuk-dan-pertanian/stok-sales",
    base: apiPath.retailStockSales,
    stockSalesSum: apiPath.retailStockSalesSum,
    stockSalesExcel: apiPath.retailStockSalesExcel,
    stockSalesFind: apiPath.retailStockSalesFind
}

export default StockSalesPath;
