import apiPath from '../../../config/api';

const CustTrxPath = {
    title: "Laporan Transaksi Pelanggan Detail",
    basePath: "/akunting/laporan/transaksi-pelanggan-detail",
    baseApi: apiPath.accountCustTrxDetail,
    custTrxDetailSum : apiPath.accountCustTrxDetailSum,
    custTrxDetailExcel : apiPath.accountCustTrxDetailExcel,
    custFind: apiPath.masterCustFind
}

export default CustTrxPath;
