import apiPath from '../../../config/api';

const StockPath = {
    title: "Toko Pupuk dan Pertanian - Saldo Awal",
    basePath: "/pupuk-dan-pertanian/stok",
    newPath: "/pupuk-dan-pertanian/stok/baru",
    detailPath: "/pupuk-dan-pertanian/stok/opsi/:id",
    api: apiPath.retailStock,
    generated: apiPath.retailStockGenerated,
    excel: apiPath.retailStockExcel,
    goods: apiPath.retailStockGoods
}

export default StockPath;
