import apiPath from '../../../config/api';

const MenuConfig = {
    title: "Modul Menu",
    basePath: "/admin/menu/group",
    newPath: "/admin/menu/group/baru",
    detailPath: "/admin/menu/group/:parentId",
    subMenuPath: "/admin/menu/group/sub",
    newSubMenuPath: "/admin/menu/group/:parentId/sub/baru",
    detailSubMenuPath: "/admin/menu/group/:parentId/sub/:id",
    baseApi: apiPath.adminMenu,
    menuGroupApi: apiPath.adminMenuGroup,
    menuGroupFilterLabelApi: apiPath.adminMenuGroupLabelFilter,
    menuSubFilterLabelApi: apiPath.adminMenuSubLabelFilter
}

export default MenuConfig;