import React, { useState, useRef, useEffect } from 'react';

import constant from '../config/constant';

/**
 * Button with onClick Event.
 * @param {Object} params - { isDisabled: Boolean, className: string, label: string, value: object, onClick: requestCallback } 
 */

const ButtonClick = (props) => {

    const refProps = useRef(props);
    const [className, setClassName] = useState('p-2 flex-fill btn btn-secondary');
    const [label, setLabel] = useState(constant.RESET);

    useEffect(() => {

        const prop = refProps.current;

        if (prop.className) setClassName(prop.className);

        if (props.label) setLabel(props.label);

    }, [props.label]);

    return(<button disabled={ props.isDisabled } className={ className } onClick= { e => props.onClick(e) } value={ props.value }>{label}</button>);
}

export default ButtonClick;
