import React, { useState, useEffect, useRef } from 'react';
import {useLocation, useHistory} from 'react-router-dom';

import Label from '../../../components/label';
import Note from '../../../components/note';
import ActionButton from '../../../components/action-button';
import { postData, putData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import designationPath from './config';

const Action = () => {

    const data = useLocation();

    const refData = useRef(data);

    const history = useHistory();

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [titleInfo, setTitleInfo] = useState("");

    const [designation, setDesignation] = useState({
        id: 0,
        name: "",
        isActive: 1
    });

    const onReset = e => {

        e.preventDefault();

        setDesignation(designation => ({ 
            ...designation,
            name: "",
            isActive: 1
        }));
    }

    const onInputChange = e => {

        const { name, value } = e.target;

        setDesignation(designation => ({ 
            ...designation,
            [name]: value
        }));
    };

    const onActiveChange = e => {
        
        setDesignation(designation => ({ 
            ...designation,
            isActive: designation.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = (e) => {

        e.preventDefault();

        const postDesignation = async () => {

            const result = await postData(designationPath.baseApi, designation);

            if (Object.keys(result).length) {

                onSuccess(`Jabatan ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };
    
        const putDesignation = async () => {

            const result = await putData(`${designationPath.baseApi}/${designation.id}`, designation);

            if (Object.keys(result).length) {
                
                onSuccess(`Jabatan ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        };

        if (designation.id === 0 && designation.name) postDesignation();
        else putDesignation();

    }

    useEffect(() => {

        const setDesignationState = () => {

            const state = refData.current.state;

            if (state) {
                
                setDesignation(state);

                setTitleInfo(`Detail: ${state.name}`);

                setIsResetable(false);

            } else {

                setTitleInfo("Tambah Jabatan Baru");

            }
        }

        setDesignationState();

    }, []);

    useEffect(() => {

        if (designation.name) setIsDisabledSave(false);

    }, [designation.name]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{designationPath.title} | {titleInfo}</h3>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="designation-name" isCol={ true } text="Nama Jabatan" isMandatory={ true } value={ designation.name } />
                                <div className="col-md-9">
                                    <input 
                                        id="designation-name"
                                        type="text" 
                                        className="form-control" 
                                        name="name"
                                        value={designation.name}
                                        onChange={ e => onInputChange(e) } 
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="designation-is-active"
                                            type="checkbox" 
                                            className="custom-control-input" 
                                            name="isActive"
                                            value={ designation.isActive }
                                            checked={ designation.isActive }
                                            onChange={ e => onActiveChange(e) } 
                                        />   
                                        <label htmlFor="designation-is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <ActionButton 
                                        isSave={ true }
                                        isDisabledSave={ isDisabledSave } 
                                        isReset={ isResetable } 
                                        onReset={ onReset }/>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isAction={ true } />
                    </div>
                </div>
                        
            </div>
        </React.Fragment>
    );
};

export default Action;
