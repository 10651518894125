import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LossesList from './List';
import LossesAction from './Action';
import lossesPath from './config';

const PurchaseRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={ lossesPath.basePath } exact component={ LossesList } />
                <Route path={ lossesPath.newPath } exact component={ LossesAction } />
                <Route path={ lossesPath.detailPath } exact component={ LossesAction } />
            </Switch>
        </React.Fragment>
    );
};

export default PurchaseRoutes;
