import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProfitLossList from './List';
import profitLossPath from './config';

const ProfitLossRoutes = () => {
    return (
        <Switch>
            <Route path={profitLossPath.basePath} exact component={ProfitLossList} />
        </Switch>
    );
};

export default ProfitLossRoutes;