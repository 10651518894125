import React from 'react';
import { Route } from 'react-router-dom';
import BalanceSheetList from './List';
import balanceSheetPath from './config';

const ApArHeaderRoutes = () => {
    return (
        <React.Fragment>
            <Route path={ balanceSheetPath.basePath } exact component={ BalanceSheetList } />
        </React.Fragment>
    );
};

export default ApArHeaderRoutes;