import apiPath from '../../../config/api';

const BusinessUnitPath = {
    title: "Modul Unit Bisnis",
    basePath: "/master/unit-bisnis",
    newPath: "/master/unit-bisnis/baru",
    detailPath: "/master/unit-bisnis/opsi/:id",
    baseApi: apiPath.masterBu,
    buCodeFilter: apiPath.masterBuCodeFilter,
    buNameFilter: apiPath.masterBuNmFilter
}

export default BusinessUnitPath;
