import apiPath from '../../../config/api';

const BalanceSheetDetailPath = {
    title: "Detail Neraca",
    basePath: "/akunting/neraca-detail",
    baseApi: apiPath.accountBalanceDetail,
    balanceDetailAsset: apiPath.accountBalanceDetail + '/asset',
    balanceDetailDebt: apiPath.accountBalanceDetail + '/debt',
    balanceDetailExcel: apiPath.accountBalanceDetail + '/excel',
}

export default BalanceSheetDetailPath;
