import apiPath from '../../../config/api';

const ChartPath = {
    title: "Grafik Hutang dan Cicilan",
    basePath: "/akunting/laporan/grafik",
    baseApi: apiPath.accountChart,
    chartSum: apiPath.accountChartSum
}

export default ChartPath;
