import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RoleList from './List';
import RoleAction from './Action';
import rolePath from './config';

const RoleRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={rolePath.basePath} exact component={RoleList} />
                <Route path={rolePath.newPath} exact component={RoleAction} />
                <Route path={rolePath.detailPath} exact component={RoleAction} />
            </Switch>
        </React.Fragment>
    );
};

export default RoleRoutes;
