import React from 'react';

import SubmitButton from './submit-button';
import ClickButton from './click-button';

const ReportButton = ({ isSubmit, onExport, onClick }) => {

    return (

        <div className="btn-group d-flex">
            {
                isSubmit && <SubmitButton className="p-2 flex-fill btn btn-info" submitLabel="Cari" />
            }
            <ClickButton label="Export Excel" className="p-2 flex-fill btn btn-primary" onClick={ onExport } />
            <ClickButton onClick={ onClick } />
        </div>
    );
    
}

export default ReportButton;
