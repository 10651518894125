import React from 'react';
import { Route } from 'react-router-dom';
import CashAuliaList from './List';
import cashAuliaPath from './config';

const CashPoRoutes = () => {
    return (
        <React.Fragment>
            <Route path={cashAuliaPath.basePath} exact component={ CashAuliaList } />
        </React.Fragment>
    );
};

export default CashPoRoutes;
