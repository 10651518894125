import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DateTime, Settings } from 'luxon';
import { formatDate } from 'react-day-picker/moment';
import _ from 'lodash';
import { useGlobalState } from 'state-pool';

import LoaderType from '../../../components/loader-type';
import Label from '../../../components/label';
import Note from '../../../components/note';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import { getData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import purchasePath from './config';

const List = () => {

    Settings.defaultLocale = 'id';

    const [rows, ,] = useGlobalState('rows');

    const rowsRef = useRef(rows);

    const purchaseFilterEntity = {
        'invoiceNo': '',
        'trxDate': undefined,
        'trxDateFrom': undefined,
        'supplierId': 0,
        'supplierName': '',
        'rows': rows
    }

    const [isLoad, setIsLoad] = useState(true);

    const [selectedSupplier, setSelectedSupplier] = useState(constant.DEFAULT_SELECTED);

    const [purchase, setPurchase] = useState([]);

    const [purchaseFilter, setPurchaseFilter] = useState(purchaseFilterEntity);

    /**
     * Get detail route path
     * @param {*} id 
     * @returns 
     */
    const getDetailPath = (id) => purchasePath.detailPath.replace(":id", id);

    /**
     * Get filter data
     */
     const getFilter = useCallback((filter) => {

        const params = { 
            'params': { 
                'invoice-no': _.get(filter, 'invoiceNo'), 
                'trx-date': _.get(filter, 'trxDate'),
                'trx-date-from': _.get(filter, 'trxDateFrom'),
                'supplier-id': _.get(filter, 'supplierId'),
                'rows': _.get(filter, 'rows')
            } 
        };

        return params;

    }, []);

    /**
     * Input change event
     * @param {*} e 
     */
    const onInputChange = e => {

        const { name, value } = e.target

        setPurchaseFilter(purchaseFilter => ({ 
            ...purchaseFilter,
            [name]: value
        }));
    };

    /**
     * Supplier autocomplete event
     * @param {*} opt 
     */
    const onSupplierChange = (opt) => {

        if (opt.id) {

            setPurchaseFilter(purchaseFilter => ({
                ...purchaseFilter,
                supplierId: opt.id,
                supplierName: opt.name
            }));

            setSelectedSupplier(supp => ({
                ...supp,
                value: opt.id,
                label: opt.name
            }));

        }
        
    }

    /**
     * Start date change event
     * @param {*} day 
     */
     const onDayFromChange = day => {

        setPurchaseFilter(purchaseFilter => ({ 
            ...purchaseFilter,
            trxDateFrom: formatDate(day, constant.DEFAULT_DATE),
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));
    }

    /**
     * End dete change event
     * @param {*} day 
     */
    const onDayChange = day => {

        setPurchaseFilter(purchaseFilter => ({ 
            ...purchaseFilter,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));

    };

    /**
     * Get purchases data from API
     */
     const getPurchases = params => {

        setIsLoad(true);

        getData(purchasePath.base, params).then(result => {

            setPurchase(result);
            setIsLoad(false);

        });

    }

    /**
     * Submit event
     * @param {*} e 
     */
    const onSubmit = (e) => {

        e.preventDefault();

        const params = getFilter({ 'invoiceNo': purchaseFilter.invoiceNo, 'trxDate': purchaseFilter.trxDate, 'trxDateFrom': purchaseFilter.trxDateFrom, 'supplierId': purchaseFilter.supplierId, 'rows': purchaseFilter.rows });
        getPurchases(params);

    }

    /**
     * Event triggered when reset button clicked
     * @param {Object} e 
     */
    const onReset = (e) => {

        e.preventDefault();

        setPurchaseFilter(purchaseFilterEntity);

        setSelectedSupplier(constant.DEFAULT_SELECTED);

        const params = getFilter({ 'rows': purchaseFilter.rows });
        getPurchases(params);

    }

    useEffect(() => { 

        const params = getFilter({ 'rows': rowsRef.current });
        
        getPurchases(params);
    
    }, [getFilter]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{purchasePath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="inv-no" text="No Faktur" />
                                    <input 
                                        id="inv-no" 
                                        type="text" 
                                        className="form-control"
                                        name="invoiceNo"
                                        value={ purchaseFilter.invoiceNo }
                                        onChange={ e => onInputChange(e) }
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="supplier" text="Supplier" />
                                    <Select 
                                        id="supplier"
                                        api={ purchasePath.custFind }
                                        entityId="6"
                                        value={ selectedSupplier }
                                        onChange={ onSupplierChange }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal Pembelian Dari" />
                                    <DayPicker id="trx-date" name="trxDateFrom" onDayChange={ onDayFromChange } valueDate={ purchaseFilter.trxDateFrom } />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal Pembelian Ke" />
                                    <DayPicker id="trx-date" name="trxDate" onDayChange={ onDayChange } valueDate={ purchaseFilter.trxDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <FilterButton to={ purchasePath.newPath } newLabel="Tambah Data Pembelian" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Data Pembelian" entity="Pembelian" />
                    </div>
                </div>

                { isLoad && <LoaderType /> }
                {
                    !isLoad && purchase.length > 0 &&
                    <>
                        <br />
                            
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>No Faktur</th>
                                                <th>Tanggal</th>
                                                <th>Nama Suplier</th>
                                                <th>Status</th>
                                                <th>Opsi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                purchase.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ item.invoiceNo }</td>
                                                            <td>{ DateTime.fromISO(item.trxDate).toFormat('dd-LLLL-yyyy') }</td>
                                                            <td>{ item.supplierName }</td>
                                                            <td>{ item.isCommit ? constant.COMMIT : constant.VOID }</td>
                                                            <td><LinkButton to={ {pathname: getDetailPath(item.id), state: item} } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
