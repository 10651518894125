import React from 'react';
import { Route } from 'react-router-dom';
import BankTrxTypeList from './List';
import BankTrxTypeAction from './Action';
import banktrxtypePath from './config';

const BankTrxTypeRoutes = () => {
    return (
        <React.Fragment>
            <Route path={banktrxtypePath.basePath} exact component={BankTrxTypeList} />
            <Route path={banktrxtypePath.newPath} exact component={BankTrxTypeAction} />
            <Route path={banktrxtypePath.detailPath} exact component={BankTrxTypeAction} />
        </React.Fragment>
    );
};

export default BankTrxTypeRoutes;