import React from 'react';
import NumberFormat from 'react-number-format';

/**
 * 
 * @param {Object} parameter - { id: string, display: Boolean, isDisabled: Boolean, value: any, onValueChange: requestCallback } 
 */
const InputNumeral = ({ id, display, isDisabled, value, onValueChange }) => {

    const onClick = e => {

        if (parseFloat(e.target.value) === 0) e.target.value = '';
    }

    if (!display) {

        return(
            <NumberFormat 
                id={ id }
                placeholder="0"
                className="form-control"
                disabled={ isDisabled }
                value={ value }
                isNumericString={ true }
                thousandSeparator={ ',' }
                decimalSeparator={ '.' }
                onValueChange={ values => onValueChange(values) }
                onClick={ e => onClick(e) }
            />
        );

    } else {

        return(
            <NumberFormat 
                id={ id }
                placeholder="0"
                className="form-control"
                disabled={ true }
                value={ value }
                isNumericString={ true }
                thousandSeparator={ ',' }
                decimalSeparator={ '.' }
                decimalScale={ 2 }
            />
        );

    }

}

export default InputNumeral;
