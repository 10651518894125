import React, { useState } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';

import Label from '../../../components/label';
import Select from '../../../components/select';
import TextNumber from '../../../components/text-number';
import InputNumeral from '../../../components/input-numeral';
import DayPicker from '../../../components/day-picker';
import ReportButton from '../../../components/report-button';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import custTrxPath from './config';

const List = () => {

    const [startDate, setStartDate] = useState(DateTime.local());

    const [endDate, setEndDate] = useState(DateTime.local());

    const [custTrx, setCustTrx] = useState([]);

    const [custTrxFilter, setFilterCustTrx] = useState({
        startDate:"",
        endDate:"",
        masterCustomerId: "",
        custName:""
    });

    const [custTrxSummary, setCustTrxSummary] = useState({
        saldoHutangAwal:0,
        saldoHutangAkhir:0,
        saldoTabunganAwal:0,
        saldoTabunganAkhir:0
    });

    const [selectedCustName, setSelectedCustName] = useState(constant.DEFAULT_SELECTED);

    const onStartDayChange = day => setStartDate(formatDate(day, constant.DEFAULT_DATE));

    const onEndDayChange = day => setEndDate(formatDate(day, constant.DEFAULT_DATE));

    const getExcel = async(param, url) => await getFile(url, param);

    const onExport = e => {

        e.preventDefault();

        const params = {
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                endDate: DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT),
                masterCustomerId: custTrxFilter.masterCustomerId,
                custName: custTrxFilter.custName
            }
        };

        getExcel(params, custTrxPath.custTrxDetailExcel).then(response => {

            downloadFile(response);

        });

    }

    const onReset = e => {

        e.preventDefault();

        setFilterCustTrx(trx => ({ 
            ...custTrxFilter,
            startDate:"",
            endDate:"",
            masterCustomerId: "",
            custName:""
        }));

        setStartDate(DateTime.local());
        setEndDate(DateTime.local());

        getCustTrxs();

    }

    const getCustTrxs = async(params) => {

        const result = await getData(custTrxPath.baseApi, params);

        setCustTrx(result[0]);

        getData(custTrxPath.custTrxDetailSum, params).then(result => {

            if (Object.keys(result[0]).length) {

                setCustTrxSummary(sum => ({
                    ...sum,
                    saldoHutangAwal:result[0][0].saldoHutangAwal,
                    saldoHutangAkhir:result[0][0].saldoHutangAkhir,
                    saldoTabunganAwal:result[0][0].saldoTabunganAwal,
                    saldoTabunganAkhir:result[0][0].saldoTabunganAkhir
                }));
            }

        });

    }

    /**
     * Customer change event.
     * @param {Object} opt - Option object.
     */
    const onChangeCustomer = opt => {

        if (opt.id) {

            setFilterCustTrx(trx => ({
                ...trx,
                masterCustomerId: opt.id,
                custName: opt.name
            }));

            setSelectedCustName(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                endDate: DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT),
                masterCustomerId: custTrxFilter.masterCustomerId
            }
        };

        getCustTrxs(params);

    };

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ custTrxPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="startDate" text="Tanggal Dari" />
                                    <DayPicker id="startDate" name="startDate" onDayChange={onStartDayChange} valueDate={ startDate } />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="endDate" text="Tanggal Ke" />
                                    <DayPicker id="endDate" name="endDate" onDayChange={onEndDayChange} valueDate={ endDate } />
                                </div>
                            </div>
                            
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label htmlFor="custTrx-name" text="Nama" />
                                    <Select
                                        id="customer-name"
                                        paramFilter="name"
                                        api={ custTrxPath.custFind }
                                        entityId="3,4"
                                        onChange={ onChangeCustomer }
                                        value={ selectedCustName }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <div className="col-md-12">
                                <h3>Rekapitulasi</h3>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6">
                                <Label text="Saldo Hutang Awal" />
                                <InputNumeral
                                    value={custTrxSummary.saldoHutangAwal}
                                    display={ true }
                                />
                            </div>

                            <div className="col-md-6">
                                <Label text="Saldo Hutang Akhir" />
                                <InputNumeral
                                    value={custTrxSummary.saldoHutangAkhir}
                                    display={ true }
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6">
                                <Label text="Saldo Tabungan Awal" />
                                <InputNumeral
                                    value={custTrxSummary.saldoTabunganAwal}
                                    display={ true }
                                />
                            </div>

                            <div className="col-md-6">
                                <Label text="Saldo Tabungan Akhir" />
                                <InputNumeral
                                    value={custTrxSummary.saldoTabunganAkhir}
                                    display={ true }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tanggal</th>
                                        <th>No Faktur</th>
                                        <th>Deskripsi</th>
                                        <th>Kg</th>
                                        <th>Kendaraan</th>
                                        <th>Pembelian TBS</th>
                                        <th>Biaya Retribusi</th>
                                        <th>Biaya Panen</th>
                                        <th>Bayar Hutang</th>
                                        <th>Tambah Hutang</th>
                                        <th>Setor Tabungan</th>
                                        <th>Tarik Tabungan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        custTrx.map((ct, index) => {
                                            return(
                                                <tr key={ index }>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ `${formatDate(ct.trx_date, 'LL', constant.LOCALE)}` }</td>
                                                    <td>{ ct.invoice_no }</td>
                                                    <td>{ ct.description }</td>
                                                    <td><TextNumber value={ ct.qty } /></td>
                                                    <td>{ ct.vehicle_code }</td>
                                                    <td><TextNumber value={ ct.po_purchase } /></td>
                                                    <td><TextNumber value={ ct.retribution } /></td>
                                                    <td><TextNumber value={ ct.harvest } /></td>
                                                    <td><TextNumber value={ ct.loan_dec } /></td>
                                                    <td><TextNumber value={ ct.loan_inc } /></td>
                                                    <td><TextNumber value={ ct.saving_inc } /></td>
                                                    <td><TextNumber value={ ct.saving_dec } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
