import React from 'react';
import NumberFormat from 'react-number-format';

const TextNumber = ({ value }) => {

    return(
        <NumberFormat 
            value={ value }
            displayType={'text'}
            renderText={value => <span>{value}</span>} 
            isNumericString={ true }
            thousandSeparator={ ',' }
            decimalSeparator={ '.' }
            decimalScale={ 2 }
        />
    );

}

export default TextNumber;
