import apiPath from '../../../config/api';

const PoPurchasePath = {
    title: "TBS - Transaksi Pembelian",
    basePath: "/sawit/pembelian",
    newPath: "/sawit/pembelian/baru",
    detailPath: "/sawit/pembelian/opsi/:id",
    newGns: "/master/barang-dan-jasa/baru",
    base: apiPath.palmOilPurchase,
    baseHistory: apiPath.palmOilPurchaseHistory,
    palmOilSalesFind: apiPath.palmOilSalesFind,
    custFind: apiPath.masterCustFind,
    gnsFind: apiPath.masterGnsFind,
    baseGns: apiPath.masterGns
}

export default PoPurchasePath;
