import apiPath from '../../../config/api';

const BankCashPath = {
    title: "Saldo BRILink Harian",
    path: "/kas/brilink",
    api: apiPath.cashBank,
    apiExcel: apiPath.cashBankExcel
}

export default BankCashPath;
