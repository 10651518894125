import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AddressAction from './Action';
import addressPath from './config';

const AddressRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={addressPath.custNewAddrPath} exact component={AddressAction} />
                <Route path={addressPath.empNewAddrPath} exact component={AddressAction} />
                <Route path={addressPath.custAddrDetailPath} exact component={AddressAction} />
                <Route path={addressPath.emplAddrDetailPath} exact component={AddressAction} />
            </Switch>
        </React.Fragment>
    );
};

export default AddressRoutes;
