import axios from '../config/axios';
import { store } from 'state-pool';

import { onError } from './toaster';
import _ from 'lodash';

export async function checkServer() {
    
    const host = process.env.REACT_APP_SERVER_HOST;
    
    await axios.get(host, { crossDomain: true })
        .then(res => { 
            
            console.log(res);
            return 1;

        }).catch(error => {

            console.log('error', error);
            return 0;

        })
}

export async function postData(api, body) {

    try {

        const token = store.getState('token');

        const user = store.getState('user');

        body['user'] = user;

        const result = await axios.post(api, body, { headers: { 'Authorization': `Bearer ${token.value}` } });
        
        return result.data;

    } catch (error) {

        onError(error);

        return {};

    }

}

export async function putData(api, body) {

    try {

        const token = store.getState('token');

        const user = store.getState('user');

        body['user'] = user;

        const result = await axios.put(api, body, { headers: { 'Authorization': `Bearer ${token.value}` } });
        
        return result.data;

    } catch (error) {

        onError(error);

        return {};

    }

}

export async function getData(api, params) {
    
    try {

        const token = store.getState('token');
        const user = store.getState('user');

        if (_.isEmpty(params)) params = {};
        params['headers'] = { 'Authorization': `Bearer ${token.value}` }
        
        if (_.isEmpty(params['params'])) params['params'] = {};
        params['params']['roleId'] = _.get(user, 'value.roleId');

        const result = await axios.get(api, params);

        return result.data;

    } catch (error) {

        onError(error);
        
        return [];

    }
};

export async function getFile(api, params) {
    
    try {

        const token = store.getState('token');

        const response = await axios.get(api, { 'headers': { 'Authorization': `Bearer ${token.value}` }, responseType: 'arraybuffer', params: params.params });

        return response;

    } catch (error) {

        onError(error);
        
        return [];

    }
};

export async function deleteData(api, params) {

    try {

        const token = store.getState('token');
        const user = store.getState('user');

        if (_.isEmpty(params)) params = {};
        params['headers'] = { 'Authorization': `Bearer ${token.value}` }
        
        if (_.isEmpty(params['params'])) params['params'] = {};
        params['params']['roleId'] = _.get(user, 'value.roleId');

        const result = await axios.delete(api, params);

        return result.data;

    } catch (error) {

        onError(error);
        
        return [];

    }
}
