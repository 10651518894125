import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PoPurchaseList from './List';
import PoPurchaseAction from './Action';
import poPurchasePath from './config';

const PoPurchaseRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={ poPurchasePath.basePath } exact component={ PoPurchaseList } />
                <Route path={ poPurchasePath.newPath } exact component={ PoPurchaseAction } />
                <Route path={ poPurchasePath.detailPath } exact component={ PoPurchaseAction } />
            </Switch>
        </React.Fragment>
    );
};

export default PoPurchaseRoutes;
