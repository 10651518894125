import React, {useState, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import { DateTime } from 'luxon';
import Select from '../../../components/select';
import DayPicker from '../../../components/day-picker';
import Label from '../../../components/label';
import Note from '../../../components/note';
import LinkButton from '../../../components/link-button';
import ActionButton from '../../../components/action-button';
import InputNumeral from '../../../components/input-numeral';
import {postData} from '../../../helper/request-response';
import {onSuccess} from '../../../helper/toaster';
import constant from '../../../config/constant';
import saveCredPath from './config';
import { getPoBalance } from '../../../helper/cash-balance';

const Action = () => {
    
    const data = useLocation();
    
    const history = useHistory();

    const [, , setPoBalance] = useGlobalState('poBalance');

    const [isDisabledSave, setIsDisabledSave] = useState(true);

    const [isResetable, setIsResetable] = useState(true);

    const [isNew, setIsNew] = useState(true);
    
    const [titleInfo, setTitleInfo] = useState("");

    const [selectedCustName, setSelectedCustName] = useState(constant.DEFAULT_SELECTED);

    const [saveCred, setsaveCred] = useState(
        {
            id: 0,
            apArId: 0,
            trxDate: DateTime.local(),
            invoiceNo: `CR${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
            invoiceLineNo: null,
            amount:0,
            masterCustomerId: 0,
            outstanding:0,
            custName:"",
            isChecked: 0,
            savingBalance:0,
            loanBalance:0,
            description: ""
        });

    const onInputChange = e => {

        const { name, value } = e.target;
        
        const saveCredData = {
            ...saveCred,
            [name]: value
        } 

        setsaveCred(saveCredData);
        
    };

    const onAmountChange = values => {

        const { value } = values;
        const val = parseFloat(value);

        setsaveCred(data => ({
            ...data,
            amount: val
        }));

    }

    /**
     * Customer change event.
     * @param {Object} opt - Option object.
     */
    const onChangeCustomer = opt => {

        if (opt.id) {

            setsaveCred(data => ({
                ...data,
                apArId: opt.apArId,
                masterCustomerId: opt.id,
                custName: opt.name,
                savingBalance: opt.apAmount,
                loanBalance: opt.arAmount,
            }));

            setSelectedCustName(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));
        } 
    }

    const onDayChange = day => {

        setsaveCred({ 
            ...saveCred,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    }

    const onReset = e => {

        e.preventDefault();

        setsaveCred(data => ({ 
            ...data,
                id: 0,
                apArId: 0,
                trxDate: DateTime.local(),
                invoiceNo: `CR${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
                invoiceLineNo: null,
                amount:0,
                masterCustomerId: 0,
                outstanding:0,
                custName:"",
                isChecked: 0,
                description: ""
        }));

        setSelectedCustName(name => ({
            ...name,
            label: ""
        }));
    }

    const onSubmit = (e) => {

        e.preventDefault();

        const postBankTrx = async () => {

            setIsDisabledSave(true);
            setPoBalance(0);

            const result = await postData(saveCredPath.baseApi, saveCred);

            if (!_.isEmpty(result)) {

                onSuccess(`Transaksi ${result.invoice_no} berhasil di tambahkan`);

                history.goBack();

                getPoBalance();

            }
        };

        if (saveCred.id === 0) postBankTrx();

    }

    useEffect(() => {

        function setsaveCredState() {

            if (data.state) {

                setsaveCred(data.state)

                setTitleInfo(`Detail: ${data.state.invoiceNo}`);

                setSelectedCustName(code => ({
                    ...code,
                    value: data.state.custId,
                    label: data.state.custName
                }));

                setIsResetable(false);

                setIsNew(false);

            } else {

                setTitleInfo("Tambah Data Baru");

            }
        }

        setsaveCredState();

    }, [data]);

    useEffect(() => {

    if (saveCred.custName && saveCred.description && ( saveCred.amount > 0) ) setIsDisabledSave(false);

    if (!saveCred.custName || !saveCred.description || ( saveCred.amount <= 0) ) setIsDisabledSave(true);

    }, [saveCred.custName ,saveCred.description, saveCred.amount]);

    return (

        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{saveCredPath.title} | {titleInfo}</h3>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="account-type" text="No Pembayaran"/>
                                <input 
                                id="saveCred-number"
                                type="text" 
                                className="form-control" 
                                name="invoiceNo"
                                value={saveCred.invoiceNo}
                                onChange={ e => onInputChange(e) } 
                                readOnly
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="account-type" text="Tanggal"/>
                                <div>
                                    <DayPicker id="trx-date" name="trxDate" valueDate={saveCred.trxDate} onDayChange={onDayChange} />
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                                <Label htmlFor="customer-name" text="Nama Pelanggan" isMandatory={ true } value={ saveCred.custName } />
                                <Select
                                    id="customer-name"
                                    paramFilter="name"
                                    api={ saveCredPath.custFind }
                                    entityId="3,4"
                                    onChange={ onChangeCustomer }
                                    value={ selectedCustName }
                                />
                                <span><LinkButton to={ saveCredPath.newCustomer } className="stretched-link" label="Tambah Pelanggan Baru" /></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                    <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                        {isNew &&
                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="account-type" text="Saldo Tabungan"/>
                                <InputNumeral 
                                    id="debtTotal"
                                    value={ saveCred.savingBalance }
                                    display={ true }
                                    disabled
                                />
                            </div>
                        </div>
                        }

                        {isNew &&
                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="account-type" text="Saldo Pinjaman"/>
                                <InputNumeral 
                                    id="debtTotal"
                                    value={ saveCred.loanBalance }
                                    display={ true }
                                    disabled
                                />
                            </div>
                        </div>
                        }
                        {isNew && parseFloat(saveCred.loanBalance) <= 0 &&
                        <>
                        <div className="form-group row">
                           
                            <div className="col-md-12">
                            <Label htmlFor="account-type" text="Jumlah yang ditabungkan" isMandatory={ true } value={ saveCred.amount } />
                            <InputNumeral 
                                id="amount"
                                value={ saveCred.amount }
                                onValueChange={ onAmountChange }
                            />
                            </div>
                        </div>

                        <div className="form-group row">
                            
                            <div className="col-md-12">
                            <Label htmlFor="saveCred-description" text="Deskripsi"  isMandatory={ true } value={ saveCred.description }/>
                                <input 
                                id="saveCred-description"
                                type="text" 
                                className="form-control" 
                                name="description"
                                value={saveCred.description}
                                onChange={ e => onInputChange(e) } 
                                />
                            </div>
                        </div>
                        </>
                        }
                        
                        <div className="form-group row">
                            <div className="col-md-12">
                            <Label />
                                <ActionButton 
                                    isSave={ isNew }
                                    isDisabledSave={ isDisabledSave } 
                                    isReset={ isResetable } 
                                    onReset={ onReset } />
                            </div>
                        </div>

                        <Note isAction={ true } />
                    
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Action;
