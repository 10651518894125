import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RetrList from './List';
import retrPath from './config';

const RetrRoutes = () => {
    return (
        <Switch>
            <Route path={ retrPath.basePath } exact component={ RetrList } />
        </Switch>
    );
};

export default RetrRoutes;