import React, { useEffect, useState } from 'react';

import constant from '../config/constant';

/**
 * Submit Button
 * @param {Object} props - { isDisabled:Boolean, className: string, submitLabel: string } 
 */

const SubmitButton = (props) => {

    const [isDisabled, setIsDisabled] = useState(false);
    const [className, setClassName] = useState('p-2 flex-fill btn btn-success');
    const [label, setLabel] = useState(constant.SAVE);

    useEffect(() => {

        if (props.isDisabled !== undefined) setIsDisabled(props.isDisabled);

        if (props.className) setClassName(props.className);

        if (props.submitLabel) setLabel(props.submitLabel);

    }, [props]);
    
    return(<button type="submit" disabled={ isDisabled } className={ className }>{ label }</button>);
}

export default SubmitButton;
