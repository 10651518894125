import apiPath from '../../../config/api';

const CustSaldoPath = {
    title: "Laporan Hutang Piutang",
    basePath: "/akunting/laporan/hutang-piutang",
    baseApi: apiPath.accountCustSaldo,
    accountCustSaldoExcel : apiPath.accountCustSaldoExcel
}

export default CustSaldoPath;
