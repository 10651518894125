import apiPath from '../../../config/api';

const RetributionPath = {
    title: "Modul Retribusi", 
    basePath: "/master/retribusi",
    newPath: "/master/retribusi/baru",
    detailPath: "/master/retribusi/opsi/:id",
    baseApi: apiPath.masterRetribution
}

export default RetributionPath;
