import React, { useState, useEffect } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';
import _ from 'lodash';

import Label from '../../../components/label';
import InputNumeral from '../../../components/input-numeral';
import TextNumber from '../../../components/text-number';
import ReportButton from '../../../components/report-button';
import DayPicker from '../../../components/day-picker';
import Select from '../../../components/select';
import LoaderType from '../../../components/loader-type';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import harvestPath from './config';

const List = () => {

    const [isLoad, setIsLoad] = useState(true);

    const [startDate, setStartDate] = useState(DateTime.local());

    const [endDate, setEndDate] = useState(DateTime.local());

    const [selectedTrack, setSelectedTrack] = useState(constant.DEFAULT_SELECTED);

    const [retributionId, setRetributionId] = useState();

    const [harvestCost, setHarvestCost] = useState([]);

    const [harvestCostSummary, setHarvestCostSummary] = useState({
        invoices: 0,
        farmer: 0,
        agent: 0,
        qty: 0,
        amount: 0 
    });

    const onStartDateChange = day => {

        setStartDate(formatDate(day, constant.DEFAULT_DATE));
        setEndDate(formatDate(day, constant.DEFAULT_DATE));
    };

    const onEndDateChange = day => setEndDate(formatDate(day, constant.DEFAULT_DATE));

    const getHarvestCost = () => {

        setIsLoad(true);

        const params = getParams();

        getData(harvestPath.base, params).then(result => setHarvestCost(result[0]));

        getData(harvestPath.palmOilHarvestSum, params).then(result => {

            if (!_.isEmpty(result[0])) {

                setHarvestCostSummary(sum => ({
                    ...sum,
                    invoices: result[0][0].purchaseInvNo,
                    farmer: result[0][0].farmerCount,
                    agent: result[0][0].agentCount,
                    qty: result[0][0].qty,
                    amount: result[0][0].amount,
                }));
            }

            setIsLoad(false);

        });

    }

    const getParams = () => {

        const params = {
            params: { 
                'start-date': DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                'end-date': DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT),
                'retribution-id': retributionId
            }
        };

        return params;
    }

    const onNameChange = opt => {

        if (opt.id) {
            
            setSelectedTrack(name => ({
                ...name,
                value: opt.id,
                label: opt.label
            }));

            setRetributionId(opt.id);

        }

    }
    
    const onExport = e => {

        e.preventDefault();

        const params = getParams();

        getFile(harvestPath.palmOilHarvestExcel, params).then(response => downloadFile(response));

    }

    const onSubmit = e => {

        e.preventDefault();

        getHarvestCost();

    }

    const onReset = e => {

        e.preventDefault();

        setStartDate(DateTime.local());

        setSelectedTrack(constant.DEFAULT_SELECTED);

        getHarvestCost();

    }

    useEffect(getHarvestCost, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ harvestPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="start-date" text="Tanggal Dari" />
                                    <DayPicker id="start-date" name="start-date" onDayChange={onStartDateChange} valueDate={ startDate } />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="end-date" text="Tanggal Ke" />
                                    <DayPicker id="end-date" name="end-date" onDayChange={onEndDateChange} valueDate={ endDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                
                                <div className="col-md-12">
                                    <Label htmlFor="retri-name" text="Jalur Retribusi" />
                                    <Select
                                        id="retri-name"
                                        paramFilter="name"
                                        api={ harvestPath.palmOilRetrFind }
                                        onChange={ onNameChange }
                                        value={ selectedTrack }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>

                    {
                        !isLoad &&

                        <div className="col-md-6">
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <h3>Rekapitulasi</h3>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label text="Jumlah Petani" />
                                    <InputNumeral
                                        value={ harvestCostSummary.farmer }
                                        display={ true }
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Label text="Jumlah Agen" />
                                    <InputNumeral
                                        value={ harvestCostSummary.agent }
                                        display={ true }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label text="Total Kilogram (Kg)" />
                                    <InputNumeral
                                        value={ harvestCostSummary.qty }
                                        display={ true }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <Label text="Total Pembelian (Rp)" />
                                    <InputNumeral
                                        value={ harvestCostSummary.amount }
                                        display={ true }
                                    />
                                </div>
                            </div>
                        </div>
                
                    }

                </div>

                {
                    isLoad &&
                    <LoaderType />
                }

                {
                    !isLoad && harvestCost.length > 0 &&
                    <>
                        <br />

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Tanggal</th>
                                                <th>Faktur Penjualan</th>
                                                <th>Faktur Pembelian</th>
                                                <th>Kode Kendaraan</th>
                                                <th>Nama Pelanggan</th>
                                                <th>Jalur</th>
                                                <th>Jumlah (Kg)</th>
                                                <th>Harga (Rp)</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                harvestCost.map((co, index) => {
                                                    return(
                                                        <tr key={ index }>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ formatDate(co.trxDate, 'DD MMM YY', constant.LOCALE) }</td>
                                                            <td>{ co.salesInvNo }</td>
                                                            <td>{ co.purchaseInvNo }</td>
                                                            <td>{ co.vehicleCode }</td>
                                                            <td>{ co.farmerName }</td>
                                                            <td>{ co.retribution }</td>
                                                            <td><TextNumber value={ co.qty } /></td>
                                                            <td><TextNumber value={ co.purchasePrice } /></td>
                                                            <td><TextNumber value={ co.amount } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
