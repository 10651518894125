import apiPath from '../../../config/api';

const RetailCashPath = {
    title: "Saldo Toko Harian",
    path: "/kas/toko",
    api: apiPath.cashRetail,
    apiExcel: apiPath.cashRetailExcel
}

export default RetailCashPath;
