import apiPath from '../../../config/api';

const PurchaseSalesPath = {
    title: "Retail - Pembelian dan Penjualan",
    basePath: "/pupuk-dan-pertanian/jual-beli",
    base: apiPath.retailPurchaseSales,
    purchaseSalesExcel: apiPath.retailPurchaseSalesExcel,
    purchaseSalesFind: apiPath.retailPurchaseSalesFind
}

export default PurchaseSalesPath;
