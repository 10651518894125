import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import LoaderType from '../../components/loader-type';
import CashBalance from '../../components/cash-balance';
import { getData } from '../../helper/request-response';

const BankBalance = () => {

    const [bankBalance, setBankBalance] = useState([]);
    const [isLoad, setIsLoad] = useState(true);

    useEffect(() => { 

        getData('/dashboard/bank').then(result => {
            
            if (!_.isEmpty(result)) {

                setBankBalance(result);
                setIsLoad(false);
            }

        });

    }, []);

    return(
        <div className="card">
            <div className="card-header">
                <h2 className="h6 text-uppercase mb-0">Saldo Di Bank</h2>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        {
                            isLoad && <LoaderType />
                        }
                        {
                            !isLoad &&
                            bankBalance.map((balance, index) => {
                                return(
                                    <CashBalance key={index} balance={ balance.balance } label={ `${balance.bankName} - ${balance.accountName}` } />
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankBalance;