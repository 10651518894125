import React, { useState, useEffect } from 'react';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import Select from '../../../components/select';
import { getData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import retributionPath from './config';

const List = () => {

    const [retributions, setRetributions] = useState([]);

    const [selectedCode, setSelectedCode] = useState(constant.DEFAULT_SELECTED);

    const [selectedName, setSelectedName] = useState(constant.DEFAULT_SELECTED);

    const [retributionFilter, setRetributionFilter] = useState({
        name: "",
        code: ""
    });

    const getDetailPath = (id) => retributionPath.detailPath.replace(":id", id);

    const onCodeChange = opt => {

        if (opt.id) {

            setRetributionFilter(ret => ({ 
                ...ret,
                code: opt.code
            }));

            setSelectedCode(code => ({
                ...code,
                value: opt.id,
                label: opt.code
            }));

        }

        
    }

    const onNameChange = opt => {

        if (opt.id) {

            setRetributionFilter(ret => ({ 
                ...ret,
                name: opt.name
            }));

            setSelectedName(name => ({
                ...name,
                value: opt.id,
                label: opt.name
            }));

        }

    }

    const onSubmit = e => {

        e.preventDefault();

        const params = {
            params: { 
                name: retributionFilter.name
            }
        };

        getRetributions(params).then(result => {
            setRetributions(result);
        });

    };

    const onReset = e => {

        e.preventDefault();

        setRetributionFilter(role => ({ 
            ...role,
            name: ""
        }));

        getRetributions().then(result => {
            setRetributions(result);
        });
    }

    const getRetributions = async(param) => {

        const result = await getData(retributionPath.baseApi, param);

        return result;
    }

    useEffect(() => { 
        
        getRetributions().then(result => {
            setRetributions(result);
        });
        
    }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ retributionPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >
                            <div className="form-group row">
                                <Label htmlFor="retri-code" isCol={ true } text="Kode Retribusi" />
                                <div className="col-md-9">
                                    <Select
                                        id="retri-code"
                                        paramFilter="code"
                                        api={ retributionPath.retributionFind }
                                        onChange={ onCodeChange }
                                        value={ selectedCode }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="retri-name" isCol={ true } text="Nama Retribusi" />
                                <div className="col-md-9">
                                    <Select
                                        id="retri-name"
                                        paramFilter="name"
                                        api={ retributionPath.retributionFind }
                                        onChange={ onNameChange }
                                        value={ selectedName }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ retributionPath.newPath } newLabel="Tambah Retribusi Baru" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Retribusi Baru" entity="Retribusi" />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Kode</th>
                                        <th>Nama</th>
                                        <th>Retribusi (Rp)</th>
                                        <th>Opsi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        retributions.map((retribution, index) => {
                                            return(
                                                <tr key={ index }>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ retribution.code }</td>
                                                    <td>{ retribution.name }</td>
                                                    <td>{ retribution.amount }</td>
                                                    <td><LinkButton to={ {pathname: getDetailPath(retribution.id), state: retribution} } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
