import apiPath from '../../../config/api';

const SalesPath = {
    title: "Kelapa Sawit dan PBDO - Transaksi Penjualan",
    basePath: "/sawit/penjualan",
    newPath: "/sawit/penjualan/baru",
    detailPath: "/sawit/penjualan/opsi/:id",
    base: apiPath.palmOilSales,
    custFind: apiPath.masterCustFind,
    emplFind: apiPath.masterEmplFind,
    vehicleFind: apiPath.masterVehicleFind, 
    gns: apiPath.masterGns,
    gnsFind: apiPath.masterGnsFind,
    purchaseBulk: apiPath.palmOilPurchaseBulk,
    purchasesFind: apiPath.palmOilPurchaseSales,
    purchaseHistory: apiPath.palmOilPurchaseHistories,
    salesHistory: apiPath.palmOilSalesHistory
}

export default SalesPath;
