import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useGlobalState } from 'state-pool';

const ProtectedRoute = ({ component: Component, ...rest }) => {

    const [, , setToken] = useGlobalState('token');
    const [, , setUser] = useGlobalState('user');
    const [isAuthenticated, , setIsAuthenticated] = useGlobalState('isAuthenticated');

    return(
        <Route 
            { ...rest }
            render={ props => {

                if (isAuthenticated) {

                    return ( <Component { ...props } /> );

                } else {

                    setToken('');
                    setUser({});
                    setIsAuthenticated(false);

                    return(
                        <Redirect 
                            to={{
                                    pathname: "/login",
                                    state: {
                                        from: props.location
                                    }
                                }} 
                        />
                    );
                }

            }}
        />
    );
}

export default ProtectedRoute;