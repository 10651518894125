import apiPath from '../../../config/api';

const BankTrxTypePath = {
    title: "Modul Jenis Transaksi Bank",
    basePath: "/brilink/jenis-transaksi",
    newPath: "/brilink/jenis-transaksi/baru",
    detailPath: "/brilink/jenis-transaksi/opsi/:id",
    newCoa: "/master/coa/baru",
    baseApi: apiPath.masterBrilinkType,
    baseApiCoa: apiPath.masterCoa,
    cashCoa: apiPath.masterCoaCash
}

export default BankTrxTypePath;
