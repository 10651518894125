import React from 'react';
import { Route } from 'react-router-dom';
import CashPbdoList from './List';
import cashPbdoPath from './config';

const CashPbdoRoutes = () => {
    return (
        <React.Fragment>
            <Route path={cashPbdoPath.path} exact component={ CashPbdoList } />
        </React.Fragment>
    );
};

export default CashPbdoRoutes;
