const EmployeePath = {
    title: "Modul Karyawan",
    basePath: "/master/karyawan",
    newPath: "/master/employee/baru",
    detailPath: "/master/employee/opsi/:id",
    newDesignation: "/master/designation/baru",
    baseApi: "/master/employee",
    baseApiDesignation: "/master/designation",
    baseApiAddress: "/master/address"
}

export default EmployeePath;
