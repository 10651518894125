import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import LoaderType from '../components/loader-type';
import { getData } from '../helper/request-response';
import constant from '../config/constant';
import apiPath from '../config/api';

const Sidebar = () => {
    
    const [menu, setMenu] = useState([]);
    const [isLoad, setIsLoad] = useState(true);

    useEffect(() => {

        const getMenu = () => {

            getData(`${apiPath.adminMenuAccess}/role-access`).then(result => {

                setMenu(result);

                setIsLoad(false);

            });
        }

        getMenu();

    }, []);

    const menuList = menu.map(({id, name, label, path, icon, subs}) => {
        return(
            <li key={id} className="sidebar-list-item">
                <Link to={path} data-toggle="collapse" data-target={`#${name}`} aria-expanded="false" aria-controls={name} className="sidebar-link text-muted">
                    <i className={`${icon} mr-3 text-gray`}></i><span>{label}</span>
                </Link>
                <div id={name} className="collapse">
                    <ul className="sidebar-menu list-unstyled border-left border-primary border-thick">
                        {
                            subs.map(({id, label, path}) => {
                                return(
                                    <li key={id} className="sidebar-list-item">
                                        <NavLink to={path} activeClassName="active" className="sidebar-link text-muted pl-lg-5">{label}</NavLink>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </li>
        );
    });

    return(
        <div id="sidebar" className="sidebar py-3">
            {
                isLoad &&
                <LoaderType />
            }
            { 
                !isLoad && 
                <>
                    <div className="text-gray-400 text-uppercase px-3 px-lg-4 py-4 font-weight-bold small headings-font-family">{constant.MENU}</div>
                    <ul className="sidebar-menu list-unstyled">
                        {menuList}
                    </ul>
                </>
            }
        </div>
    );
}

export default Sidebar;
