import apiPath from '../../../config/api';

const CSDetailPath = {
    title: "Laporan Hutang Piutang Detail",
    basePath: "/akunting/laporan/hutang-piutang-detail",
    baseApi: apiPath.accountCustSaldoDetail,
    custSaldoDetailExcel : apiPath.accountCustSaldoDetailExcel,
    custFind: apiPath.masterCustFind
}

export default CSDetailPath;
