import apiPath from '../../../config/api';

const PoCashoutPath = {
    title: "TBS - Laporan Pengambilan Uang",
    basePath: "/sawit/laporan/pencairan",
    base: apiPath.palmOilCashout,
    palmoilCashoutSum: apiPath.palmOilCashoutSum,
    palmoilCashoutPurchase: apiPath.palmOilCashoutPurchases,
    palmoilCashoutPurchaseExcel: apiPath.palmOilCashoutPurchasesExcel
}

export default PoCashoutPath;
