import React from 'react';
import { Route } from 'react-router-dom';
import CashPoList from './List';
import cashPoPath from './config';

const CashPoRoutes = () => {
    return (
        <React.Fragment>
            <Route path={cashPoPath.path} exact component={ CashPoList } />
        </React.Fragment>
    );
};

export default CashPoRoutes;
