import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ChartList from './List';
import chartPath from './config';

const ChartRoutes = () => {
    return (
        <Switch>
            <Route path={chartPath.basePath} exact component={ChartList} />
        </Switch>
    );
};

export default ChartRoutes;