import apiPath from '../../../config/api';

const RolePath = {
    title: "Modul Role",
    basePath: "/admin/role",
    newPath: "/admin/role/baru",
    detailPath: "/admin/role/opsi/:id",
    baseApi: apiPath.adminRole,
    nmFilterApi: apiPath.adminRoleFind
}

export default RolePath;
