import React, { useState, useEffect } from 'react';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import { getData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import custTypePath from './config';

const List = () => {

    const [customerTypes, setCustomerTypes] = useState([]);

    const [customerTypeFilter, setCustomerTypeFilter] = useState({
        name: "",
        isActive: 1
    });

    const getDetailPath = (id) => custTypePath.detailPath.replace(":id", id);

    const onFilterChange = e => {

        const { name, value } = e.target; 

        setCustomerTypeFilter(type => ({ 
            ...type,
            [name]: value
        }));
    };

    const onActiveChange = e => {

        setCustomerTypeFilter(type => ({ 
            ...type,
            isActive: type.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: { 
                name: customerTypeFilter.name,
                'is-active': customerTypeFilter.isActive 
            }
        };

        getCustomerType(params);
        
    };

    const getCustomerType = async(params) => {

        const result = await getData(custTypePath.baseApi, params);
        
        setCustomerTypes(result);

    }

    const onReset = e => {

        e.preventDefault();

        setCustomerTypeFilter({ 
            ...customerTypeFilter,
            name: "",
            isActive: 1
        });

        getCustomerType();

    }

    useEffect(() => {  getCustomerType() }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{custTypePath.title}</h3>
            </div>
            <div className="card-body">

                <div className="row">

                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >
                            <div className="form-group row">
                                <Label htmlFor="role-name" isCol={ true } text="Tipe Pelanggan" />
                                <div className="col-md-9">
                                    <input 
                                        id="role-name" 
                                        type="text" 
                                        className="form-control"
                                        name="name"
                                        value={customerTypeFilter.name}
                                        onChange={ e => onFilterChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="role-is-active"
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="isActive"
                                            value={ customerTypeFilter.isActive }
                                            checked={ customerTypeFilter.isActive }
                                            onChange={ e => onActiveChange(e) } />   
                                        <label htmlFor="role-is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ custTypePath.newPath } newLabel="Tambah Tipe Pelanggan Baru" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Tipe Pelanggan Baru" entity="Tipe Pelanggan" />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tipe Pelanggan</th>
                                        <th>Status</th>
                                        <th>Opsi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        customerTypes.map((custType, index) => {
                                            return(
                                                <tr key={index}>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ custType.name }</td>
                                                    <td>{ custType.isActive === 1 ? constant.ACTIVE : constant.INACTIVE }</td>
                                                    <td><LinkButton to={ {pathname: getDetailPath(custType.id), state: custType} } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                    

                    
            </div>
        </React.Fragment>
    );
};

export default List;
