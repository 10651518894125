import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CustTypeList from './List';
import CustTypeAction from './Action';
import custTypePath from './config';

const CustomerTypeRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={custTypePath.basePath} exact component={CustTypeList} />
                <Route path={custTypePath.newPath} exact component={CustTypeAction} />
                <Route path={custTypePath.detailPath} exact component={CustTypeAction} />
            </Switch>
        </React.Fragment>
    );
};

export default CustomerTypeRoutes;
