import apiPath from '../../../config/api';

const PoCashPath = {
    title: "Saldo Kas TBS",
    path: "/kas/tbs",
    api: apiPath.cashPo,
    apiExcel: apiPath.cashPoExcel
}

export default PoCashPath;
