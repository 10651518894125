import React from 'react';

import SubmitButton from './submit-button';
import BackButton from './back-button';
import ClickButton from './click-button';
import LinkButton from './link-button';
import constant from '../config/constant';

/**
 * Action Button
 * @param {Object} props - { isNew: Boolean, isCancel: Boolean, isDisabledSave: Boolean, isDisabledAdd: Boolean, isAddWithPage: Boolean, isReset: Boolean, newPagePath: Object, isModal: Boolean, addLabel: string, onReset: requestCallback, onCancel: requestCallback, openModal: requestCallback }
 */
const ActionButtonNoPrint = (props) => {

    return (
        <div className="btn-group d-flex">
            
            {
                !props.isSave && props.isCancel &&
                <ClickButton onClick={ props.openCancel } className="p-2 flex-fill btn btn-danger" label={ constant.CANCEL_TRANS } />
            }

            {
                props.isSave && <SubmitButton isDisabled={ props.isDisabledSave } />
            }

            {
                props.isSave && props.isModal &&
                <ClickButton isDisabled={ props.isDisabledAdd } onClick={ props.openModal } className="p-2 flex-fill btn btn-primary" label={ props.addLabel } />
            }

            {
                props.isAddWithPage &&
                <LinkButton to={ props.newPagePath } className="p-2 flex-fill btn btn-primary" label={ props.addLabel } />
            }

            {
                props.isReset &&
                <ClickButton onClick={ props.onReset } className="p-2 flex-fill btn btn-secondary" label={ constant.RESET } />
            }

            <BackButton />
        </div>
    );


}

export default ActionButtonNoPrint;
