import React, { useState, useEffect } from 'react';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import AutoComplete from '../../../components/async-select';
import { getData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import menuPath from './config';

const List = () => {

    const [menu, setMenu] = useState([]);

    const [menuFilter, setMenuFilter] = useState({
        label: "",
        submenuLabel: "",
        isActive: 1
    });

    const getDetailPath = (parentId) => menuPath.detailPath.replace(":parentId", parentId);

    const onSelectChange = opt => {

        setMenuFilter(menu => ({ 
            ...menu,
            label: opt.label
        }));
    }

    const onSubSelectChange = opt => {

        setMenuFilter(menu => ({ 
            ...menu,
            submenuLabel: opt.label
        }));
    }
    
    const onActiveChange = e => {

        setMenuFilter(menu => ({ 
            ...menu,
            isActive: menuFilter.isActive === 1 ? 0 : 1
        }));
        
    };

    const onSubmit = e => {

        e.preventDefault();

        const params = { params: { label: menuFilter.label, submenuLabel: menuFilter.submenuLabel, 'is-active': menuFilter.isActive }};

        getMenu(params);

    };

    const getMenu = async(params) => {

        const result = await getData(menuPath.menuGroupApi, params);
            
        setMenu(result);
        
    }

    const onReset = e => {
        
        e.preventDefault();

        setMenuFilter({ 
            ...menuFilter,
            label: "",
            submenuLabel: "",
            isActive: 1
        });

        getMenu();

    }

    useEffect(() => { getMenu() }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ menuPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >
                            <div className="form-group row">
                                <Label htmlFor="group-menu-label" isCol={ true } text="Menu Group" />
                                <div className="col-md-9">
                                    <AutoComplete 
                                        id="group-menu-label"
                                        filterApi={ menuPath.menuGroupFilterLabelApi }
                                        onSelectChange={ onSelectChange } 
                                        filter={ {label: menuFilter.label} }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="sub-menu-label" isCol={ true } text="Sub Menu" />
                                <div className="col-md-9">
                                    <AutoComplete 
                                        id="sub-menu-label"
                                        filterApi={ menuPath.menuSubFilterLabelApi }
                                        onSelectChange={ onSubSelectChange } 
                                        filter={ {label: menuFilter.submenuLabel} }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="is-active"
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="isActive"
                                            value={ menuFilter.isActive }
                                            checked={ menuFilter.isActive }
                                            onChange={ e => onActiveChange(e) }
                                        />   
                                        <label htmlFor="is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ menuPath.newPath } newLabel="Tambah Menu Baru" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Menu Baru" entity="Menu" />
                    </div>
                </div>

                <br />

                <div className="row"></div>
                        

                <div className="col-lg-12 mb-4">
                    <div className="row">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Menu Group</th>
                                        <th>Icon</th>
                                        <th>Status</th>
                                        <th>Sub Menu</th>
                                        <th>Opsi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        menu.map((menu, index) => {
                                            return(
                                                <tr key={ index }>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ menu.label }</td>
                                                    <td><i className={ menu.icon }></i></td>
                                                    <td>{ menu.isActive === 1 ? constant.ACTIVE : constant.INACTIVE }</td>
                                                    <td>
                                                        {
                                                            menu.subs.map((sub, ix) => {

                                                                if (ix + 1 === menu.subs.length)

                                                                    return (<span key={ix}>{sub.label}</span>);

                                                                else

                                                                    return(
                                                                        <React.Fragment key={ix}>
                                                                            <span key={ix}>{sub.label}</span><br/>
                                                                        </React.Fragment>
                                                                    );
                                                            })
                                                        }
                                                    </td>
                                                    <td><LinkButton to={ {pathname: getDetailPath(menu.id), state: menu} } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
