import React, {useState, useEffect} from 'react';
import { formatDate } from 'react-day-picker/moment';

import TextNumber from '../../../components/text-number';
import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import {getData} from '../../../helper/request-response';
import DayPicker from '../../../components/day-picker';
import constant from '../../../config/constant';
import saveCredPath from './config';

const List = () => {

    const [saveCreds, setSaveCreds] = useState([]);

    const getDetailPath = (invoiceNo) => saveCredPath.detailPath.replace(":id", invoiceNo);

    const [saveCredFilter, setFilterSaveCred] = useState({
        trxDate: "",
        customerName: ""
    });

    const onInputChange = e => {

        setFilterSaveCred({ 
            ...saveCredFilter,
            [e.target.name]: e.target.value
        });
    };

    const onDayChange = day => {

        setFilterSaveCred(trx => ({ 
            ...trx,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));
    }

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                trxDate: saveCredFilter.trxDate,
                customerName: saveCredFilter.customerName,
            }
        };

        getSaveCreds(params);

    };

    const getSaveCreds = async(params) => {

        const result = await getData(saveCredPath.baseApi, params);
            
        setSaveCreds(result);

    }

    const onReset = e => {

        e.preventDefault();

        setFilterSaveCred(trx => ({ 
            ...saveCredFilter,
            trxDate: "",
            customerName: ""
        }));

        getSaveCreds();

    }

    useEffect(() => { 

        getSaveCreds();

     }, []);
    

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{saveCredPath.title}</h3>
            </div>
            <div className="card-body">
            <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <Label htmlFor="trx-date" isCol={ true } text="Tanggal Pembelian" />
                                <div className="col-md-9">
                                    <DayPicker id="trx-date" name="trxDate" onDayChange={ onDayChange } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="saveCred-customerName" isCol={ true } text="Nama Pelanggan" />
                                <div className="col-md-9">
                                    <input 
                                        id="saveCred-customerName" 
                                        type="text" 
                                        className="form-control"
                                        name="customerName"
                                        value={ saveCredFilter.customerName }
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ saveCredPath.newPath } newLabel="Tambah Setoran Baru" onClick={ onReset } />
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Transaksi Baru" entity="Transaksi" />
                    </div>
                </div>

                <div className="col-lg-12 mb-4">
                    <div className="row">
                        <table className="table table-striped table-hover card-text">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Tanggal</th>
                                    <th>Nama Pelanggan</th>
                                    <th>Deskripsi</th>
                                    <th>Jumlah</th>
                                    <th>Opsi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    saveCreds.map((saveCred, index) => {
                                        const ket = saveCred.description.split('-');
                                        return(
                                            <tr key={index}>
                                                <th scope="row">{ index + 1 }</th>
                                                <td>{ `${formatDate(saveCred.trxDate, 'LL', constant.LOCALE)}` } </td>
                                                <td>{ saveCred.custName }</td>
                                                <td>{ ket.length > 1 ? ket[1] : 'TABUNGAN PETANI' }</td>
                                                <td><TextNumber value={ saveCred.amount } /></td>
                                                <td><LinkButton to={ {pathname: getDetailPath(saveCred.invoiceNo), state: saveCred} } /></td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
