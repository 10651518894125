import React from 'react';

import TextNumber from './text-number';

const MaxTransfer = ({ coaOut, coaValue, label, balance }) => {

    return (
        coaOut === coaValue &&
        <p>
            <span><strong>Maksimal Transfer dari { label } <u className="text-danger"><TextNumber value={ balance } /></u></strong></span>
        </p>
    )
}

export default MaxTransfer;
