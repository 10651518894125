import React, { useState } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';

import Label from '../../../components/label';
import InputNumeral from '../../../components/input-numeral';
import TextNumber from '../../../components/text-number';
import DayPicker from '../../../components/day-picker';
import ReportButton from '../../../components/report-button';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import profitLossPath from './config';

const List = () => {

    const [startDate, setStartDate] = useState(DateTime.local());

    const [endDate, setEndDate] = useState(DateTime.local());

    const [profitLoss, setProfitLoss] = useState([]);

    const [profitLossFilter, setFilterProfitLoss] = useState({
        startDate:"",
        endDate:""
    });

    const [profitLossSummary, setProfitLossSummary] = useState({
        subTotal:0,
        cost:0,
        profit:0,
        percentage:0,
        sales:0,
        purchase:0
    });

    const onStartDayChange = day => setStartDate(formatDate(day, constant.DEFAULT_DATE));

    const onEndDayChange = day => setEndDate(formatDate(day, constant.DEFAULT_DATE));

    const getExcel = async(param, url) => await getFile(url, param);

    const onExport = e => {

        e.preventDefault();

        const params = {
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                endDate: DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT)
            }
        };

        getExcel(params, profitLossPath.profitLossExcel).then(response => {

            downloadFile(response);

        });

    }

    const onReset = e => {

        e.preventDefault();

        setFilterProfitLoss(trx => ({ 
            ...profitLossFilter,
            startDate:"",
            endDate:""
        }));

        setStartDate(DateTime.local());
        setEndDate(DateTime.local());

        getProfitLoss();

    }

    const getProfitLoss = async(params) => {

        const result = await getData(profitLossPath.baseApi, params);

        setProfitLoss(result[0]);

        getData(profitLossPath.profitLossSum, params).then(result => {

            if (Object.keys(result[0]).length) {

                setProfitLossSummary(sum => ({
                    ...sum,
                    subTotal: result[0][0].sub_total,
                    cost: result[0][0].cost,
                    profit: result[0][0].profit,
                    percentage: result[0][0].percentage,
                    sales:result[0][0].sales,
                    purchase:result[0][0].purchase
                }));
            }

        });

    }

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                endDate: DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT)
            }
        };

        getProfitLoss(params);

    };

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ profitLossPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="startDate" text="Tanggal Dari" />
                                    <DayPicker id="startDate" name="startDate" onDayChange={onStartDayChange} valueDate={ startDate } />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="endDate" text="Tanggal Ke" />
                                    <DayPicker id="endDate" name="endDate" onDayChange={onEndDayChange} valueDate={ endDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <div className="col-md-12">
                                <h3>Rekapitulasi</h3>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6">
                                <Label text="Total Keuntungan" />
                                <InputNumeral
                                    value={profitLossSummary.subTotal}
                                    display={ true }
                                />
                            </div>

                            <div className="col-md-6">
                                <Label text="Beban Usaha" />
                                <InputNumeral
                                    value={profitLossSummary.cost}
                                    display={ true }
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6">
                                <Label text="Total Penjualan" />
                                <InputNumeral
                                    value={profitLossSummary.sales}
                                    display={ true }
                                />
                            </div>

                            <div className="col-md-6">
                                <Label text="Total HPP" />
                                <InputNumeral
                                    value={profitLossSummary.purchase}
                                    display={ true }
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6">
                                <Label text="Laba Bersih" />
                                <InputNumeral
                                    value={profitLossSummary.profit}
                                    display={ true }
                                />
                            </div>

                            <div className="col-md-6">
                                <Label text="Persentase" />
                                <InputNumeral
                                    value={profitLossSummary.percentage}
                                    display={ true }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                

                <br />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Bisnis Unit</th>
                                        <th>Penjualan</th>
                                        <th>HPP</th>
                                        <th>PPH 21</th>
                                        <th>PPH 22</th>
                                        <th>Rugi/Laba</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        profitLoss.map((pl, index) => {
                                            return(
                                                <tr key={ index }>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ pl.business_unit }</td>
                                                    <td><TextNumber value={ pl.sales } /></td>
                                                    <td><TextNumber value={ pl.buy } /></td>
                                                    <td><TextNumber value={ pl.pph_21 } /></td>
                                                    <td><TextNumber value={ pl.pph_22 } /></td>
                                                    <td><TextNumber value={ pl.profit_amount } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
