import apiPath from '../../../config/api';

const ProfitLossPath = {
    title: "Laporan Rugi Laba",
    basePath: "/akunting/laporan/rugi-laba",
    baseApi: apiPath.accountProfitLoss,
    profitLossSum : apiPath.accountProfitLossSum,
    profitLossExcel : apiPath.accountProfitLossExcel
}

export default ProfitLossPath;
