import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-responsive-modal/styles.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { store } from 'state-pool';

import ProtectedRoute from './layout/ProtectedRoute';
import ProtectedContent from './layout/ProtectedContent';
import Login from './layout/Login';

store.setState('token', '');
store.setState('user', {});
store.setState('isAuthenticated', false);
store.setState('rows', process.env.REACT_APP_DATA_ROWS);
store.setState('appTitle', process.env.REACT_APP_TITLE);
store.setState('appEntity', process.env.REACT_APP_ENTITY);
store.setState('appPos', process.env.REACT_APP_POS);
store.setState('loginImage', process.env.REACT_APP_LOGIN_IMAGE);
store.setState('isShowPettyCash', process.env.REACT_APP_IS_SHOW_PETTY_CASH);
store.setState('pettyCashLabel', process.env.REACT_APP_PETTY_CASH_LABEL);
store.setState('auliaBalance', 0);
store.setState('isShowPBDOCash', process.env.REACT_APP_IS_SHOW_PBDO_CASH);
store.setState('PBDOCashLabel', process.env.REACT_APP_PBDO_CASH_LABEL);
store.setState('pbdoBalance', 0);
store.setState('isShowTBSCash', process.env.REACT_APP_IS_SHOW_TBS_CASH);
store.setState('TBSCashLabel', process.env.REACT_APP_TBS_CASH_LABEL);
store.setState('poBalance', 0);
store.setState('isShowRetailCash', process.env.REACT_APP_IS_SHOW_RETAIL_CASH);
store.setState('retailCashLabel', process.env.REACT_APP_RETAIL_CASH_LABEL);
store.setState('retailBalance', 0);
store.setState('isShowBankCash', process.env.REACT_APP_IS_SHOW_BANK_CASH);
store.setState('bankCashLabel', process.env.REACT_APP_BANK_CASH);
store.setState('bankBalance', 0);
store.setState('poSalesLabel', process.env.REACT_APP_PO_SALES_TRX);
store.setState('poPurchaseLabel', process.env.REACT_APP_PO_PURCHASE_TRX);
store.setState('bankTrxLabel', process.env.REACT_APP_BANK_TRX);
store.setState('retailTrxLabel', process.env.REACT_APP_RETAIL_TRX);
store.setState('navbar', process.env.REACT_APP_NAVBAR);
store.setState('navbarTitle', process.env.REACT_APP_NAVBAR_TITLE);

function App() {

  return (
    <React.Fragment>

      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <ProtectedRoute path="/" component={ProtectedContent} />
        </Switch>
      </Router>
      
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
