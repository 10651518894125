import React, { useState, useEffect} from 'react';

import Label from '../../../components/label';
import AccessCheckbox from './access-checkbox';
import Select from '../../../components/select';
import { getData } from '../../../helper/request-response';
import menuAccessPath from './config';
import constant from '../../../config/constant'

const Action = () => {

    const [menuAccess, setMenuAccess] = useState([]);

    const [roleId, setRoleId] = useState(0);

    const [selectedRole, setSelectedRole] = useState(constant.DEFAULT_SELECTED);

    const onRoleChanged = opt => {

        if (opt.id) {

            setRoleId(opt.id);

            setSelectedRole(role => ({
                ...role,
                value: opt.id,
                label: opt.label
            }));

        }
    }

    useEffect(() => {

        const init = () => {

            const getMenuAccessRole = async (id) => {

                const result = await getData(`${ menuAccessPath.accessRole }/${id}`);
        
                return result;
            }

            if (roleId) {

                getMenuAccessRole(roleId)
                    .then(result => {

                        if (result) {

                            setMenuAccess(result);

                        }
                    });
            }
        }
    
        init();

    }, [roleId]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ menuAccessPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8">
                        <form className="form-horizontal">
                            <div className="form-group row">
                                <Label htmlFor="role" isCol={ true } text="Nama Role" />
                                <div className="col-md-9">
                                    <Select
                                        id="role"
                                        api={ menuAccessPath.roleFind }
                                        onChange={ onRoleChanged }
                                        value={ selectedRole }
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {
                    menuAccess.length > 0 &&
                    <>
                        <div className="line"></div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Parent</th>
                                                <th>Menu</th>
                                                <th>Akses</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                menuAccess.map((menu, index) => {
                                                    return(
                                                        <tr key={ index }>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ menu.parent }</td>
                                                            <td>{ menu.menu }</td>
                                                            <td><AccessCheckbox key={ index } menu={ menu } roleId={ roleId } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default Action;
