import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CoaList from './List';
import CoaAction from './Action';
import coaPath from './config';

const CoaRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={coaPath.basePath} exact component={CoaList} />
                <Route path={coaPath.newPath} exact component={CoaAction} />
                <Route path={coaPath.detailPath} exact component={CoaAction} />
            </Switch>
        </React.Fragment>
    );
};

export default CoaRoutes;
