import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import { useGlobalState } from 'state-pool';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import Select from '../../../components/select';
import LinkButton from '../../../components/link-button';
import { getData } from '../../../helper/request-response';
import LoaderType from '../../../components/loader-type';
import bankAccPath from './config';

const List = () => {

    const [rows, ,] = useGlobalState('rows');

    const rowsRef = useRef(rows);

    const [isLoad, setIsLoad] = useState(true);

    const [bankAccount, setBankAccount] = useState([]);

    const [selectedBank, setSelectedBank] = useState({ value: '', label: ''});

    const bankAccEntity = {
        'bankId': 0,
        'accountName': '',
        'rows': rows
    }

    const [bankAccFilter, setBankAccFilter] = useState(bankAccEntity);

    const getDetailPath = (id) => bankAccPath.detail.replace(':id', id);

    /**
     * 
     * @param {*} e 
     */
    const onFilterChange = e => {

        const { name, value } = e.target;

        setBankAccFilter(filter => ({ 
            ...filter,
            [name]: value
        }));
    };

    /**
     * Get bank account data from API
     * @param {*} params 
     */
    const getBankAccount = params => {

        setIsLoad(true);

        getData(bankAccPath.api, params).then(result => {

            setBankAccount(result);

            setIsLoad(false);
        });
    }

    /**
     * Autocomplete of master bank selected event
     * @param {*} opt 
     */
    const onSelectedBank = opt => {

        setSelectedBank(code => ({
            ...code,
            value: opt.id,
            label: opt.label
        }));

        setBankAccFilter(filter => ({
            ...filter,
            bankId: opt.id
        }));
    }

    /**
     * Get filter params
     */
    const getFilter = useCallback((filter) => {

        const params = { 
            'params': { 
                'name': _.get(filter, 'name'), 
                'acccount': _.get(filter, 'account'),
                'rows': _.get(filter, 'rows')
            } 
        };

        return params;

    }, []);

    /**
     * 
     * @param {*} e 
     */
    const onSubmit = e => {

        e.preventDefault();

        const params = getFilter({ 'name': bankAccFilter.name, 'account': bankAccFilter.accountName, 'rows': bankAccFilter.rows });

        getBankAccount(params);

    }

    /**
     * 
     * @param {*} e 
     */
    const onReset = (e) => {

        e.preventDefault();

        setSelectedBank({ value: '', label: ''});

        setBankAccFilter(bankAccEntity);

        getBankAccount({ 'rows': bankAccEntity.rows });

    }

    useEffect(() => {

        const params = getFilter({ 'rows': rowsRef.current });
        getBankAccount(params);

    }, [getFilter]);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{bankAccPath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="bank-name" text="Nama Bank" isCol={ true } />
                                <div className="col-md-9">
                                    <Select
                                        id="bank-name"
                                        api={ bankAccPath.apiBank }
                                        value={ selectedBank }
                                        onChange={ onSelectedBank }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="account-name" isCol={ true } text="Nama Rekening" />
                                <div className="col-md-9">
                                    <input 
                                        id="account-name" 
                                        type="text" 
                                        className="form-control"
                                        name="accountName"
                                        value={bankAccFilter.accountName}
                                        onChange={ e => onFilterChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ bankAccPath.new } newLabel="Tambah Rekening Bank Baru" onClick={ onReset } />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Bank Baru" entity="Bank" />
                    </div>
                </div>

                <br />
                { isLoad && <LoaderType /> }
                {
                    !isLoad &&
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Bank</th>
                                            <th>Nama</th>
                                            <th>No Rekening</th>
                                            <th>Opsi</th>
                                        </tr>
                                    </thead>
                                    {
                                        bankAccount.length > 0 &&
                                        <tbody>
                                            {
                                                bankAccount.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{item.bankName}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.accountNo}</td>
                                                            <td><LinkButton to={ {pathname: getDetailPath(item.id), state: item} } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div> 
                }
            </div>
        </React.Fragment>
    );
};

export default List;
