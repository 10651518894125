import React, {useState, useEffect} from 'react';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import AutoComplete from '../../../components/async-select';
import { getData } from '../../../helper/request-response';
import constant from '../../../config/constant';
import userPath from './config';

const List = () => {

    const [users, setUsers] = useState([]);

    const [roles, setRoles] = useState([]);

    const [userFilter, setFilterUser] = useState({
        username: "",
        isActive: 1
    });

    const getDetailPath = (id) => userPath.detailPath.replace(":id", id);

    const onUsernameChange = opt => {

        setFilterUser(user => ({ 
            ...user,
            username: opt.label
        }));
    }

    const onFilterChange = e => {

        const { name, value } = e.target;

        setFilterUser(user => ({ 
            ...user,
            [name]: value
        }));
    };

    const onActiveChange = e => {

        setFilterUser(user => ({ 
            ...user,
            isActive: userFilter.isActive === 1 ? 0 : 1
        }));
    };

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                name: userFilter.name,
                adminRoleId: userFilter.adminRoleId
            }
        };

        getUsers(params);

    };

    const getUsers = async(params) => {

        const result = await getData(userPath.baseApi, params);
            
        setUsers(result);

    }

    const getRoles = async() => {

        const result = await getData(userPath.baseApiRole);

        setRoles(result);

    }

    const onReset = e => {

        e.preventDefault();

        setFilterUser({ 
            ...userFilter,
            username: "",
            adminRoleId: 0,
            isActive: 1
        });

        getUsers();

        getRoles();

    }

    useEffect(() => { 

        getUsers();

        getRoles();

     }, []);
    

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{userPath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <Label htmlFor="username" isCol={ true } text="Nama Pengguna" />
                                <div className="col-md-9">
                                    <AutoComplete
                                        id="username"
                                        filterApi={userPath.usernameFilterApi}
                                        onSelectChange={onUsernameChange}
                                        filter={{label: userFilter.username}} />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="role-id" isCol={ true } text="Role" />
                                <div className="col-md-9">
                                    <select id="role-id" className="form-control" name="adminRoleId" onChange={ e => onFilterChange(e) } value={userFilter.adminRoleId}>
                                        <option value="">Pilih</option>
                                        {roles.map(({id, name}) => {return(<option key={id} value={id}>{name}</option>)})}
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } text="Status" />
                                <div className="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                        <input 
                                            id="role-is-active"
                                            type="checkbox"
                                            className="custom-control-input"
                                            name="isActive"
                                            value={ userFilter.isActive }
                                            checked={ userFilter.isActive }
                                            onChange={ e => onActiveChange(e) }
                                        />   
                                        <label htmlFor="role-is-active" className="custom-control-label">Aktif</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ userPath.newPath } newLabel="Tambah User Baru" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah User Baru" entity="User" />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-lg-12">
                        <table className="table table-striped table-hover card-text">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nama Pengguna</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Opsi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((user, index) => {
                                        return(
                                            <tr key={index}>
                                                <th scope="row">{ index + 1 }</th>
                                                <td>{ user.username }</td>
                                                <td>{ user.adminRoleName }</td>
                                                <td>{ user.isActive === 1 ? constant.ACTIVE : constant.INACTIVE }</td>
                                                <td><LinkButton to={ {pathname: getDetailPath(user.id), state: user} } /></td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
