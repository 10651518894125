import React from 'react';
import { Route } from 'react-router-dom';
import PayableList from './List';
import PayableAction from './Action';
import payablePath from './config';

const PayableRoutes = () => {
    return (
        <React.Fragment>
            <Route path={payablePath.basePath} exact component={PayableList} />
            <Route path={payablePath.newPath} exact component={PayableAction} />
            <Route path={payablePath.detailPath} exact component={PayableAction} />
        </React.Fragment>
    );
};

export default PayableRoutes;