import React, {useState, useEffect} from 'react';
import { formatDate } from 'react-day-picker/moment';

import TextNumber from '../../../components/text-number';
import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import {getData} from '../../../helper/request-response';
import DayPicker from '../../../components/day-picker';
import constant from '../../../config/constant';
import payablePath from './config';

const List = () => {

    const [payables, setPayables] = useState([]);

    const getDetailPath = (invoiceNo) => payablePath.detailPath.replace(":id", invoiceNo);

    const [payableFilter, setFilterPayable] = useState({
        trxDate: "",
        supplierName: ""
    });

    const onInputChange = e => {

        setFilterPayable({ 
            ...payableFilter,
            [e.target.name]: e.target.value
        });
    };

    const onDayChange = day => {

        setFilterPayable(trx => ({ 
            ...trx,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));
    }

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                trxDate: payableFilter.trxDate,
                supplierName: payableFilter.supplierName,
            }
        };

        getPayables(params);

    };

    const getPayables = async(params) => {

        const result = await getData(payablePath.baseApi, params);
            
        setPayables(result);

    }

    const onReset = e => {

        e.preventDefault();

        setFilterPayable(trx => ({ 
            ...payableFilter,
            trxDate: "",
            supplierName: ""
        }));

        getPayables();

    }

    useEffect(() => { 

        getPayables();

     }, []);
    

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{payablePath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <Label htmlFor="trx-date" isCol={ true } text="Tanggal Pembelian" />
                                <div className="col-md-9">
                                    <DayPicker id="trx-date" name="trxDate" onDayChange={ onDayChange } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="payable-supplierName" isCol={ true } text="Nama Supplier" />
                                <div className="col-md-9">
                                    <input 
                                        id="payable-supplierName" 
                                        type="text" 
                                        className="form-control"
                                        name="supplierName"
                                        value={ payableFilter.supplierName }
                                        onChange={ e => onInputChange(e) } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ payablePath.newPath } newLabel="Tambah Pembayaran Baru" onClick={ onReset } />
                                </div>
                            </div>

                        </form>
                    </div>

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Transaksi Baru" entity="Transaksi" />
                    </div>
                </div>

                <div className="col-lg-12 mb-4">
                    <div className="row">
                        <table className="table table-striped table-hover card-text">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>No Pembayaran</th>
                                    <th>Tanggal</th>
                                    <th>Nama Supplier</th>
                                    <th>Jumlah</th>
                                    <th>Opsi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    payables.map((payable, index) => {
                                        return(
                                            <tr key={index}>
                                                <th scope="row">{ index + 1 }</th>
                                                <td>{ payable.invoiceNo }</td>
                                                <td>{ `${formatDate(payable.trxDate, 'LL', constant.LOCALE)}` } </td>
                                                <td>{ payable.custName }</td>
                                                <td><TextNumber value={ payable.amount } /></td>
                                                <td><LinkButton to={ {pathname: getDetailPath(payable.invoiceNo), state: payable} } /></td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
