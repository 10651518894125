import apiPath from '../../../config/api';

const CustomerPath = {
    title: "Modul Pelanggan",
    basePath: "/master/pelanggan",
    newPath: "/master/pelanggan/baru",
    detailPath: "/master/pelanggan/opsi/:id",
    newCustType: "/master/tipe-pelanggan/baru",
    baseApi: "/master/customer",
    baseApiCustomerType: "/master/customer-type",
    baseApiAddress: "/master/address",
    custFind: apiPath.masterCustFind,
}

export default CustomerPath;
