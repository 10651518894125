const ApiPath = {
    adminRole: "/admin/role",
    adminRoleFind: "/admin/role/find",
    adminUser: "/admin/user",
    adminLogin: "/admin/user/login",
    adminUserUnameFilter: "/admin/user/username",
    adminMenu: "/admin/menu",
    adminMenuGroup: "/admin/menu/group",
    adminMenuGroupLabelFilter: "admin/menu/group/label",
    adminMenuSubLabelFilter: "admin/menu/group/sub/label",
    adminMenuAccess: "/admin/menu-access",
    adminMenuAccessRole: "/admin/menu-access/role",
    masterBu: "/master/business-unit",
    masterBuCodeFilter: "/master/business-unit/code",
    masterBuNmFilter: "/master/business-unit/name",
    masterEmployee: "/master/employee",
    masterEmplFind: "/master/employee/find",
    masterEmplUser: "/master/employee/user",
    masterEmpDriverFilter: "/master/employee/driver",
    masterCoa: "/master/coa",
    masterCoaCash: "/master/coa/cash",
    masterCoaMiscExp: "/master/coa/miscellaneous-expenses",
    masterCoaGns: "/master/coa/goods-and-services",
    masterUnit: "/master/unit",
    masterUnitFilter: "/master/unit/find",
    masterGns: "/master/goods-and-service",
    masterGnsFind: "/master/goods-and-service/find",
    masterGnsNmFilter: "/master/goods-and-service/name",
    masterGnsCodeFilter: "/master/goods-and-service/code",
    masterGnsPalmOilNm: "/master/goods-and-service/palm-oil",
    masterCustomerType: "/master/customer-type",
    masterCustomer: "/master/customer",
    masterCustomerAllNameFilter: "/master/customer/all",
    masterCustSupplierDebtFilter: "/master/customer/supplierDebt",
    masterCustFind: "/master/customer/find",
    masterAddr: "/master/address",
    masterAddrLocFilter: "/master/address/location",
    masterRetribution: "/master/retribution",
    masterRetributionFind: "/master/retribution/find",
    masterBank: '/master/bank',
    masterBankFind: '/master/bank/find',
    masterBankAccount: '/master/bank-account',
    masterBankAccountFind: '/master/bank-account/find',
    masterBrilinkType: "/brilink/trx-type",
    masterBrilinkTypeNameFilter: "/brilink/trx-type/name",
    masterBrilinkTrx: "/brilink/trx",
    cashTrf: "/cash/cash-trf",
    cashTrfExcel: "/cash/cash-trf/excel",
    cashRetail: "/cash/retail",
    cashRetailExcel: "/cash/retail/excel",
    cashAulia: "/cash/aulia",
    cashAuliaExcel: "/cash/aulia/excel",
    cashPo: "/cash/palm-oil",
    cashPoExcel: "/cash/palm-oil/excel",
    cashPbdo: "/cash/palm-oil/pbdo",
    cashPbdoExcel: "/cash/palm-oil/pbdo/excel",
    cashBank: "cash/bank",
    cashBankExcel: "cash/bank/excel",
    bankBalance: '/bank/balance',
    accountBalanceDetail:'/account/balance/detail',
    accountBalance: '/account/balance',
    accountApAr: "/account/ap-ar",
    accountApArDetail: "/account/ap-ar/detail",
    accountApArMiscExp: "/account/ap-ar/misc-exp",
    accountApArCustIdFilter: "/account/ap-ar/customer",
    accountCashLoan: "/account/cash-loan",
    accountReceivable: "/account/receivable",
    accountPayable: "/account/payable",
    accountSaveDeb: "/account/saving-debit",
    accountSaveCred: "/account/saving-credit",
    accountMiscExp: "/account/misc-exp",
    accountCustSaldo: "/account/cust-saldo",
    accountCustSaldoDetail: "/account/cust-saldo-detail",
    accountCustSaldoExcel: "/account/cust-saldo/excel",
    accountCustSaldoDetailExcel: "/account/cust-saldo-detail/excel",
    accountProfitLoss: "/account/loss-profit",
    accountProfitLossSum: "/account/loss-profit/summary",
    accountProfitLossExcel: "/account/loss-profit/excel",
    accountMonitoring: "/account/monitoring",
    accountMonitoringExcel: "/account/monitoring/excel",
    accountChart: "/account/chart",
    accountChartSum: "/account/chart/summary",
    accountCustTrxDetail: "/account/cust-trx-detail",
    accountCustTrxDetailExcel: "/account/cust-trx-detail/excel",
    accountCustTrxDetailSum: "/account/cust-trx-detail/summary",
    accountTax: "/account/tax",
    accountTaxExcel: "/account/tax/excel",
    retailPurchase: "/retail/purchase",
    retailSales: "/retail/sales",
    retailSalesExcel: "retail/sales/excel",
    retailLosses: "/retail/losses",
    retailStockSales: "/retail/stock-sales",
    retailStockSalesSum: "/retail/stock-sales/summary",
    retailStockSalesExcel: "/retail/stock-sales/excel",
    retailStockSalesFind: "/retail/stock-sales/find",
    retailPurchaseSales: "/retail/purchase-sales",
    retailPurchaseSalesExcel: "/retail/purchase-sales/excel",
    retailPurchaseSalesFind: "/retail/purchase-sales/find",
    retailStock: "/retail/stock",
    retailStockExcel: "/retail/stock/excel",
    retailStockGoods: "/retail/stock/goods",
    retailStockGenerated: "/retail/stock/generated",
    palmOilSales: "/palmoil/sales",
    palmOilSalesHistory: "/palmoil/sales/history",
    palmOilSalesFind: "/palmoil/sales/find",
    palmOilPurchase: "/palmoil/purchase",
    palmOilPurchaseBulk: "/palmoil/purchase/bulk",
    palmOilPurchaseSales: "/palmoil/purchase/sales",
    palmOilPurchaseHistory: "/palmoil/purchase/history",
    palmOilPurchaseHistories: "/palmoil/purchase/histories",
    palmOilSalesPurchase: "/palmoil/sales-purchase",
    palmOilSalesPurchaseSum: "/palmoil/sales-purchase/summary",
    palmOilSalesPurchaseExcel: "/palmoil/sales-purchase/excel",
    palmOilCashout: "/palmoil/cashout",
    palmOilCashoutSum: "/palmoil/cashout/summary",
    palmOilCashoutPurchases: "/palmoil/cashout/purchases",
    palmOilCashoutPurchasesExcel: "/palmoil/cashout/purchases/excel",
    palmOilCashin: "/palmoil/cashin",
    palmOilCashinSum: "/palmoil/cashin/summary",
    palmOilCashinExcel: "/palmoil/cashin/excel",
    palmOilRetribution: "/palmoil/retribution",
    palmOilRetributionFind: "/palmoil/retribution/find",
    palmOilRetributionSum: "/palmoil/retribution/summary",
    palmOilRetributionExcel: "/palmoil/retribution/excel",
    palmOilHarvest: "/palmoil/harvest",
    palmOilHarvestFind: "/palmoil/harvest/find",
    palmOilHarvestSum: "/palmoil/harvest/summary",
    palmOilHarvestExcel: "/palmoil/harvest/excel",
    palmOilLoad: "/palmoil/load",
    palmOilLoadSum: "/palmoil/load/summary",
    palmOilLoadExcel: "/palmoil/load/excel",
    masterVehicles: "/master/vehicles",
    masterVehicleFind: "/master/vehicles/find",
    masterVehiclesCodeFilter: "/master/vehicles/code",
    masterVehiclesNoFilter: "/master/vehicles/vehicleNo",
    rentalTrx: "/rental/trx",
}

export default ApiPath;
