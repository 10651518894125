import React, { useState } from 'react';
import { formatDate } from 'react-day-picker/moment';
import { DateTime } from 'luxon';

import Label from '../../../components/label';
import TextNumber from '../../../components/text-number';
import DayPicker from '../../../components/day-picker';
import ReportButton from '../../../components/report-button';
import { getData, getFile } from '../../../helper/request-response';
import { downloadFile } from '../../../helper/util';
import constant from '../../../config/constant';
import trxPath from './config';

const List = () => {

    const [startDate, setStartDate] = useState(DateTime.local());

    const [endDate, setEndDate] = useState(DateTime.local());

    const [trx, setTrx] = useState([]);

    const [trxFilter, setFilterTrx] = useState({
        startDate:"",
        endDate:"",
        masterCustomerId: "",
        reportType:"",
        custName:""
    });

    const onStartDayChange = day => setStartDate(formatDate(day, constant.DEFAULT_DATE));

    const onEndDayChange = day => setEndDate(formatDate(day, constant.DEFAULT_DATE));

    const getExcel = async(param, url) => await getFile(url, param);

    const onExport = e => {

        e.preventDefault();

        const params = {
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                endDate: DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT),
                masterCustomerId: trxFilter.masterCustomerId,
                reportType: trxFilter.reportType,
                custName: trxFilter.custName
            }
        };

        getExcel(params, trxPath.custSaldoDetailExcel).then(response => {

            downloadFile(response);

        });

    }

    const onReset = e => {

        e.preventDefault();

        setFilterTrx(trx => ({ 
            ...trx,
            startDate:"",
            endDate:"",
            masterCustomerId: "",
            reportType:"",
            custName:""
        }));

        setStartDate(DateTime.local());
        setEndDate(DateTime.local());

        getTrxs();

    }

    const onInputChange = e => {

        setFilterTrx({ 
            ...trxFilter,
            [e.target.name]: e.target.value
        });
    };


    const getTrxs = async(params) => {

        const result = await getData(trxPath.baseApi, params);

        setTrx(result[0]);

    }

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: {
                startDate: DateTime.fromISO(startDate).toFormat(constant.PARAM_DATE_FORMAT),
                endDate: DateTime.fromISO(endDate).toFormat(constant.PARAM_DATE_FORMAT),
                masterCustomerId: trxFilter.masterCustomerId,
                reportType: trxFilter.reportType
            }
        };

        getTrxs(params);

    };

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{ trxPath.title }</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="startDate" text="Tanggal Dari" />
                                    <DayPicker id="startDate" name="startDate" onDayChange={onStartDayChange} valueDate={ startDate } />
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="endDate" text="Tanggal Ke" />
                                    <DayPicker id="endDate" name="endDate" onDayChange={onEndDayChange} valueDate={ endDate } />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">

                                    <Label htmlFor="reportType" text="Jenis Laporan" />
                                    <select id="reportType" className="form-control" name="reportType" onChange={ e => onInputChange(e) } value={trxFilter.reportType}>
                                        <option value="">Pilih</option>
                                        <option value="all">Semua Transaksi</option>
                                        <option value="payable">Hutang Supplier</option>
                                        <option value="receivable">Piutang Pelanggan</option>
                                        <option value="miscExp">Biaya Lain-lain</option>
                                        <option value="cashTransfer">Transfer Kas</option>
                                        <option value="cashLoan">Pinjaman Tunai</option>
                                        <option value="savingCredit">Setor Tabungan</option>
                                        <option value="savingDedit">Tarik Tabungan</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <ReportButton isSubmit={ true } onClick={ onReset } onExport={ onExport }/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tanggal</th>
                                        <th>No Faktur</th>
                                        <th>Nama</th>
                                        <th>Tipe</th>
                                        <th>Deskripsi</th>
                                        <th>Kredit</th>
                                        <th>Debit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        trx.map((trx, index) => {
                                            return(
                                                <tr key={ index }>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>{ `${formatDate(trx.trx_date, 'LL', constant.LOCALE)}` }</td>
                                                    <td>{ trx.invoice_no }</td>
                                                    <td>{ trx.customer_name }</td>
                                                    <td>{ trx.customer_type }</td>
                                                    <td>{ trx.description }</td>
                                                    <td><TextNumber value={ trx.credit } /></td>
                                                    <td><TextNumber value={ trx.debit } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default List;
