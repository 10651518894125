import apiPath from '../../../config/api';

const VehiclesPath = {
    title: "Modul Kendaraan",
    basePath: "/master/kendaraan",
    newPath: "/master/kendaraan/baru",
    detailPath: "/master/kendaraan/opsi/:id",
    baseApi: apiPath.masterVehicles,
    codeFilterApi: apiPath.masterVehiclesCodeFilter,
    vehicleNoFilterApi: apiPath.masterVehiclesNoFilter
}

export default VehiclesPath;
