import apiPath from '../../../config/api';

const CustomerTypePath = {
    title: "Modul Barang Dan Jasa", 
    basePath: "/master/barang-dan-jasa",
    newPath: "/master/barang-dan-jasa/baru",
    detailPath: "/master/barang-dan-jasa/opsi/:id",
    newUnit: "/master/satuan/baru",
    baseApi: apiPath.masterGns,
    baseUnitApi: apiPath.masterUnit,
    baseBusinessUnitApi: apiPath.masterBu,
    gnsFind: apiPath.masterGnsFind,
    coaGns: apiPath.masterCoaGns,
    gnsCodeFilter: apiPath.masterGnsCodeFilter,
    gnsNmFilter: apiPath.masterGnsNmFilter,
    unitFilter: apiPath.masterUnitFilter
}

export default CustomerTypePath;
