import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { formatDate } from 'react-day-picker/moment';
import { useGlobalState } from 'state-pool';
import _ from 'lodash';

import { DateTime } from 'luxon';
import Select from '../../../components/select';
import InputNumeral from '../../../components/input-numeral';
import DayPicker from '../../../components/day-picker';
import Create from '../../../components/create';
import {postData, putData} from '../../../helper/request-response';
import {onSuccess} from '../../../helper/toaster';
import constant from '../../../config/constant';
import rentTrxPath from './config';
import { getAuliaBalance } from '../../../helper/cash-balance';

const Action = () => {
    
    const data = useLocation();
    
    const history = useHistory();

    const refData = useRef(data);

    const [, , setAuliaBalance] = useGlobalState('auliaBalance');
    
    const [titleInfo, setTitleInfo] = useState("");

    const [isNew, setIsNew] = useState(false);

    const [selectedCust, setSelectedCust] = useState(constant.DEFAULT_SELECTED);

    const [selectedDriver, setSelectedDriver] = useState(constant.DEFAULT_SELECTED);

    const [selectedVehicle, setSelectedVehicle] = useState(constant.DEFAULT_SELECTED);

    const [rentTrx, setRentTrx] = useState(
        {
            id: 0,
            invoiceNo: `RM${DateTime.local().toFormat(constant.INVOICE_FORMAT)}`,
            description: "",
            trxDate: DateTime.local(),
            fee: 0,
            masterCustomerId: 0,
            customerName: '',
            masterEmployeeId: 0,
            employeeName: '',
            masterVehiclesId: 0,
            vehicleNo: '',
            apArId: 0,
            isCommit: true
        });

    const onInputChange = e => {

        const { name, value } = e.target;
        
        const rentTrxData = {
            ...rentTrx,
            [name]: value
        } 

        setRentTrx(rentTrxData);
        
    };

    const onCustomerChange = opt => {

        if (opt.id) {

            setRentTrx(data => ({ 
                ...data,
                apArId: opt.apArId,
                masterCustomerId: opt.id,
                customerCode: opt.label,
                customerName: opt.name
            }));

            setSelectedCust(cust => ({
                ...cust,
                value: opt.id,
                label: opt.name
            }));

        } 

    }

    const onDriverChange = opt => {

        if (opt.id) {

            setRentTrx(trx => ({
                ...trx,
                masterEmployeeId: opt.id,
                employeeName: opt.label
            }));

            setSelectedDriver(driver => ({
                ...driver,
                value: opt.id,
                label: opt.label
            }));

        }

    }

    const onRentalFeeChange = values => {

        const { value } = values;

        setRentTrx(trx => ({
            ...trx,
            fee: parseFloat(value)
        }));

    }

    const onVehicleChange = opt => {

        if (opt.id) {

            setRentTrx(trx => ({
                ...trx,
                masterVehiclesId: opt.id,
                vehicleNo: opt.code
            }));

            setSelectedVehicle(vehicle => ({
                ...vehicle,
                value: opt.id,
                label: opt.code
            }));
        }
    }

    const onDayChange = day => {

        setRentTrx({ 
            ...rentTrx,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        });
    }


    const onSubmit = (e) => {

        e.preventDefault();

        const postBankTrx = async () => {

            setAuliaBalance(0);

            const result = await postData(rentTrxPath.baseApi, rentTrx);

            if (!_.isEmpty(result)) {

                onSuccess(`Transaksi ${result.invoiceNo} berhasil di tambahkan`);

                history.goBack();

                getAuliaBalance();

            }
        };

        const putBankTrx = async () => {

            setAuliaBalance(0);

            const result = await putData(`${rentTrxPath.baseApi}/${rentTrx.id}`, rentTrx);

            if (!_.isEmpty(result)) {

                onSuccess(`Transaksi ${result.invoiceNo} berhasil di perbaharui`);

                history.goBack();

                getAuliaBalance();

            }
        }

        if (rentTrx.id === 0) postBankTrx();
        else putBankTrx();

    }

    useEffect(() => {

        function setRentTrxState() {

            const state = refData.current.state;

            if (state) {

                setRentTrx(state)

                setTitleInfo(`Detail: ${state.invoiceNo}`);

                setSelectedCust(cust => ({
                    ...cust,
                    value: state.customerId,
                    label: state.customerName
                }));

                setSelectedDriver(driver => ({
                    ...driver,
                    value: state.employeeId,
                    label: state.employeeName
                }));

                setSelectedVehicle(vehicle => ({
                    ...vehicle,
                    value: state.masterVehiclesId,
                    label: state.vehicleNo
                }));

            } else {

                setTitleInfo("Tambah Data Baru");

                setIsNew(true);

            }
        }

        setRentTrxState();

    }, []);

    return (

        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{rentTrxPath.title} | {titleInfo}</h3>
            </div>

            <div className="card-body">
                <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                    <div className="form-group row">
                        <label htmlFor="rentTrx-invoiceNo" className="col-md-2 form-control-label">No Faktur</label>
                        <div className="col-md-4">
                            <input 
                            id="rentTrx-invoiceNo"
                            type="text" 
                            className="form-control" 
                            name="invoiceNo"
                            value={rentTrx.invoiceNo}
                            onChange={ e => onInputChange(e) } 
                            readOnly
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        
                            <label htmlFor="trx-date" className="col-md-2 form-control-label">Tanggal</label>
                            <div className="col-md-4">
                            <div>
                                <DayPicker id="trx-date" name="trxDate" valueDate={rentTrx.trxDate} onDayChange={onDayChange} />
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="rentTrx-customerCode" className="col-md-2 form-control-label">Kode Pelanggan</label>

                        <div className="col-md-4">
                            <Select
                                id="customer-name"
                                paramFilter="name"
                                api={ rentTrxPath.custFind }
                                entityId="1,2,3,4"
                                onChange={ onCustomerChange }
                                value={ selectedCust }
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="rentTrx-customerName" className="col-md-2 form-control-label">Nama Pelanggan</label>
                        <div className="col-md-4">
                            <input 
                            id="rentTrx-customerName"
                            type="text" 
                            className="form-control" 
                            name="customerName"
                            value={rentTrx.customerName}
                            onChange={ e => onInputChange(e) } 
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="rentTrx-vehicle" className="col-md-2 form-control-label">Mobil</label>

                        <div className="col-md-4">
                            <Select
                                id="pks"
                                api={ rentTrxPath.vehicleFind }
                                onChange={ onVehicleChange }
                                value={ selectedVehicle }
                            />
                            {/* <AutoComplete 
                                filterApi={rentTrxPath.vehicleNoFilterApi}
                                onSelectChange={onSelectChangeVehicleNo} 
                                onInputChange={onInputChange}
                                filter={{id: rentTrx.id, label: rentTrx.vehicleNo}}
                             /> */}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="rentTrx-driver" className="col-md-2 form-control-label">Supir</label>

                        <div className="col-md-4">
                            <Select
                                id="driver"
                                api={ rentTrxPath.emplFind }
                                entityId="5"
                                onChange={ onDriverChange }
                                value={ selectedDriver }
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="rentTrx-description" className="col-md-2 form-control-label">Deskripsi</label>
                        <div className="col-md-4">
                            <input 
                            id="rentTrx-description"
                            type="text" 
                            className="form-control" 
                            name="description"
                            value={rentTrx.description}
                            onChange={ e => onInputChange(e) } 
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="rentTrx-fee" className="col-md-2 form-control-label">Biaya</label>
                        <div className="col-md-4">
                            <InputNumeral
                                id="rentTrx-fee"
                                value={ rentTrx.fee }
                                onValueChange={ onRentalFeeChange }
                            />
                            {/* <input 
                            id="rentTrx-fee"
                            type="text" 
                            className="form-control" 
                            name="fee"
                            value={rentTrx.fee}
                            onChange={ e => onInputChange(e) } 
                            /> */}
                        </div>
                    </div>

                    <Create isNew={ isNew } />

                    {/* <Create /> */}
                </form>
            </div>
        </React.Fragment>
    );
};

export default Action;
