import React from 'react';
import {Switch, Route} from 'react-router-dom';
import PurchaseList from './List';
import PurchaseAction from './Action';
import purchasePath from './config';

const PurchaseRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={purchasePath.basePath} exact component={PurchaseList} />
                <Route path={purchasePath.newPath} exact component={PurchaseAction} />
                <Route path={purchasePath.detailPath} exact component={PurchaseAction} />
            </Switch>
        </React.Fragment>
    );
};

export default PurchaseRoutes;
