import React from 'react';
import { Route } from 'react-router-dom';
import CashRetailList from './List';
import cashRetailPath from './config';

const CashRetailRoutes = () => {
    return (
        <React.Fragment>
            <Route path={cashRetailPath.path} exact component={ CashRetailList } />
        </React.Fragment>
    );
};

export default CashRetailRoutes;
