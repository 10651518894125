import React, {useState, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';

import AutoComplete from '../../../components/async-select';
import Create from '../../../components/create';
import {postData, putData} from '../../../helper/request-response';
import {onSuccess} from '../../../helper/toaster';
import apArHeaderPath from './config';

const Action = () => {
    
    const data = useLocation();
    
    const history = useHistory();
    
    const [titleInfo, setTitleInfo] = useState("");

    const [apArHeader, setApArHeader] = useState(
        {
            id: 0,
            masterCustomerId: "",
            isCommit: true,
            code: "",
            name: ""
        });

    const onInputChange = e => {

        setApArHeader({ 
            ...apArHeader,
            [e.target.name]: e.target.value
        });
    };

    const onCheckboxChange = e => {

        setApArHeader({ 
            ...apArHeader,
            [e.target.name]: apArHeader.isCommit === true ? false : true
        });
    };

    const onSelectChange = opt => {

        setApArHeader({ 
            ...apArHeader,
            masterCustomerId: opt.id,
            customerCode: opt.label,
            customerName: opt.name
        });

    }


    const onSubmit = (e) => {

        e.preventDefault();

        
        const postApArHeader = async () => {

            const result = await postData(apArHeaderPath.baseApi, apArHeader);

            if (Object.keys(result).length) {

                onSuccess(`Jenis Transaksi ${result.name} berhasil di tambahkan`);

                history.goBack();

            }
        };

        const putApArHeader = async () => {

            const result = await putData(`${apArHeaderPath.baseApi}/${apArHeader.id}`, apArHeader);

            if (Object.keys(result).length) {

                onSuccess(`Jenis Transaksi ${result.name} berhasil di perbaharui`);

                history.goBack();

            }
        }

        if (apArHeader.id === 0) postApArHeader();
        else putApArHeader();

    }

    useEffect(() => {

        function setApArHeaderState() {

            if (data.state) {

                setApArHeader(data.state)

                setTitleInfo(`Detail: ${data.state.name}`);

            } else {

                setTitleInfo("Tambah Data Baru");

            }
        }

        setApArHeaderState();

    }, [data]);

    return (

        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{apArHeaderPath.title} | {titleInfo}</h3>
            </div>

            <div className="card-body">
                <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>

                <div className="form-group row">
                        <label htmlFor="apArHeader-customerCode" className="col-md-2 form-control-label">Kode Pelanggan</label>

                        <div className="col-md-4">
                            <AutoComplete 
                                filterApi={apArHeaderPath.customerNameFilterApi}
                                onSelectChange={onSelectChange}
                                filter={{id: apArHeader.id, label: apArHeader.customerCode}}
                             />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="apArHeader-customerName" className="col-md-2 form-control-label">Nama Pelanggan</label>
                        <div className="col-md-4">
                            <input 
                            id="apArHeader-customerName"
                            type="text" 
                            className="form-control" 
                            name="customerName"
                            value={apArHeader.customerName}
                            onChange={ e => onInputChange(e) } 
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-md-2 form-control-label">Status</label>
                        <div className="col-md-4">
                            <div className="custom-control custom-checkbox">
                                <input 
                                id="apArHeader-is-commit"
                                type="checkbox" 
                                className="custom-control-input" 
                                name="isCommit"
                                value={ apArHeader.isCommit }
                                checked={ apArHeader.isCommit }
                                onChange={ e => onCheckboxChange(e) } 
                                />   
                                <label htmlFor="apArHeader-is-commit" className="custom-control-label">Aktif</label>
                            </div>
                        </div>
                    </div>

                    <Create />
                </form>
            </div>
        </React.Fragment>
    );
};

export default Action;
