import apiPath from '../../../config/api';

const BankPath = {
    'title': 'Bank', 
    'base': '/master/bank',
    'new': '/master/bank/baru',
    'detail': '/master/bank/opsi/:id',
    'api': apiPath.masterBank
}

export default BankPath;
