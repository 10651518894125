import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BankList from './List';
import BankAction from './Action';
import bankPath from './config';

const CoaRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={ bankPath.base } exact component={ BankList } />
                <Route path={ bankPath.new } exact component={ BankAction } />
                <Route path={ bankPath.detail } exact component={ BankAction } />
            </Switch>
        </React.Fragment>
    );
};

export default CoaRoutes;
