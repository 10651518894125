import React from 'react';
import { Route } from 'react-router-dom';
import CashBankList from './List';
import cashBankPath from './config';

const CashBankRoutes = () => {
    return (
        <React.Fragment>
            <Route path={cashBankPath.path} exact component={ CashBankList } />
        </React.Fragment>
    );
};

export default CashBankRoutes;
