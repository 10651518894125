import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'state-pool';

const Header = () => {

    const [navbar, ,] = useGlobalState('navbar');
    const [navbarTitle, ,] = useGlobalState('navbarTitle');
    const [appEntity, ,] = useGlobalState('appEntity');
    const [user, , setUser] = useGlobalState('user');
    const [, , setToken] = useGlobalState('token');
    const [, ,setIsAuthenticated] = useGlobalState('isAuthenticated');
    const [env, ] = useState(process.env.REACT_APP_ENV);
    
    const logout = e => {

        e.preventDefault();

        setToken('');
        setUser({});
        setIsAuthenticated(false);
    }

    return (
      <React.Fragment>
          <header className="header">
            <nav className={ navbar }>
                <a href="#pos" className="sidebar-toggler text-gray-500 mr-4 mr-lg-5 lead"><i className="fas fa-align-left"></i></a>

                <Link to="/dashboard" className={ navbarTitle }><h2>{appEntity}<sup>&nbsp;{ env }</sup></h2></Link>
                
                <ul className="ml-auto d-flex align-items-center list-unstyled mb-0">                 
                    <li className="nav-item dropdown ml-auto">
                        
                        <Link id="userInfo" to="/user" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">
                            <img src={window.location.origin + "/images/avatar.png"} alt="Jason Doe" style={{maxWidth: 2.5 + "rem"}} className="img-fluid rounded-circle shadow" />
                        </Link>
                        
                        <div aria-labelledby="userInfo" className="dropdown-menu">
                            
                            <Link to="/" className="dropdown-item"><strong className="d-block text-uppercase headings-font-family">{ user.emplName }</strong><small>{ user.designation }</small></Link>
                            
                            <div className="dropdown-divider"></div>
                            <Link to="/setting" className="dropdown-item">Settings</Link>
                            <Link to="/activity" className="dropdown-item">Activity log</Link>
                            
                            <div className="dropdown-divider"></div>
                            <Link to="/login" onClick={ e => logout(e) } className="dropdown-item">Logout</Link>
                        </div>
                        </li>
                </ul>
            </nav>
          </header>
      </React.Fragment>
    );
};

export default Header;
