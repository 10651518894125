import apiPath from '../../../config/api';

const MenuAccessPath = {
    title: "Modul Menu Akses",
    basePath: "/admin/menu-akses",
    baseApi: apiPath.adminMenuAccess,
    accessRole: apiPath.adminMenuAccessRole,
    roleFind: apiPath.adminRoleFind
}

export default MenuAccessPath;
