import React, { useState, useEffect } from 'react';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import AutoComplete from '../../../components/async-select';
import { getData } from '../../../helper/request-response';
import vehiclePath from './config';

const List = () => {

    const [vehicles, setVehicles] = useState([]);

    const [vehicleFilter, setVehicleFilter] = useState({
        code: "",
        vehicleNo: ""
    });

    const getDetailPath = (id) => vehiclePath.detailPath.replace(":id", id); 

    const onCodeChange = opt => {

        setVehicleFilter(vhc => ({ 
            ...vhc,
            code: opt.label
        }));

    }

    const onVehicleNoChange = opt => {

        setVehicleFilter(vhc => ({ 
            ...vhc,
            vehicleNo: opt.label
        }));
    }

    const onSubmit = (e) => {

        e.preventDefault();

        const params = {
            params: {
                code: vehicleFilter.code,
                vehicleNo: vehicleFilter.vehicleNo 
            }
        };

        getVehicle(params);

    }

    const getVehicle = async(params) => {

        const result = await getData(vehiclePath.baseApi, params);

        setVehicles(result);

    }

    const onReset = (e) => {

        e.preventDefault();
        setVehicleFilter({
            ...vehicleFilter,
            code: "",
            vehicleNo: ""
        });

        getVehicle();

    }

    useEffect(() => { getVehicle() }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{vehiclePath.title}</h3>
            </div>
            <div className="card-body">

                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <Label htmlFor="vehicle-code" isCol={ true } text="Kode Kendaraan" />
                                <div className="col-md-9">
                                    <AutoComplete 
                                        id="vehicle-code"
                                        filterApi={ vehiclePath.codeFilterApi }
                                        onSelectChange={ onCodeChange } 
                                        filter={ {label: vehicleFilter.code} }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="vehicle-no" isCol={ true } text="No Kendaraan" />
                                <div className="col-md-9">
                                    <AutoComplete 
                                        id="vehicle-no"
                                        filterApi={ vehiclePath.vehicleNoFilterApi }
                                        onSelectChange={ onVehicleNoChange } 
                                        filter={ {label: vehicleFilter.vehicleNo} }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ vehiclePath.newPath } newLabel="Tambah Kendaraan Baru" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div> 

                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Kendaraan Baru" entity="Kendaraan" />
                    </div>
                </div>
                
                <br />
                
                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover card-text">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Kode Kendaraan</th>
                                        <th>No Kendaraan</th>
                                        <th>Opsi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        vehicles.map((vehicle, index) => {
                                            return(
                                                <tr key={index}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{vehicle.code}</td>
                                                    <td>{vehicle.vehicleNo}</td>
                                                    <td><LinkButton to={ {pathname: getDetailPath(vehicle.id), state: vehicle} } /></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default List;
