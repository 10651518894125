import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Action from './Action';
import menuAccessPath from './config';

const MenuAccessRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={ menuAccessPath.basePath } exact component={ Action } />
            </Switch>
        </React.Fragment>
    );
};

export default MenuAccessRoutes;
