import React from 'react';
import { Route } from 'react-router-dom';
import MiscExpList from './List';
import MiscExpAction from './Action';
import miscExpPath from './config';

const MiscExpRoutes = () => {
    return (
        <React.Fragment>
            <Route path={miscExpPath.basePath} exact component={MiscExpList} />
            <Route path={miscExpPath.newPath} exact component={MiscExpAction} />
            <Route path={miscExpPath.detailPath} exact component={MiscExpAction} />
        </React.Fragment>
    );
};

export default MiscExpRoutes;