import React from 'react';
import { Switch, Route } from 'react-router-dom';
import GoodsAndServiceList from './List';
import GoodsAndServiceAction from './Action';
import goodsAndServicePath from './config';

const GoodsAndServiceRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={goodsAndServicePath.basePath} exact component={GoodsAndServiceList} />
                <Route path={goodsAndServicePath.newPath} exact component={GoodsAndServiceAction} />
                <Route path={goodsAndServicePath.detailPath} exact component={GoodsAndServiceAction} />
            </Switch>
        </React.Fragment>
    );
};

export default GoodsAndServiceRoutes;
