import apiPath from '../../../config/api';

const PoSalesPurchase = {
    title: "TBS - Laporan Penjualan dan Pembelian",
    basePath: "/sawit/laporan/penjualan-dan-pembelian",
    base: apiPath.palmOilSalesPurchase,
    baseExcel: apiPath.palmOilSalesPurchaseExcel,
    salesPurchaseSumm: apiPath.palmOilSalesPurchaseSum,
}

export default PoSalesPurchase;
