import apiPath from '../../../config/api';

const AuliaCashPath = {
    title: "Saldo Aulia",
    basePath: "/kas/aulia-tani",
    baseApi: apiPath.cashAulia,
    excel: apiPath.cashAuliaExcel
}

export default AuliaCashPath;
