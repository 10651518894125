import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BuList from './List';
import BuAction from './Action';
import buPath from './config';

const BuRoutes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path={buPath.basePath} exact component={BuList} />
                <Route path={buPath.newPath} exact component={BuAction} />
                <Route path={buPath.detailPath} exact component={BuAction} />
            </Switch>
        </React.Fragment>
    );
};

export default BuRoutes;
