import React, { useState, useEffect } from 'react';

import Note from '../../../components/note';
import Label from '../../../components/label';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import LoaderType from '../../../components/loader-type';
import { getData } from '../../../helper/request-response';
import unitPath from './config';

const List = () => {

    const [isLoad, setIsLoad] = useState(true);

    const [units, setUnits] = useState([]);

    const [unitFilter, setUnitFilter] = useState({
        code: "",
        name: ""
    });

    const getDetailPath = (id) => unitPath.detailPath.replace(":id", id);

    const onInputChange = e => {

        const { name, value } = e.target;

        setUnitFilter(data => ({ 
            ...data,
            [name]: value
        }));
    };

    const onSubmit = e => {

        e.preventDefault();

        const params = { 
            params: { 
                name: unitFilter.name, 
                code: unitFilter.code 
            }
        };

        setUnitsState(params);

    };

    const setUnitsState = async(params) => {

        setIsLoad(true);

        const result = await getData(unitPath.baseApi, params);

        setUnits(result);

        setIsLoad(false)
    }

    const onReset = e => {

        e.preventDefault();

        setUnitFilter(filter => ({ 
            ...filter,
            name: "",
            code: ""
        }));

        setUnitsState();

    }

    useEffect(() => { 

        setUnitsState();

    }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{unitPath.title}</h3>
            </div>
            <div className="card-body">

                <div className="row">
                    <div className="col-md-8 border-right">
                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >
                            <div className="form-group row">
                                <Label htmlFor="unit-code" isCol={ true } text="Kode Satuan" />
                                <div className="col-md-9">
                                    <input 
                                        id="unit-code" 
                                        type="text" 
                                        className="form-control"
                                        name="code"
                                        value={unitFilter.code}
                                        onChange={ e => onInputChange(e) }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label htmlFor="unit-name" isCol={ true } text="Nama Satuan" />
                                <div className="col-md-9">
                                    <input 
                                        id="unit-name" 
                                        type="text" 
                                        className="form-control"
                                        name="name"
                                        value={unitFilter.name}
                                        onChange={ e => onInputChange(e) }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <Label isCol={ true } />
                                <div className="col-md-9">
                                    <FilterButton to={ unitPath.newPath } newLabel="Tambah Unit Baru" onClick={ onReset } />
                                </div>
                            </div>
                        </form>
                    </div>
                    
                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Unit Baru" entity="Unit" />
                    </div>
                </div>

                <br />
                {
                    isLoad && <LoaderType />
                }
                {
                    !isLoad &&
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover card-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Kode Satuan</th>
                                            <th>Nama Satuan</th>
                                            <th>Opsi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            units.map((unit, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <th scope="row">{ index + 1 }</th>
                                                        <td>{ unit.code }</td>
                                                        <td>{ unit.name }</td>
                                                        <td><LinkButton to={ {pathname: getDetailPath(unit.id), state: unit} } /></td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
