import React, { useState, useEffect } from 'react';

import Label from '../../../components/label';
import Note from '../../../components/note';
import FilterButton from '../../../components/filter-button';
import LinkButton from '../../../components/link-button';
import TextNumber from '../../../components/text-number';
import LoaderType from '../../../components/loader-type';
import { getData } from '../../../helper/request-response';
import goodsServicePath from './config';

const List = () => {

    const [goodsAndServices, setGoodsAndServices] = useState([]);

    const [isLoad, setIsLoad] = useState(true);

    const [goodsAndServiceFilter, setGoodsAndServiceFilter] = useState({
        code: "",
        name: ""
    });

    const getDetailPath = (id) => goodsServicePath.detailPath.replace(":id", id);

    const onInputChange = e => {

        const { name, value } = e.target;

        setGoodsAndServiceFilter(gns => ({ 
            ...gns,
            [name]: value
        }));
    };

    const onSubmit = e => {

        e.preventDefault();

        setIsLoad(true);

        const params = { 
            params: { 
                name: goodsAndServiceFilter.name, 
                code: goodsAndServiceFilter.code 
            }
        };

        setGnsLists(params);

    };

    const setGnsLists = async(params) => {

        const result = await getData(goodsServicePath.baseApi, params);
            
        setGoodsAndServices(result);

        setIsLoad(false);

    }

    const onReset = e => {

        e.preventDefault();

        setIsLoad(true);

        setGoodsAndServiceFilter({ 
            ...goodsAndServiceFilter,
            name: "",
            code: ""
        });

        setGnsLists();

    }

    useEffect(() => { setGnsLists() }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{goodsServicePath.title}</h3>
            </div>
            <div className="card-body">
                {
                    isLoad &&
                    <LoaderType />
                }

                {
                    !isLoad &&
                    <>
                        <div className="row">
                            <div className="col-md-8 border-right">
                                <form className="form-horizontal" onSubmit={ e => onSubmit(e) } >
                                    <div className="form-group row">
                                        <Label htmlFor="goods-and-service-name" isCol={ true } text="Kode" />
                                        <div className="col-md-9">
                                            <input 
                                                id="goods-and-service-code" 
                                                type="text" 
                                                className="form-control"
                                                name="code"
                                                value={goodsAndServiceFilter.code}
                                                onChange={ e => onInputChange(e) }
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <Label htmlFor="goods-and-service-name" isCol={ true } text="Nama" />
                                        <div className="col-md-9">
                                            <input 
                                                id="goods-and-service-name" 
                                                type="text" 
                                                className="form-control"
                                                name="name"
                                                value={goodsAndServiceFilter.name}
                                                onChange={ e => onInputChange(e) }
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <Label isCol={ true } />
                                        <div className="col-md-9">
                                            <FilterButton to={ goodsServicePath.newPath } newLabel="Tambah Data Barang Atau Jasa" onClick={ onReset } />
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-md-4">
                                <Note isFilter={ true } label="Tambah Data Barang Atau Jasa" entity="Barang atau Jasa" />
                            </div>
                        </div>

                        <br />

                        <div className="row">
                            <div className="col-lg-12 mb-4">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover card-text">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Unit Bisnis</th>
                                                <th>Kode Barang</th>
                                                <th>Nama Barang</th>
                                                <th>Satuan</th>
                                                <th>Stok</th>
                                                <th>Harga Beli</th>
                                                <th>Harga Jual</th>
                                                <th>Opsi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                goodsAndServices.map((goodsAndService, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <th scope="row">{ index + 1 }</th>
                                                            <td>{ goodsAndService.businessUnit }</td>
                                                            <td>{ goodsAndService.code }</td>
                                                            <td>{ goodsAndService.name }</td>
                                                            <td>{ goodsAndService.unit }</td>
                                                            <td><TextNumber value={ goodsAndService.stock } /></td>
                                                            <td><TextNumber value={ goodsAndService.purchasePrice } /></td>
                                                            <td><TextNumber value={ goodsAndService.sellingPrice } /></td>
                                                            <td><LinkButton to={ {pathname: getDetailPath(goodsAndService.id), state: goodsAndService} } /></td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </>
                }    
            </div>
        </React.Fragment>
    );
};

export default List;
