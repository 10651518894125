import React, { useState, useEffect } from 'react';
import { DateTime, Settings } from 'luxon';
import { formatDate } from 'react-day-picker/moment';
import { Modal } from 'react-responsive-modal';
import _ from 'lodash';

import Select from '../../../components/select';
import LinkButton from '../../../components/link-button';
import ClickButton from '../../../components/click-button';
import Label from '../../../components/label';
import Note from '../../../components/note';
import TextNumber from '../../../components/text-number';
import DayPicker from '../../../components/day-picker';
import FilterButton from '../../../components/filter-button';
import LoaderType from '../../../components/loader-type';
import { postData, deleteData, getData } from '../../../helper/request-response';
import { onSuccess } from '../../../helper/toaster';
import constant from '../../../config/constant';
import poPurchasePath from './config';

const List = () => {

    Settings.defaultLocale = 'id';

    const filterEntity = {
        'invoiceNo': '',
        'salesInvoiceNo': '',
        'trxDate': undefined,
        'trxDateFrom': undefined,
        'customerType': '',
        'farmerId': 0,
        'farmerName': ''
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    
    const [poPurchases, setPoPurchases] = useState([]);

    const [poPurchase, setPoPurchase] = useState({});

    const [poPurchaseFilter, setPoPurchaseFilter] = useState(filterEntity);

    const [selectedCust, setSelectedCust] = useState(constant.DEFAULT_SELECTED);

    const [isLoad, setIsLoad] = useState(true);

    const [entityId, setEntityId] = useState('3,4');

    const getDetailPath = (id) => poPurchasePath.detailPath.replace(":id", id);

    const onCloseModal = () => setIsOpen(false);

    const onInputChange = e => {

        setPoPurchaseFilter(filter => ({ 
            ...filter,
            [e.target.name]: e.target.value
        }));

    };

    const onCustomerTypeChange = e => {

        setPoPurchaseFilter(filter => ({ 
            ...filter,
            [e.target.name]: e.target.value
        }));

        if (e.target.value === '') setEntityId('3,4');
        else setEntityId(e.target.value);
    }

    const onSelectChange = (opt) => {

        if (opt.id) {

            setPoPurchaseFilter(data => ({
                ...data,
                farmerId: opt.id,
                farmerName: opt.label
            }));

            setSelectedCust(cust => ({
                ...cust,
                value: opt.id,
                label: opt.name
            }));
        }
        
    }

    const onDayChange = day => {

        setPoPurchaseFilter(filter => ({ 
            ...filter,
            trxDate: formatDate(day, constant.DEFAULT_DATE)
        }));

    };

    const onDayFromChange = day => {

        setPoPurchaseFilter(filter => ({ 
            ...filter,
            trxDateFrom: formatDate(day, constant.DEFAULT_DATE),
            trxDate: formatDate(day, constant.DEFAULT_DATE),
        }));

    }

    const onDelete = e => {

        e.preventDefault();

        const val = parseInt(e.currentTarget.value);

        setPoPurchase(poPurchases[val]);

        setIsOpen(true);

    }

    const onConfirmDelete = async(e) => {

        e.preventDefault();

        setIsLoad(true);

        let param = poPurchase;
        param.recordType = 'DELETE';

        const historyResult = await postData(poPurchasePath.baseHistory, param);

        if (historyResult) {

            const params = {
                params: { 
                    'invoice-no': poPurchase.invoiceNo,
                    'sales-invoice-no': poPurchase.salesInvoiceNo
                }
            };
            
            const removeResult = await deleteData(poPurchasePath.base, params);

            if (!_.isEmpty(removeResult)) {

                onSuccess(`Pembelian ${poPurchase.invoiceNo} berhasil di hapus`);

                getPurchases().then(result => {

                    setPoPurchases(result);

                    setIsLoad(false);
        
                });

                setIsOpen(false);
            }
        }

    }

    const onSubmit = (e) => {

        e.preventDefault();

        setIsLoad(true);

        const params = { 
            params: { 
                'invoice-no': poPurchaseFilter.invoiceNo, 
                'sales-invoice-no': poPurchaseFilter.salesInvoiceNo,
                'trx-date': poPurchaseFilter.trxDate, 
                'trx-date-from': poPurchaseFilter.trxDateFrom,
                'farmer-id': poPurchaseFilter.farmerId,
                'customer-type': poPurchaseFilter.customerType
            } 
        };

        getPurchases(params).then(result => {

            setPoPurchases(result);

            setIsLoad(false);

        });

    }

    const getPurchases = async(params) => await getData(poPurchasePath.base, params);

    const onReset = (e) => {

        e.preventDefault();

        setIsLoad(true);

        setPoPurchaseFilter(filterEntity);

        getPurchases().then(result => {

            setPoPurchases(result);

            setIsLoad(false);

        });

        setEntityId('3,4');

    }

    useEffect(() => { 
        
        getPurchases().then(result => {

            setPoPurchases(result);
            
            setIsLoad(false);

        });
    
    }, []);

    return(
        <React.Fragment>
            <div className="card-header">
                <h3 className="h6 text-uppercase mb-0">{poPurchasePath.title}</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <Modal open={ modalIsOpen } onClose={ onCloseModal } center>
                        <div className="modal-header">
                            <h5 className="modal-title">KONFIRMASI PENGHAPUSAN DATA</h5>
                        </div>

                        <div className="modal-body">
                            <table className="table-borderless">
                                <tbody>
                                    <tr>
                                        <td>Faktur Penjualan</td>
                                        <td>:</td>
                                        <td>{poPurchase.salesInvoiceNo}</td>
                                    </tr>
                                    <tr>
                                        <td>PKS</td>
                                        <td>:</td>
                                        <td>{poPurchase.pks}</td>
                                    </tr>
                                    <tr>
                                        <td>Kendaraan</td>
                                        <td>:</td>
                                        <td>{poPurchase.vehicleCode}</td>
                                    </tr>
                                    <tr>
                                        <td>Faktur Pembelian</td>
                                        <td>:</td>
                                        <td>{poPurchase.invoiceNo}</td>
                                    </tr>
                                    <tr>
                                        <td>Tanggal Pembelian</td>
                                        <td>:</td>
                                        <td>{`${formatDate(poPurchase.trxDate, 'LL', constant.LOCALE)}`}</td>
                                    </tr>
                                    <tr>
                                        <td>Tipe Pelanggan</td>
                                        <td>:</td>
                                        <td>{poPurchase.customerTypeName}</td>
                                    </tr>
                                    <tr>
                                        <td>Nama Pelanggan</td>
                                        <td>:</td>
                                        <td>{poPurchase.customerName}</td>
                                    </tr>
                                    <tr>
                                        <td>Jumlah Pembelian (Rp)</td>
                                        <td>:</td>
                                        <td><TextNumber value={ poPurchase.totalPurchase } /></td>
                                    </tr>
                                    <tr>
                                        <td>Jumlah Pembayaran (Rp)</td>
                                        <td>:</td>
                                        <td><TextNumber value={ poPurchase.payment } /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer">
                            <ClickButton onClick={ onConfirmDelete } label="Hapus" className="btn btn-primary" />
                        </div>
                    </Modal>
                </div>

                <div className="row">
                    <div className="col-md-8 border-right">

                        <form className="form-horizontal" onSubmit={ e => onSubmit(e) }>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="inv-no" text="No Faktur Pembelian" />
                                    <input 
                                        id="inv-no" 
                                        type="text" 
                                        className="form-control"
                                        name="invoiceNo"
                                        value={ poPurchaseFilter.invoiceNo }
                                        onChange={ e => onInputChange(e) }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="sales-inv-no" text="No Faktur Penjualan" />
                                    <input 
                                        id="sales-inv-no" 
                                        type="text" 
                                        className="form-control"
                                        name="salesInvoiceNo"
                                        value={ poPurchaseFilter.salesInvoiceNo }
                                        onChange={ e => onInputChange(e) }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="cust" text="Tipe Pelanggan" />
                                    <select id="coa" className="form-control" name="customerType" onChange={ e => onCustomerTypeChange(e) } value={ poPurchaseFilter.customerType }>
                                        <option value="">Pilih</option>
                                        <option value="3">Petani</option>
                                        <option value="4">Agen</option>
                                    </select>
                                </div>

                                <div className="col-md-6">
                                    <Label htmlFor="cust" text="Nama Agen / Petani" />
                                    <Select
                                        id="cust"
                                        paramFilter="name"
                                        api={ poPurchasePath.custFind }
                                        entityId= { entityId }
                                        onChange={ onSelectChange }
                                        value={ selectedCust }
                                        key={ entityId }
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <Label htmlFor="trx-date-to" text="Tanggal Dari" />
                                    <DayPicker id="trx-date-to" name="trxDateFrom" onDayChange={ onDayFromChange } valueDate={ poPurchaseFilter.trxDateFrom } />
                                </div>
                                <div className="col-md-6">
                                    <Label htmlFor="trx-date" text="Tanggal Ke" />
                                    <DayPicker id="trx-date" name="trxDate" onDayChange={ onDayChange } valueDate={ poPurchaseFilter.trxDate } />
                                </div>
                            </div>
                            
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <FilterButton to={ poPurchasePath.newPath } newLabel="Tambah Data Pembelian" onClick={ onReset } />
                                </div>
                            </div>

                        </form>

                    </div>
                    <div className="col-md-4">
                        <Note isFilter={ true } label="Tambah Data Pembelian" entity="Pembelian" />
                    </div>
                </div>

                {
                    isLoad &&
                    <LoaderType />
                }

                {
                    !isLoad &&
                    <>
                        {
                            poPurchases.length > 0 &&
                            <>
                                <br />
                                <div className="row">
                                    <div className="col-lg-12 mb-4">
                                        <div className="table-responsive">
                                                <table className="table table-striped table-hover card-text">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Faktur Pembelian</th>
                                                            <th>Faktur Penjualan</th>
                                                            <th>Tanggal Pembelian</th>
                                                            <th>Nama Pelanggan</th>
                                                            <th>Kendaraan</th>
                                                            <th>PKS</th>
                                                            <th>Qty (Kg)</th>
                                                            <th>Total</th>
                                                            <th>Detail</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            poPurchases.map((item, index) => {
                                                                return(
                                                                    <tr key={index}>
                                                                        <th scope="row">{ index + 1 }</th>
                                                                        <td>{ item.invoiceNo }</td>
                                                                        <td>{ item.salesInvoiceNo }</td>
                                                                        <td>{ DateTime.fromISO(item.trxDate).toFormat('dd-LLLL-yyyy') }</td>
                                                                        <td>{ item.customerName }</td>
                                                                        <td>{ item.vehicleCode }</td>
                                                                        <td>{ item.pks }</td>
                                                                        <td><TextNumber value={ item.qty } /></td>
                                                                        <td><TextNumber value={ item.totalPurchase } /></td>
                                                                        <td className="btn-group">
                                                                            <LinkButton to={ {pathname: getDetailPath(item.id), state: item} } label="Detail" />
                                                                            <LinkButton to={ {pathname: getDetailPath(item.id), state: item, isEdit: true} } className="btn btn-primary" label="Ubah" />
                                                                            <ClickButton onClick={ onDelete } label="Hapus" className="btn btn-danger" value={ index } />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </React.Fragment>
    );
};

export default List;
