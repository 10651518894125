import React from 'react';
import { Route } from 'react-router-dom';
import EmployeeList from './List';
import EmployeeAction from './Action';
import employeePath from './config';

const EmployeeRoutes = () => {
    return (
        <React.Fragment>
            <Route path={employeePath.basePath} exact component={EmployeeList} />
            <Route path={employeePath.newPath} exact component={EmployeeAction} />
            <Route path={employeePath.detailPath} exact component={EmployeeAction} />
        </React.Fragment>
    );
};

export default EmployeeRoutes;
